// sass-lint:disable-all
.cls-1, .cls-2 {
    fill: #9f93c7;
}

.cls-1, .cls-3, .cls-7 {
    fill-rule: evenodd;
}

.cls-3, .cls-4 {
    fill: #fdc85c;
}

.cls-5 {
    fill: #ef809e;
}

.cls-6 {
    fill: #3f337f;
}

.cls-7 {
    fill: #fff;
}
// sass-lint:enable-all