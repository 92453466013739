$pagination-border: #ccc;
$pagination-active-bg: #372e74;


.listing-pagination {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    height: 2rem;
    min-height: 2rem;

    @media print {
        display: none;
    }
}

.listing-pagination > select {
    &:active, &:focus {
        outline: none;
    }
}

.listing-pagination > ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;

    &:first-child {
        margin-left: auto;
    }
}

.listing-pagination__item button,
.listing-pagination > select {
    border: 1px solid $pagination-border;
    position: relative;
    padding: 6px 12px;
    font-size: 1em;
    color: $pagination-active-bg;
    background: #fff;
    cursor: pointer;
}

.listing-pagination__item:not(:last-of-type) button {
    margin-right: -1px;
}

.listing-pagination__item:first-of-type button {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.listing-pagination__item:last-of-type button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.listing-pagination__item.active button {
    background: $pagination-active-bg;
    border-color: $pagination-active-bg;
    color: #fff;
}


.listing-pagination__item.disabled button {
    color: grey;
}
