.microsoft-graph-response {
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    gap: 4rem;
    height: 100vh;
}

.microsoft-graph-response svg {
    width: 30%;
}

.microsoft-graph-response p {
    font-size: 1.1rem;
}

.microsoft-graph-response a {
    font-size: 1rem;
}
