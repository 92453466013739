.module-card__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;
    padding-inline: 1rem;
}

.module-card__error {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
        font-size: 0.8rem;
    }

    button {
        display: flex;
        justify-content: center;
        background: $bg;
        border: unset;
        border-radius: 5px;
        padding: 0.35rem;
        font-size: 0.7rem;
        transition: background-color .3s ease-in-out;

        &:hover {
            cursor: pointer;
            background: darken($bg, 5%);
        }
    }
}

.module-card {
    position: relative;
    padding: 0.5rem 1rem;
    background: $bg;
    border-radius: 3px;

    &.active {
        background: $soft-green;
    }

    &__title {
        font-size: 0.8rem;
        line-height: 1.5rem;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 1.5rem;
    }

    &__img {
        width: 100%;
        aspect-ratio: 1;
        margin-block: 1rem 0.7rem;
        position: relative;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__role {
        position: absolute;
        bottom: 0.25rem;
        right: 0;
        background: $grey;
        padding: 0.35rem 0.5rem;
        border-radius: 5px;
        color: $white;
        font-size: 0.5rem;
    }

    &__desc {
        line-height: 1.5;
        font-size: 0.7rem;
    }

    &__checkbox {
        position: absolute;
        top: 0.7rem;
        right: 0.65rem;
        appearance: none;
        border: solid 2px $font-color-sav-searchs;
        border-radius: 2px;
        height: 20px;
        aspect-ratio: 1;
        display: inline-block;
        cursor: pointer;
        margin-right: 5px;
        background: transparent;

        &:checked {
            border-color: $input-border-bottom;
            background: $input-border-bottom;
        }

        &:checked:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 6px;
            height: 12px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: translate(-60%, -65%) rotate(45deg);
        }
    }
}
