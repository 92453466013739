.admin__card {

  &__content {

    .is-loading {
      backdrop-filter: blur(5px);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .loader {
        display: block;
        width: 12rem;
        margin: auto;
      }
    }
  }
}