@use "scss/utils/_vars.scss" as *;

.action-view-orders-list {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    aspect-ratio: 1/1;
    animation: zoom-in 0.3s ease-in;
    transform-origin: top right;

    @keyframes zoom-in {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }
}

.action-view-orders-list__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.5rem;
    background: $white;
}

.action-view-orders-list__close {
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.8rem;
}

.view-orders-list {
    height: 80%;
}

.view-orders-list__search {
    border: none;
    border-block: 1px solid $disabled;
    padding: 0.5rem;

    &:focus,
    &:active {
        outline: none;
    }
}

.view-orders-list__scrollable {
    height: 100%;
    overflow-y: auto;
}

.view-orders-list__item {
    display: block;
    width: 100%;
    cursor: pointer;
    position: relative;
    font-size: 0.75rem;
    text-transform: capitalize;
    color: #494949;
}

.view-orders-list__item.active .view-orders-list__item__drag {
    background: #f8f8f8;
}

.view-orders-list__item__drag {
    padding: 5px 45px;
    border-bottom: 1px solid #fff;
}

.view-orders-list__item__check {
    position: absolute;
    top: 5px;
    left: 7px;
    height: 25px;
    width: 25px;
    cursor: move;
}

.view-orders-list__item.active.active .view-orders-list__item__check:after {
    display: block;
}

.view-orders-list__item__check:after {
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #3860c4;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.view-orders-list__btns {
    background: #fff;
    padding: 0.25rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    height: 40px;
}

.view-orders-list__btn-validate,
.view-orders-list__btn-cancel {
    font-weight: bold;
    font-size: 0.75rem;
    border: 1px solid #2F56B7;
    padding-inline: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.view-orders-list__btn-validate,
.view-orders-list__btn-cancel:hover {
    background: #2F56B7;
    color: #fff;
}

.view-orders-list__btn-cancel,
.view-orders-list__btn-validate:hover {
    background: #fff;
    color: #2F56B7;
}

.view-orders-list__btn-validate:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
}

.view-orders-list__btn-validate > i {
    display: inline-block;
    margin-left: 0.5rem;
    animation: spin 1s linear infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}

.view-orders-list__loading {
    position: absolute;
    z-index: 1;
    inset: 0;
    display: grid;
    place-items: center;
    background: $white;
}

.view-orders-list__loading.fade-out {
    animation: fade-out 0.3s ease-in-out forwards;

    @keyframes fade-out {
        from {
            visibility: visible;
            opacity: 1;
        }
        to {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.view-orders-list__loading .is-loading {
    width: 75%;
    margin: auto;
    text-align: center;
}

.view-orders-list__select-all {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.35rem;

    > input {
        aspect-ratio: 1;
        height: 1rem;
    }
}
