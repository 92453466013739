.documents-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(400px, 100%), 1fr));
    gap: 0.5rem;
    padding: 0.5rem;
}

.documents-list .document {
    position: relative;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: space-between;
    column-gap: 0.4rem;
    align-items: center;
    background: #f3f1fc;
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    isolation: isolate;
}

.documents-list .document .file-icon {
    font-size: 1.5rem;
    color: #7565c0;
    margin-right: 0.4rem;
    vertical-align: middle;
}

.documents-list .document-label {
    font-size: 0.85rem;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
}

.documents-list .document-icon {
    font-size: 1rem;
    color: #372e74;
    cursor: pointer;
    margin-left: 5px;
    border: solid 1px #372e74;
    border-radius: 4px;
    padding: 5px 0;
    text-align: center;
    width: 32px;
    height: 32px;
    line-height: 1;

    &:hover {
        background-color: #372e74;
        color: #fff;
    }
}
