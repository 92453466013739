@use "scss/utils/_vars.scss" as *;

.has-dropdown {
    position: relative;
}

.has-dropdown > .quote-pdf-versionning-button {
    display: flex;
    align-items: flex-end;
}

.has-dropdown > .quote-pdf-versionning-button > a > i {
    position: relative;
    z-index: 1;
    transition: color 0.2s ease-in-out;
}

.has-dropdown > .quote-pdf-versionning-button > a:hover {
    color: $white;
}

.has-dropdown > .quote-pdf-versionning-button > a:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
}

.has-dropdown > .quote-pdf-versionning-button > a:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 0;
    transition: background-color 0.2s ease-in-out;
}

.has-dropdown > .quote-pdf-versionning-button > a:hover:before {
    background: $main-color;
}

.has-dropdown .quote-pdf-versionning-button__dropdown {
    position: absolute;
    inset-block: -1px;
    left: 100%;
    display: flex;
    align-items: center;
    border: 1px solid $main-color;
    border-left: unset;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.has-dropdown .quote-pdf-versionning-button__dropdown > i {
    font-size: 0.7rem;
    margin-inline: 0.25rem;
    color: $main-color;
    transition: color 0.2s ease-in-out;
}

.has-dropdown .quote-pdf-versionning-button__dropdown:is([data-is-open="true"], :hover) {
    background: $main-color;
}

.has-dropdown .quote-pdf-versionning-button__dropdown:is([data-is-open="true"], :hover) > i,
.prospect-tab__prospect__header__action.has-dropdown:hover:not(.disabled) .quote-pdf-versionning-button__dropdown > i {
    color: $white;
}
.prospect-tab__prospect__header__action.has-dropdown.disabled .quote-pdf-versionning-button__dropdown > i {
    color: inherit;
}

.has-dropdown .quote-pdf-versionning-button__dropdown ul {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    max-height: 150px;
    overflow: auto;
    background: $white;
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out
}

.has-dropdown .quote-pdf-versionning-button__dropdown[data-is-open="true"] ul {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    box-shadow:  0 0 6px $box-shadow;
}

.has-dropdown .quote-pdf-versionning-button__dropdown ul li {
    position: relative;
    padding: 0.1rem 0.3rem;
    cursor: pointer;
    font-size: 0.8rem;
    max-width: 27ch;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.has-dropdown .quote-pdf-versionning-button__dropdown ul li:hover {
    background: $main-color;
    color: $white;
}

.has-dropdown .quote-pdf-versionning-button__dropdown ul li i {
    font-size: 0.9rem;
}

.has-dropdown .quote-pdf-versionning-button__dropdown ul li a {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.has-dropdown .quote-pdf-versionning-button__dropdown ul li span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
