.ged-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .hidden {
    display: none;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    background-color: $disabled;
    border-color: $disabled;
    color: $disabled-font-color;
  }

  button:disabled {
    background-color: $disabled;
    color: $disabled-font-color;
    pointer-events: none;
  }

  .page-list__content {
    padding: 0;
  }

  //Header
  &__header {
    background-color: $last-views;
    height: 60px;

    &-popup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 570px;
      height: 200px;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      border: solid 1px $border-button-ged;
      border-radius: 5px;
      transition: .15s ease-in-out;
      z-index: 9999;

      &-content {
        width: 600px;

        @include respond-to('mobile', false) {
          width: unset;
        }
      }

      .policon {
        position: absolute;
        z-index: 1;
        top: 15px;
        right: 15px;
        font-size: 1.3em;
        transition: .15s ease-in-out;
        cursor: pointer;

        &:hover {
          color: $close;
        }
      }

      .filepond {
        &--wrapper {
          width: 80%;
        }
      }
    }

    &-container {
      height: 100%;
      box-shadow: 0 2px 2px 0 rgba(119, 119, 119, 0.3);
    }

    &-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      font-weight: 500;
      font-size: 0.97em;
      width: 300px;
      text-transform: uppercase;
      border-right: 1px;
      border-right: 1px solid rgba(119, 119, 119, 0.5);

      > div {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .85);
        z-index: 999;

        > div {
          position: relative;
          display: none;
          flex-direction: column;
          background-color: $white;
          color: $black;
          width: 80vw;
          font-size: 1.2em;
          animation-name: openPopup;
          animation-duration: .75s;

          p {
            font-size: inherit;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $last-views;
            color: $white;
            font-weight: 500;
            height: 75px;
            font-size: 0.6em;
            margin-bottom: 5px;
          }

          .policon-quit {
            position: fixed;
            top: 15px;
            left: 90vw;
            color: $white;
            font-size: 0.65em;
            transform: translate(-50%);

            &:hover, &:active {
              color: $close;
            }
          }

          .ged__nav-menu {
            &__link {
              text-align: left;
              background-color: transparent;
              border: none;
              margin-top: 6px;
              margin-left: 8px;
              font-family: inherit;
              outline: none;

              &-content {
                color: $black;
                list-style: unset;
                margin-left: 20px;
                font-size: 0.9em;

                &:hover, &:active {
                  color: $last-views;
                }
              }

              &:hover, &:active {
                color: $last-views;
              }

              &:last-child {
                display: flex;
                flex-direction: row;

                .policon {
                  font-size: 0.7em;
                }

                input {
                  margin-left: 8px;
                  border: none;
                  border-bottom: solid 1px $black;
                  font-family: inherit;
                  outline: none;
                  transition: .25s;

                  &:hover {
                    padding-bottom: 3px;
                  }
                }
              }
            }

            &__select {
              font-weight: 400;

              > div {
                font-size: 0.5em;
              }

              &-icon {
                margin-left: 8px;
                font-size: 0.9em;
                transform: rotate(180deg);
                transition: .25s;
              }

              .close {
                transform: rotate(0deg);
              }
            }
          }
        }
      }

      .open {
        display: flex;
        > div {
          display: flex;
          animation-name: openPopup;
          animation-duration: .75s;
        }
      }

      .policon-folder-settings {
        display: none;
        @include respond-to('mobile', false) {
          display: block;
        }
      }

      @include respond-to('mobile', false) {
        font-size: 2em;

        p {
          display: none;
        }
      }
    }

    &-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      div:first-child {
        display: none;
      }

      @include respond-to('mobile', false) {
        justify-content: center;
        > div {
          display: none;
        }

        div:first-child {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          width: 100%;

          button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            font-size: 1.3em;
            border-radius: 5px;
            border: solid 1px $white;
            color: $white;
            background-color: transparent;
            outline: none;

            &:hover {
              color: $last-views;
              background-color: $white;
            }
          }

          a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            font-size: 1.3em;
            border-radius: 5px;
            border: solid 1px $white;
            color: $white;
            background-color: transparent;
            outline: none;

            &:hover {
              color: $last-views;
              background-color: $white;
            }
          }

          .disabled {
            background-color: $disabled;
            color: $disabled-font-color;
            border-color: $disabled;
          }
        }
      }

      &-displayMode {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        .active {
          background-color: $secondary-color;
          border-color: $secondary-color;
          color: $white;
        }

        button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 28px;
          width: 40px;
          border: solid 1px $border-button-ged;
          background-color: $white;
          cursor: pointer;
          color: $last-views;
          font-size: 1.5em;
          transition: .20s ease-in-out;

          &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white;
          }

          &:focus {
            outline: none;
          }
        }

        button:first-child {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }

        button:last-child {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      &-select {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        div:first-child {
          display: flex;
          position: relative;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          list-style: none;
          height: 28px;
          width: 140px;
          border: solid 1px $border-button-ged;
          background-color: $white;
          cursor: pointer;
          color: $last-views;
          font-size: 1em;
          border-radius: 5px;
          outline: none;
          transition: .20s ease-in-out;

          &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white;
          }

          ul {
            position: absolute;
            top: 102%;
            width: 100%;
            background-color: white;
            z-index: 1;
            border: solid 1px $border-button-ged;

            li {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
              list-style: none;
              color: $last-views;
              transition: .20s ease-in-out;
              height: 30px;

              &:hover {
                background-color: $secondary-color;
                border-color: $secondary-color;
                color: $white;
              }
            }
          }
        }
      }

      &-action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 28px;
          width: 40px;
          border: solid 1px $border-button-ged;
          background-color: $white;
          cursor: pointer;
          color: $last-views;
          font-size: 1.3em;
          transition: .20s ease-in-out;

          &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white;
          }

          &:focus {
            outline: none;
          }
        }

        button:first-child {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }

        button:last-child {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      &-upload {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        a {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          height: 28px;
          width: 115px;
          border: solid 1px $border-button-ged;
          background-color: $white;
          cursor: pointer;
          color: $last-views;
          font-size: 1em;
          transition: .20s ease-in-out;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;

          .policon {
            font-size: 1.2em;
          }

          &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white;
          }
        }

        button {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          height: 28px;
          width: 115px;
          border: solid 1px $border-button-ged;
          background-color: $white;
          cursor: pointer;
          color: $last-views;
          font-size: 1.050em;
          transition: .20s ease-in-out;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;

          .policon {
            font-size: 1.250em;
          }

          &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white;
          }

          &:focus {
            outline: none;
          }

        }
      }

      &-search {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        & > div {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          position: relative;
          height: 28px;
          background-color: white;
          width: 230px;
          padding-left: 10px;
          padding-right: 10px;
          border: solid 1px $border-button-ged;
          color: $last-views;
          border-radius: 5px;

          .policon {
            font-size: 1.2em;
          }

          input {
            width: 85%;
            color: $last-views;
            font-family: inherit;
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 1.050em;

            &::placeholder {
              color: $last-views;
            }
          }
        }
      }

      &-create {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          height: 28px;
          width: 140px;
          border: solid 1px $border-button-ged;
          background-color: $white;
          cursor: pointer;
          color: $last-views;
          font-size: 1.050em;
          transition: .20s ease-in-out;
          border-radius: 5px;

          .policon {
            font-size: 1.250em;
          }

          &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  //Nav des folders
  &__folder-list {
    position: relative;
    padding: 0;
    background-color: $table-row;
    box-shadow: 2px 2px 2px 0 rgba(119, 119, 119, 0.16);

    @include respond-to('mobile', false) {
      display: none;
    }

    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 35px;
      box-shadow: 0 2px 2px 0 rgba(67, 67, 67, 0.16);
      background-color: $border-button-ged;
      color: $grey;
      cursor: pointer;

      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        user-select: none;

        span {
          font-size: 1.050em;

          .policon {
            font-size: 1.1em;
            margin-right: 10px;
          }
        }

        .policon {
          font-size: 0.9em;
          transition: .20s ease-in-out;
        }
      }
    }

    .isClose {
      .policon-Polygone {
        transform: rotate(-90deg);
        transition: .20s ease-in-out;
      }
    }

    &-content {
      color: $main-color;
      overflow-x: auto;
      white-space: nowrap;

      &-file {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        border-bottom: 1px solid $background-folder;
        transition: .15s ease-in-out;

        &-selected {
          background-color: $background-folder;
        }

        &-drop {
          background-color: $breadscrumb-separator !important;
        }

        &:hover {
          background-color: $background-folder;
        }

        .policon {
          font-size: 1em;
          transform: rotate(-90deg);
          transition: .20s ease-in-out;
        }

        > span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          span {
            max-width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;

            .policon {
              margin-right: 10px;
            }
          }
        }

        &--policon-reverse {
          > .policon {
            transform: rotate(0deg);
          }
        }
      }

      @for $i from 1 through 99 {
        .nestedLevel#{$i} {
          padding-left: calc(15px * #{$i + 1});
        }
      }

      &-nestedFile {
        user-select: none;
      }
    }
  }

  &__breadscrumb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: $table-row;
    height: 35px;
    box-shadow: 0 2px 2px 0 rgba(119, 119, 119, 0.16);

    &-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        font-weight: 300;
        cursor: pointer;
      }

      span:last-child {
        font-weight: 400;
      }

      span:nth-child(1) {
        margin-left: 15px;
        font-weight: 300;
      }

      > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      &-separator {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 2px;
        width: 50px;
        background-color: $breadscrumb-separator;
        margin-left: 15px;
        margin-right: 15px;

        div {
          background-color: $breadscrumb-separator;
          height: 9px;
          width: 9px;
          border-radius: 50%;
        }
      }
    }
  }

  &__currentDirectory {
    position: relative;

    &-iconview {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: 15px;
      margin-top: 15px;

      .select {
        background-color: $background-selected-ged;
      }

      div {
        position: relative;
        width: 125px;
        height: 125px;
        font-weight: 300;
        cursor: pointer;
        border-radius: 5px;

        @include respond-to('mobile', false) {
          width: 105px;
          height: 105px;
        }

        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        input {
          font-family: inherit;
          font-weight: 400;
          text-align: center;
          outline: none;
        }

        .policon {
          width: 100px;
          display : flex;
          flex-direction: column;
          justify-content: center;
          align-items : center;
          height: 50px;
          font-size: 4em;
          color: $last-views;
          margin-bottom: 10px;

          &:hover{
            background: $background-selected-ged;
          }
        }

        .policon-folder {
          font-size: 3em;
          color: $main-color;
        }

        .policon-zip {
          color: $breadscrumb-separator;
        }
      }
    }
  }

  &__files {
    display: flex;
    flex-direction: row;
    padding: 0;

    &-directory {
      display: flex;
      flex-direction: column;
      padding: 0;

      > div:last-child {
        min-height: 400px;
        position: relative;

        @include respond-to('mobile', false) {
          overflow: scroll;
        }

        > div:first-child {
          display: none;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          background-color: $table-row;
          font-size: 1.5em;
          color: $last-views;
          border-bottom-left-radius: 5px;
          z-index: 3;
          cursor: pointer;
          box-shadow: 0 2px 2px 0 rgba(119, 119, 119, 0.16);

          @include respond-to('mobile', false) {
            display: flex;
          }
        }
      }
      &--responsive {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .85);
        z-index: 999;

        .policon-quit {
          position: fixed;
          left: 90vw;
          transform: translate(-50%);
          top: 15px;
          color: $white;
          font-size: 1.4em;

          &:hover, &:active {
            color: $close;
          }
        }

        .ged-component__folder-list {
          display: none;
          width: 80vw;
          animation-name: openPopup;
          animation-duration: .75s;
        }
      }

      .open {
        display: flex;
        .ged-component__folder-list {
          display: block;
        }
      }
    }
  }

  .table-ged {
    margin-top: 30px;

    &__table {
      width: 100%;
    }

    &__body {
      td {
        padding: 5px 10px;
        border: 0;

        &:not(:first-child) {
          text-align: center;
        }
      }

      tr {
        &:hover {
          background: $background-selected-ged !important;
        }

        .selected {
          background: $background-selected-ged !important;
        }

        &:nth-child(even) {
          background: $table-row;
        }
      }
    }

    &__document-name {
      display: flex;
      align-items: center;
      flex-flow: column;
    }
  }

  &__document-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85%;
    text-align: center;
    &:hover{
      background: $background-selected-ged;
    }
  }

  &__input-rename {
    z-index: 999;
    border-width: 0;
    background: transparent;
    max-width: 100%;
  }

  &__separator {
    margin-top: 20px;
  }
}

.popup__content {
  .ged-component__files {
    @include respond-to('mobile', false) {
      max-height: 500px;
      overflow: scroll;
    }
  }
}

@keyframes openPopup {
  from {
    left: -80vw;
  }

  to {
    left: 0;
  }
}
