.main-header {
    background: $header;
    padding-bottom: 10px;
    font-size: 1.1em;

    &__picto {
        color: $white;
        font-size: 1.3em;

        &:first-child {
            text-align: left;
            padding-left: 0;
            font-size: 1.5em;
        }

        &:last-child {
            padding-right: 0;
            text-align: right;
        }
    }

    .close-icon {
        &__lasts-visits {
            color: $white;
            position: fixed;
            left: 0;
            z-index : 10;
            text-align: center;
            font-size: 1.3em;

            &:active {
                color: $close;
            }
        }

        &__menu {
            color: $white;
            position: fixed;
            right: 0;
            z-index : 10;
            text-align: center;
            font-size: 1.3em;

            &:active {
                color: $close;
            }
        }
    }

    .user {
        background: $white;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &__content {
            height: 100%;
            padding: 5px 10px;

            @include respond-to('mobile') {
                justify-content: center !important;
            }

            div:first-child {
                display: flex;
                img {
                    max-height: 75px;
                    width: auto;
                }
            }
        }

        &__title {
            margin: 0;
            color: $main-color;
            opacity: .6;
            font-size: 1.1em;
        }

        &__text {
            margin: 0;
            color: $main-color;
            white-space: nowrap;
            font-size: 1.1em;
            cursor: pointer;
        }

        &__warning {
            color: $user-chevron;
            margin-right: 10px;
        }

        &__menu {
            color: $user-chevron;
            transition: transform .5s;
            margin-left: 30px;
            margin-top: 5px;

            &.active-menu {
                transform: rotate(-180deg);
            }
        }

        &__actions {
            position: absolute;
            z-index: 7;
            background: $menu-user;
            border-radius: 4px;
            left: 0;
            right: 0;
            margin-top: 10px;

            &:before {
                @include triangle-top($menu-user, 8px);
                content: '';
                position: absolute;
                top: -8px;
                left: 15px;
            }

            &__item {
                display: block;
                margin: 10px 15px;
                color: $white;
                font-size: 1em;
                white-space: nowrap;
                cursor: pointer;

                &__picto {
                    font-size: 1.2em;
                    margin-right: 10px;
                }
            }
        }
    }

    .logo {
        padding: 10px 0 0;

        svg {
            width: 100px;
            overflow: visible;
        }

        &__slogan {
            text-align: right;
        }

        &__powered {
            color: $white;
            fill: $white;
            margin-top: 5px;
            font-size: .8em;

            &__img {
                text-align: right;
                padding-left: 10px;
            }

            svg {
                text-align: right;
                fill: $white;
                width: 50px;
            }
        }
    }

    .nav {
        position: relative;
        top: 0;
        bottom: 0;
        background: $main-color;
        z-index: 3;
        transition: left .5s ease-in-out;
        border-radius: 4px;

        @at-root body.no-cache & {
            background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
            background-size: 200% 100%;
            animation: gradient 5s ease-in-out infinite;

            &__list__item .sub-menu::before {
                background: transparent;
            }

            @keyframes gradient {
                0% {
                    background-position: 0 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0 50%;
                }
            }
        }

        @include respond-to('mobile') {
            position: fixed;
            left: -100vw;
            background-color: $white;
            width: 80vw;
            z-index: 9999;
        }

        &-open {
            left: 0;
        }

        &__user {
            background: $active-class;
            padding: 8px 0;
            margin: 0 -15px;

            @include respond-to('mobile') {
                width: 100%;
                margin: 0;
            }
        }

        &__logo {
            position: absolute;
            bottom: 15px;
            right: 15px;
            width: 100px;

            @include respond-to('mobile') {
                width: 75px;
            }
        }

        &__title {
            background: $active-class;
            text-align: center;
            color: $white;
            border-radius: 4px;
            padding: 6px 15px;
            text-transform: uppercase;
            width: 95%;
            left: 2.5%;
            position: relative;
            top: 40px;
        }

        &__list {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            margin-top: 10px;
            width: 100%;
            flex-wrap: wrap;

            @include respond-to('mobile') {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 50px;
            }

            @include respond-to('tablet') {
                width: 90%;
            }

            &:before,
            &:after {
                content: '';
                display: table;
            }

            &:after {
                clear: both;
            }

            &__item {
                position: relative;
                z-index: 4;
                list-style: none;
                margin-bottom: 15px;
                white-space: nowrap;

                @include respond-to('mobile') {
                    width: 100%;
                }

                &.search {
                    color: $white;
                    float: right;
                    font-size: 1.3em;
                    margin: -10px 15px 0 0;

                    &.active-search {
                        .search__picto {
                            color: $main-color;
                        }
                    }
                }

                &__link {
                    text-transform: uppercase;
                    border-radius: 4px;

                    @include respond-to('mobile') {
                        display: block;
                    }

                    &.active {
                        @include menu-color-mobile;
                        color: $active-class;
                        position: relative;
                        z-index: 10;
                    }
                }

                &:hover {
                    .sub-menu {
                        display: block;
                    }
                }
            }

            form {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: absolute;
                right: 15px;

                @include respond-to('mobile') {
                    position: relative;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    right: 0;
                    margin-bottom: 15px;
                }

                input {
                    margin-right: 15px;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    font-family: inherit;
                    text-overflow: ellipsis;
                    color: $white;
                    font-size: 1em;
                    border-bottom: solid 1px $white;
                    visibility: visible;
                    opacity: 1;
                    transition: .20s ease-in-out;
                    @include respond-to('mobile') {
                        color: $black;
                        border-bottom: solid 1px $black;
                        margin-right: 0;
                        margin-left: 15px;
                    }

                    &::placeholder {
                        color: white;
                        opacity: .35;
                        @include respond-to('mobile') {
                            color: $black;
                        }
                    }

                    &:focus {
                        padding-bottom: 5px;
                    }
                }

                .input__close {
                    visibility: hidden;
                    opacity: 0;
                }

                button {
                    background-color: transparent;
                    border: none;
                    color: $white;
                    font-size: 1.5em;
                    cursor: pointer;
                    outline: none;

                    @include respond-to('mobile') {
                        color: $black;
                    }
                }
            }

            .globalSearch--extended {
                background-color: $main-color;
                z-index: 10;
                padding-left: 15px;
                padding-top: 3px;
                padding-bottom: 3px;

                @include respond-to('mobile') {
                    background-color: $white;
                    padding: 0;
                }
            }
        }

        .sub-menu {
            position: absolute;
            z-index: 9;
            left: 0;
            background: $secondary-color;
            color: $white;
            top: 100%;
            display: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-top: 15px;
            overflow: hidden;

             @include respond-to('mobile') {
                 position: relative;
                 z-index: 9;
                 left: 15px;
                 color: black;
                 background: none;
                 border-radius: 4px;
                 padding-top: 15px;
                 overflow: hidden;
             }

            &:before {
                content: '';
                top: 0;
                position: absolute;
                left: 0;
                right: 0;
                height: 15px;
                background: $main-color;

                @include respond-to('mobile'){
                    display: none;
                }
            }

            &__item {
                list-style: none;
                text-align: left;

                &:hover {
                    background: $main-color;

                    @include respond-to('mobile') {
                        background: none;
                    }
                }

                &__link {
                    white-space: nowrap;
                    padding: 10px 20px;
                    display: block;

                    &.active {
                        background: $main-color;
                        color: $white;
                    }
                }

                &__disabled {
                    background: $box-shadow;
                    pointer-events: none;
                }
            }
        }
    }
}

@include respond-to('tablet') {
    .main-header {
        .nav {
            &__list {

                &__item {
                    display: inline-block;
                    margin: 5px 0;
                    text-align: center;

                    &__link {
                        // sass-lint:disable-block nesting-depth
                        color: $white;
                        padding: 3px 15px;
                        margin: 0 5px;

                        &.active {
                            @include menu-color;
                            background: $active-class;
                            color: $white;
                        }
                    }
                }
            }
        }

        .user {
            &__content {
                padding: 15px;
            }
        }
    }
}

@include respond-to('large') {
    .main-header {
        .nav {
            &__item {
                margin: 0;

                &.search {
                    margin: -10px 15px 0 0;
                }

                .search {
                    &__input {
                        top: 5px;
                    }

                    &__picto {
                        top: 8px;
                    }
                }
            }
        }
    }
}
