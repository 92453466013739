.ReactModal__Body {

  &--open {
    #root {
      position: relative;
      z-index: 1;
    }

    .ReactModalPortal {
      z-index: 2;
      display: block;
    }
  }
}

.ReactModalPortal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($bg, 0.2);
}