.page-admin {
  .datatable {
    .list__item__logo {
      max-height: 40px;
      width: auto;
    }
  }

  .list {
    padding: 0;
    list-style: none;

    &__item {
      margin: 10px 0;

      &__logo {
        max-height: 500px;
        width: auto;
        margin-top: 10px;
      }

      &__link {
        color: $lte-list-link;

        &:hover {
          color: darken($lte-list-link, 20%);
        }
      }
    }
  }

  &.exit-active {
    display: none;
  }

  .admin-orders {
    &__hidden {
      display: none;
    }

    &__dropdown {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 6;

      &__content {
        position: absolute;
        left: 0%;
        top: 105%;
        background-color: $white;
        z-index: 6;
        border: solid 1px $active-class;
        border-radius: 5px;
        width: 110%;
        font-weight: 400;

        p {
          color: $main-color;
        }
      }

      &__subcontent {
        position: absolute;
        left: -110%;
        top: 105%;
        background-color: $white;
        z-index: 6;
        border: solid 1px $active-class;
        border-radius: 5px;
        width: 110%;
        font-weight: 400;

        p {
          color: $main-color;
        }
      }

      &__icon {
        color: $active-class;
      }

      &__item {
        color: $white;
        font-size: 0.9em;
        cursor: pointer;
        padding: 8px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:hover {
          background-color: $active-class;

          p, .policon {
            color: $white;
          }
        }

        &--disable {
          background-color: $disabled;
          cursor: not-allowed;

          p, .policon {
            color: $disabled-font-color;
          }

          &:hover {
            background-color: $disabled;

            p, .policon {
              color: $disabled-font-color;
            }
          }
        }
      }

      &__Btn {
        cursor: pointer;
      }

      &:hover {
        .action__dropdown__content {
          display: block;
        }
      }

    }

    .group {
      transition: max-width .2s linear, flex .2s linear;
      margin-bottom: 25px;

      &__container {
        width: 100%;
      }

      &__row {
        margin: 0;
        padding: 0;
      }

      &__content {
        border-radius: 4px;
        border: 1px solid $admin-border-group;
        background: $lte-border-header;
        min-inline-size: auto;
      }

      &__title {
        margin: 10px 0;

        &__input {
          border: 0;
          background: transparent;
          font-size: 1em;
          font-family: inherit;
          width: 100%;
          outline: none;
        }
      }

      &__item {
        transition: max-width .2s linear, flex .2s linear;

        &__content {
          position: relative;
        }

        &__drag {
          position: relative;
          background: $white;
          cursor: move;
          padding: 5px 100px 5px 10px;
          margin: 10px 0;
          border-radius: 4px;
          box-shadow: 0 2px 5px $admin-shadow-drag;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &__required {
          font-weight: bold;
        }

        &__unique {
          background: $color-unique;
        }
      }
    }

    .actions {
      position: absolute;
      padding: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $actions;
      border-radius: 0 4px 4px 0;

      &-group {
        bottom: auto;
        border-radius: 0 4px 0 0;
        right: 15px;
        z-index: 1;
      }

      &__item {
        padding: 5px 10px;
        display: inline-block;
        cursor: pointer;

        &:not(:last-child) {
          border-right: 1px solid $white;
        }
      }

      &__type {
        font-size: .9em;
        color: $lte-active-link;
        margin-right: 5px;
      }

      &__icon {
        font-size: .9em;
        color: $white;
      }
    }

    &__add-group {
      color: $admin-bg-group;
      padding: 5px 15px;
      cursor: pointer;
      border: 1px solid $admin-border-group;
      border-radius: 4px;
      background: $lte-border-header;

      &__text {
        margin-left: 10px;
        font-size: 1em;
      }

      &__icon {
        position: relative;
        top: 2px;
        font-size: 1.2em;
      }
    }

    &__content {
      margin-top: 20px;
    }

    &__container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__label {
      margin-right: 10px;
    }

    &__choose {
      color: $admin-bg-group;
      cursor: pointer;
      border: 1px solid $admin-border-group;
      border-radius: 4px;
      background: $lte-border-header;

      &__select {
        height: 33px;
        font-family: inherit;
        width: 200px;
        font-size: 1em;
        border: 0;
        background: transparent;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .save-orders {
    margin-right: 5px;
  }

  .admin-info-mt-15 {
    margin-top: 15px;
  }

  .admin__field__info {
    background-color: #E0F4FA;
    border-left: solid #61C9E2;
    display: flex;
    flex-direction: row;
    padding-top: 10px;

    .admin-info {
      &:after {
        margin-left: 10px;
        margin-bottom: 15px;
        background: #61C9E2;
        color: $white;
        opacity: 1;
        content: '?';
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 0.5em;
        font-size: 24px;
        font-weight: bold;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
      }

      &-error {
        color: $error;
        font-weight: bold;
      }

      &__flex-cols {
        display: flex;
        flex-direction: column;
      }
    }


    p {
      color: $lte-active-link;
      font-size: 1.1em;
      margin-top: 5px;
    }

    .admin__field {
      margin-top: 20px;
      width: 100%;

      &__entity {
        font-size: 1.1em;
        font-weight: bold;
      }

      &__contact {
        font-style: italic;
        font-size: 0.9em;
        margin-bottom: 20px;
      }

      &__unique {
        font-style: italic;
      }
    }
  }

  .admin__import__example {
    color: $lte-active-link;
    font-size: 1.1em;
    margin-top: 5px;
    margin-left: 5px;
  }

  .admin-datatable {
    width: 86vw;
    white-space: nowrap;
  }

  .admin__flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 22vw;
  }

  .import-error {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &:hover {
      .form__error__error-import {
        display: block;
      }
    }
  }

  .td-success {
    height: 20px;
    display: block;
    background-color: $nav-actions-active;
    width: 40px;
  }

  .tr-error {
    background-color: $error-lighter !important;
  }

  .import-value-error {
    text-decoration: line-through;
  }
}