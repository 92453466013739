@use "scss/utils/_vars.scss" as *;

.export-popup {
    position: fixed;
    z-index: 10000;
    top: 1rem;
    right: 1rem;
    border-radius: 6px;
    background: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    contain: paint;
}

.export-popup__header {
    position: relative;
    background-color: $main-color;
    color: $white;
    padding: 0.3rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    cursor: move;
}

.export-popup__header__icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.export-popup__header__icon {
    font-size: 1.3rem;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.export-popup__header__icon.minimized {
    transform: rotate(-180deg);
}

.export-popup__header__tooltip {
    z-index: 10000 !important;
    max-width: 300px;
    text-align: center;
}

.export-popup__wrapper {
    display: grid;
    grid-template-columns: 550px;
    grid-template-rows: 1fr;
    transition: grid-template-rows 0.3s ease-in-out 0.3s, grid-template-columns 0.3s ease-in-out;
}

.export-popup__wrapper.minimized {
    grid-template-rows: 0fr;
    grid-template-columns: 250px;
    transition: grid-template-rows 0.3s ease-in-out, grid-template-columns 0.3s ease-in-out 0.3s;
}

.export-popup__wrapper.minimized .export-popup__list {
    overflow: hidden;
    transition: padding-block 0.3s ease-in-out;
}

.export-popup__list {
    list-style: none;
    margin: unset;
    display: flex;
    flex-direction: column;
    gap: 1px;
    background: $list-prospect-background;
    overflow-y: scroll;
    max-height: 300px;
    scrollbar-gutter: stable;
    transition: padding-block 0.3s ease-in-out 0.3s;
}

.export-popup__line {
    --_line-color: #{$main-color};
    background: $white;
    position: relative;
    padding: 0.5rem 1rem;
    overflow: hidden;
    isolation: isolate;
    font-size: 0.9rem;
}
.export-popup__line:nth-child(odd) {
    background: $list-prospect-background;
}

.export-popup__line.downloadable {
    cursor: pointer;
}

.export-popup__line.stuck {
    --_line-color: #{$menu-devis};
}

.export-popup__line.timeout {
    --_line-color: #{$late};
}

.export-popup__line__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.export-popup__line__date {
    font-size: 0.8em;
    font-weight: bold;
}

.export-popup__line__entity {
    color: var(--_line-color);
    font-weight: bold;
}

.export-popup__line__percent {
    width: 4rem;
    font-size: 0.85em;
    color: var(--_line-color);
    text-align: right;
    font-weight: bold;
}

.export-popup__line__percent i {
    font-size: 1.2rem;
}

.export-popup__line__progress {
    appearance: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border: none;

    &:indeterminate {
        &:before {
            --_length-split: 3;
            content: '';
            position: absolute;
            inset: 0;
            width: calc(100% / var(--_length-split));
            background: var(--_line-color);
            animation: loading 2s ease-in-out infinite;

            @keyframes loading {
                0% {
                    transform: translateX(-100%);
                }
                100% {
                    transform: translateX(calc(var(--_length-split, 1) * 100%));
                }
            }
        }
    }

    &::-webkit-progress-bar {
        background-color: $input-bg;
    }

    &::-webkit-progress-value {
        background-color: var(--_line-color);
    }

    // Firefox
    &::-moz-progress-bar {
        background-color: var(--_line-color);
    }
}
