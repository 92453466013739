@use "scss/utils/_vars.scss" as *;

.prospect__tab {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.prospect__tab.no-overflow {
    overflow: unset;
}

.prospect__tab .prospect__tab__component {
    flex: auto;
}

.prospect__tab__component {
    background: $white;
    padding: 1rem 2rem;
}

.prospect-tab__listing__header {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    color: $main-color;
}

.prospect-tab__listing__header h2 {
    font-size: 0.9rem;
}

.prospect-tab__actions {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.prospect-tab__action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    color: $white;
    background: $main-color;
    cursor: pointer;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    border: none;
    font-size: 0.8rem;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out;

    &:hover {
        color: $white;
    }

    &[data-disabled="true"] {
        cursor: not-allowed;
        color: $disabled-font-color;
        border-color: $disabled-font-color;
        background: $disabled;
    }
}

.prospect-tab__action.stats {
    background: $white;
    color: $main-color;
    border: 1px solid $main-color;

    &:hover {
        background: $main-color;
        color: $white;
    }
}

.prospect-tab__action.stats .svg-arrows {
    width: .8rem;
    height: .8rem;
}

.prospect-tab__action.stats .svg-arrows.spin {
    animation: spin 1s linear infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}

.prospect-tab__action.stats .svg-arrows svg {
    width: 100%;
    height: 100%;
}

.prospect-tab__action.disabled,
.prospect-tab__action:disabled {
    background: $disabled;
    color: $disabled-font-color;
    border-color: $disabled;
    cursor: default;
}

.prospect-tab__action:disabled {
    pointer-events: none;
}

.prospect-tab__action i {
    font-size: 1.1rem;
}

.prospect-tab__search input {
    height: 100%;
    border: 1px solid $main-color;
    border-radius: 4px;
    color: $main-color;
    padding-inline: 0.5rem;
    width: clamp(150px, 100%, 300px);
    font-size: 0.9em;
}

.prospect-tab__listing__results {
    text-align: right;
}

.prospect-tab__prospect :is(.show-entity-loader, .form-entity-loader) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prospect-tab__prospect :is(.show-entity-loader, .form-entity-loader) .loader svg {
    width: 100%;
}

.prospect-tab__prospect__header {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    color: $main-color;
    font-size: 0.8rem;

    @media print {
        display: none;
    }
}

.prospect-tab__prospect__header__back,
.prospect-tab__prospect__header__action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    cursor: pointer;
    border-radius: 5px;
    background: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    & i {
        font-size: 1.2rem;
    }

    &:hover {
        background: $main-color;
        color: $white;
    }

    &:is(:disabled, .disabled) {
        border-color: $disabled;
        background: $disabled;
        color: $disabled-font-color;
        cursor: not-allowed;

        > * {
            pointer-events: none;
        }
    }
}

.prospect-tab__prospect__header__back {
    box-shadow: 0 2px 2px transparentize($main-color, 0.84);
    padding: 0.3rem 1rem;
    align-self: center;
}

.prospect-tab__prospect__header__action {
    --_size: 2.5rem;
    padding: 0.5rem;
    border: 1px solid $main-color;
    color: $main-color;
    height: var(--_size);
    min-width: var(--_size);

    &.tasks {
        border-color: $menu-actions;
        color: $menu-actions;

        &:hover {
            background: $menu-actions;
            color: $white;
        }
    }
}

.prospect-tab__prospect__header__action.quote-pdf-versionning {
    padding: unset;
}

.prospect-tab__prospect__header__action .quote-pdf-versionning-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.prospect-tab__prospect__header__action.disabled .quote-pdf-versionning-button {
    padding-inline: 0.5rem;
}

.prospect-tab__prospect__header__action .quote-pdf-versionning-button > i {
    font-size: 1.2rem;
}

.prospect-tab__prospect__header__action .quote-pdf-versionning-button > a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-inline: 0.5rem;

    &:before,
    &:after {
        content: unset;
    }
}

.prospect-tab__prospect__header__action.quote-pdf-versionning.has-dropdown .quote-pdf-versionning-button {
    padding-right: unset;
}

.prospect-tab__prospect__header__action.quote-pdf-versionning.has-dropdown .quote-pdf-versionning-button > i {
    margin-right: 1rem;
}

.prospect-tab__prospect__header__action .quote-pdf-versionning-button .quote-pdf-versionning-button__dropdown {
    position: unset;
    height: 100%;
    border-width: 0;
    border-left: 1px solid $main-color;
    cursor: pointer;
    background: inherit;

    &:hover {
        background: $main-color;
    }
}

.prospect-tab__prospect__header__action:not(.disabled):hover .quote-pdf-versionning-button .quote-pdf-versionning-button__dropdown {
    border-left-color: $white;
}

.prospect-tab__prospect__header__action .quote-pdf-versionning-button .quote-pdf-versionning-button__dropdown > i {
    transition: color 0.3s ease-in-out;
}

.prospect-tab__prospect__header__action .quote-pdf-versionning-button .quote-pdf-versionning-button__dropdown ul {
    min-width: 100%;
    left: unset;
    right: -1px;
    color: $main-color;
}

.prospect-tab__prospect__header__action .quote-pdf-versionning-button .quote-pdf-versionning-button__dropdown li a {
    padding: 0.5rem 1.5rem;
}

.prospect-tab__prospect__header__tabs {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-right: auto;
    border-bottom: 1px solid transparentize(#000, 0.7);
    padding-right: 5%;
}

.prospect-tab__prospect__header__tabs .prospect-tab__prospect__header__tabs__tab {
    background: $bg;
    color: $disabled-font-color;
    border-radius: 6px 6px 0 0;
    border: 1px solid #9799a5;
    border-bottom: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:first-of-type {
        margin-right: 0.5rem;
    }
}

.prospect-tab__prospect__header__tabs .prospect-tab__prospect__header__tabs__tab.active {
    cursor: default;
}

.prospect-tab__prospect__header__tabs .prospect-tab__prospect__header__tabs__tab:where(.active, :hover) {
    background: $white;
    border: 1px solid #9799a5;
    border-bottom: none;
}

.prospect-tab__prospect__header__tabs .prospect-tab__prospect__header__tabs__tab > * {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    transition: padding-bottom 0.2s ease-in-out;
}

.prospect-tab__prospect__header__tabs .prospect-tab__prospect__header__tabs__tab:where(.active, :hover) > * {
    padding-bottom: 0.8rem;
}
