.export-view-orders-list {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.25);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 3;

    &.open {
        display: flex;
        flex-direction: column;
    }

    &-container {
        position: relative;
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        width: 350px;
        aspect-ratio: 2/3;
    }

    .view-orders-list {
        &__scrollable {
            width: 100%;
            aspect-ratio: 1/1;
        }

        &__btns {
            padding-top: 10px;
            height: 50px;
        }
    }
}
