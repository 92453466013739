@use "sass:math" as math;

/* Texts mixins */
/* ================================================== */
@function rem-size( $size ) {
  $remSize : math.div($size, 16px);
  @return #{$remSize}rem;
}

.text-nowrap {
  white-space: nowrap;
}
