.filepond {

  &--wrapper {
    z-index: 0;
    position: relative;
  }

  &--drop-label {
    background-color: $input-bg;
    color: #7565c0;
    cursor: pointer;
    border-radius: 5px;
  }

  &__container {
    margin-top: 10px;

    @include respond-to('mobile') {
      margin-top: 0;
    }

    & div {
      margin-top: 10px;
    }
    & div:nth-child(1), div:nth-child(2), div:nth-child(3)  {
      margin-top: 0;

      @include respond-to('mobile') {
        margin-top: 10px;
      }
    }

    &-file {
      height: 50px;
      background-color: $input-bg;
      border-radius: 5px;
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.7em;
        color: $main-color;

        @include respond-to('mobile') {
          display: none;
        }

        @include respond-to('tablet') {
          display: none;
        }

        @include respond-to('desktop') {
          display: block;
        }

        @include respond-to('large') {

        }
      }

      &-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        color: $main-color;

        @include respond-to('mobile') {
          max-width: 70%;
          font-size: 1em;
        }

        @include respond-to('tablet') {
          max-width: 65%;
          font-size: 1em;
        }

        @include respond-to('desktop') {
          max-width: 55%;
          font-size: 1em;
        }

        @include respond-to('large') {
          font-size: 1.1em;
          max-width: 70%;
        }
      }

      & span:nth-child(3) {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include respond-to('mobile') {
          font-size: 1.10em;
        }

        @include respond-to('tablet') {
          font-size: 1.10em;
        }

        @include respond-to('desktop') {
          font-size: 1.10em;
        }

        @include respond-to('large') {
          font-size: 1.30em;
        }
      }

      &-button {
        &:last-child {
          margin-left: 10px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $main-color;
        border-radius: 5px;
        border: solid 1px $main-color;
        cursor: pointer;
        transition: .20s ease-in-out;

        @include respond-to('mobile') {
          height: 30px;
          width: 30px;
        }

        @include respond-to('tablet') {
          height: 30px;
          width: 30px;
        }

        @include respond-to('desktop') {
          height: 30px;
          width: 30px;
        }

        @include respond-to('large') {
          height: 32px;
          width: 32px;
        }
        &:hover {
          background-color: $main-color;
          color: $white;
        }
      }
    }
  }
}
