.quotes-products__wrapper {
    overflow-x: auto;
    border-radius: 5px;
}

.quotes-products__popup__background {
    display: none;
}

.quotes-products__popup__background.active {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 3;
    inset: 0;
    background: transparentize(#333, 0.3);
}
