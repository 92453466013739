@use "scss/utils/_vars.scss" as *;
@use "scss/utils/_respondTo.scss" as * with (
$breakpoints-min: $breakpoints-min,
$breakpoints-max: $breakpoints-max
);

.customer-files__dropdown {
    position: relative;
    user-select: none;
}

.customer-files__dropdown span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    white-space: nowrap;

    @include respond-to("tablet", false) {
        flex-direction: row-reverse;
    }
}

.customer-files__dropdown span i {
    @include respond-to("tablet", false) {
        transform: rotate(180deg);
    }
}

.customer-files__dropdown ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 15;
    top: -1px;
    left: calc(100% + 0.25rem);
    list-style: none;
    margin-left: unset;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    color: $main-color;
    border: 1px solid $main-color;
    border-radius: 4px;
    background: #fff;

    @include respond-to("tablet", false) {
        left: unset;
        right: calc(100% + 0.25rem);
    }
}

.customer-files__dropdown ul:after {
    content: "";
    position: absolute;
    z-index: -1;
    inset: -0.25rem
}

.customer-files__dropdown.open ul {
    visibility: visible;
    opacity: 1;
}

.customer-files__dropdown ul li {
    max-height: 0;
    width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out, max-height 0.2s ease-in-out;

    &:hover {
        background: $main-color;
        color: $white;
    }
}

.customer-files__dropdown.open ul li {
    max-height: 10rem;
}

.customer-files__dropdown ul li i {
    font-size: 1rem;
}

.customer-files__dropdown,
td > .customer-file-btn {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border: 1px solid $main-color;
    color: $main-color;
    padding: 0.3rem 1rem;
    border-radius: 4px;
    width: 8rem;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover,
    &.open {
        background: $main-color;
        color: $white;
    }
}

.customer-file-btn span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customer-file-btn i {
    font-size: 1rem;
}

.customer-file-btn {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}
