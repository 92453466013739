@use "scss/utils/_vars" as *;

.task-stats {

    &--img {
        text-align: right;

        img {
            max-width: 100%;
        }
    }

    &__header {
        font-size: 1.15em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__label {
            font-weight: bold;
            color: $main-color;

            &--time {
                font-weight: normal;
            }
        }
    }

    &__refresh {

        &[disabled] {
            opacity: 0.5;
        }

        svg {
            margin-right: 5px;

            &.spin {
                animation: spin 1s linear infinite;

                @keyframes spin {
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }
}