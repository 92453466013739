@use 'scss/utils/_vars' as *;

.prospects-listing-maps {
    position: relative;
    isolation: isolate;
}

.prospects-listing-maps__loader {
    position: absolute;
    z-index: 1;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
}

.prospects-listing-maps__loader .loader {
    width: 25%;
}

.prospects-listing-maps .leaflet-container {
    isolation: isolate;
    height: 85vh;
}

.prospects-listing-maps__help {
    position: absolute;
    z-index: 1;
    bottom: 1rem;
    right: 1rem;
    color: $white;
    max-width: 75ch;
    line-height: 1.75;
    font-size: 0.75rem;
    background: transparentize($main-color, 0.2);
}

.prospects-listing-maps__help i {
    display: block;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    background: $main-color;
    aspect-ratio: 1/1;
}

.prospects-listing-maps__help:not(.show) div {
    display: none;
}

.prospects-listing-maps__help.show div {
    margin: 1.5rem 2.5rem;
}

.prospects-listing-maps__help.show i {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.1rem;
}

.prospects-listing-maps__help p:not(:last-child) {
    margin-bottom: 1rem;
}

.prospects-listing-maps__prospect {
    position: absolute;
    z-index: 1;
    top: 0.5rem;
    left: 3rem;
    font-size: 0.85rem;
    color: $white;
    background: transparentize($main-color, 0.4);
}

.prospects-listing-maps__prospect__header {
    background: transparentize($main-color, 0.2);
    padding: 0.5rem;
    cursor: grab;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &:active {
        cursor: grabbing;
    }
}

.prospects-listing-maps__prospect__header i {
    font-size: 1.2rem;
}

.prospects-listing-maps__prospect__header__close {
    margin-left: auto;
    cursor: pointer;
}

.prospects-listing-maps__prospect__infos {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.prospects-listing-maps__prospect__infos span {
    font-weight: bold;
}

.prospects-listing-maps__prospect__address {
    padding: 0.5rem 1rem;
    background: transparentize($main-color, 0.6);
}

.prospects-listing-maps__prospect__phone {
    padding-left: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background: transparentize($main-color, 0.4);
}

.prospects-listing-maps__prospect__phone i {
    font-size: 1.1rem;
}

.prospects-listing-maps__prospect__go {
    margin-left: auto;
    display: block;
    aspect-ratio: 1/1;
    padding: 0.75rem;
    background: transparentize($main-color, 0.2);
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: transparentize($main-color, 0.4);
    }
}

.prospects-listing-maps__prospect__go i {
    font-size: 1rem;
    display: block;
    transition: transform 0.2s ease-in-out;
}

.prospects-listing-maps__prospect__go:hover i {
    transform: translateX(3px);
}
