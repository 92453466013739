@use "scss/utils/_vars.scss" as *;

.postal-code-field {
    position: relative;
}

.postal-code-field input:focus + .postal-code-field__menu {
    visibility: visible;
}

.postal-code-field__menu {
    list-style: none;

    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $white;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    max-height: 15vh;
    overflow-y: auto;
    visibility: hidden;
    transition: visibility 0.2s ease-in-out;
}

.postal-code-field__menu > li {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.postal-code-field__menu > li:hover {
    background-color: $input-default-color;
    color: $white;
}
