@use "scss/utils/_vars.scss" as *;

.maintenance-page {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    height: 100vh;
    height: 100dvh;
    max-width: 85%;
    margin-inline: auto;
    overflow: hidden;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.maintenance-page .background {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.03;
}

.maintenance-page .logo {
    height: 10rem;
    width: auto;
    margin-bottom: 0.5rem;
}

.maintenance-page h1 {
    font-size: clamp(2rem, 5vw, 4rem);
    color: transparentize($main-color, 0.8);
    text-transform: uppercase;
}

.maintenance-page h2 {
    color: $main-color;
}

.maintenance-page hr {
    margin-block: 1rem;
}

.maintenance-page p {
    font-size: 0.85rem;
}

.maintenance-page .cogs {
    display: flex;
    gap: 0.5rem;
    transform: rotate(45deg);

    @media (max-width: 768px) {
        order: -1;
    }
}

.maintenance-page .cogs i {
    display: flex;
    align-items: center;
    font-size: clamp(1rem, 5vw, 6rem);
    animation: cog-spin 4s linear infinite;

    @keyframes cog-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.maintenance-page .cogs .cog2 {
    font-size: clamp(1rem, 7vw, 8rem);
    margin-top: 2rem;
    color: $main-color;
    animation: cog-spin 4s linear infinite reverse;
}
