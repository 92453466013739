.table-import {
    table {
        tr {
            td {
                text-align: left;
            }
        }
    }
}

.table-content {
    .react-bootstrap-table {
        min-height: 463px;
        overflow: hidden;
        overflow-x: auto;

        table {
            &.datatable {
                table-layout: auto;
            }
        }

        &-pagination {
            margin: 15px 0 0;

            .btn {
                margin-left: -15px;
                display: inline-block;
                padding: 6px 12px;
                text-align: center;
                vertical-align: middle;
                cursor: pointer;
                border: 1px solid $border;
                border-radius: 4px;
                color: $grey;
                font-size: 1em;
                background: $white;
            }

            .dropdown {
                &-menu {
                    display: none;
                    position: absolute;
                    bottom: 0;
                    left: 50px;
                    z-index: 1000;
                    float: left;
                    min-width: 160px;
                    text-align: left;
                    box-shadow: 0 6px 12px $box-shadow;
                    padding: 0;
                    margin: 2px 0 0;
                    list-style: none;
                    background: $white;
                    border: 1px solid $border;
                    border-radius: 4px;
                }

                &-item {
                    text-align: left;
                    display: block;

                    a {
                        display: block;
                        clear: both;
                        font-weight: 400;
                        color: $main-color;
                        white-space: nowrap;
                        padding: 3px 20px;

                        &:hover {
                            background: $bg;
                        }
                    }
                }

                &.open {
                    .dropdown-menu {
                        display: block;
                    }
                }
            }

            .pagination {
                text-align: right;
                list-style: none;

                &__item {
                    display: inline-block;

                    &-active {
                        .pagination__item__btn {
                            background-color: $main-color;
                            border-color: $main-color;
                            color: $white;
                        }
                    }

                    &__btn {
                        border: 1px solid $border;
                        position: relative;
                        float: left;
                        padding: 6px 12px;
                        font-size: 1em;
                        text-decoration: none;
                        z-index: 3;
                        color: $main-color;
                        margin-left: 0;
                        border-radius: 0;
                        cursor: pointer;
                        margin-right: -1px;
                        background: $white;

                        &:active,
                        &:focus {
                            outline: none;
                        }
                    }

                    &:first-child {
                        .pagination__item__btn {
                            border-top-left-radius: 4px;
                            border-bottom-left-radius: 4px;
                        }
                    }

                    &:last-child {
                        .pagination__item__btn {
                            margin-right: 0;
                            border-top-right-radius: 4px;
                            border-bottom-right-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}

.table-content-search {
    .react-bootstrap-table {
        margin-bottom: 20px;
    }
}

.table {
    &-content {
        position: relative;
        overflow: hidden;
        margin-top: 10px;
    }

    &-container {
        margin-top: 10px;

        .table-content{
                &__margin{
                    margin-top: 2px;
                }
        }


        &.no-datas {
            .react-bootstrap-table-pagination {
                display: none;
            }

            [data-row-selection='true'] {
                min-height: 43px;
                display: block;

                input {
                    display: none;
                }
            }

            tfoot {
                display: none;
            }
        }

        .btn {
            &__secondary{
                font-size: 1.5em;
                border : 1px solid $main-color;
                color: $main-color;
                border-radius : 5px;
                transition: all .20s ease-in;
                width: 90px;

                @include respond-to('mobile') {
                    width: 60px;
                    font-size: 1em;
                }

                &:hover {
                    background: $main-color;
                    color : $white;
                }
                p{
                    font-size : 0.9375em;
                }

            }
        }
    }

    &-column {
        list-style: none;
        text-align: left;
        position: absolute;
        background: $white;
        margin: 0;
        top: 43px;
        bottom: 5px;
        z-index: 5;
        box-shadow: 0 0 5px $box-shadow-column;
        transform: translateX(120%);
        max-height: calc(100% - 5px);
        transition: transform .5s linear;
        right: 0;

        &-active {
            transform: translateX(0);
        }

        &__close {
            position: absolute;
            top: 10px;
            right: 15px;
            color: $main-color;
            cursor: pointer;
            font-size: 1.2em;
        }

        &__title {
            padding: 11px 40px 10px 15px;
            color: $main-color;
            text-transform: uppercase;
        }

        .list {
            max-height: calc(100% - 42px);
            overflow-y: auto;

            &__item {
                display: block;
                width: 100%;
                cursor: pointer;
                position: relative;

                &__drag {
                    padding: 5px 45px;
                    border-bottom: 1px solid $white;
                }

                &__check {
                    position: absolute;
                    top: 5px;
                    left: 7px;
                    height: 25px;
                    width: 25px;
                    cursor: move;

                    &:after {
                        left: 9px;
                        top: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid $active-class;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                        content: '';
                        position: absolute;
                        display: none;
                    }
                }

                &:last-child {
                    display: none;
                }

                &.active {
                    .list__item {
                        &__drag {
                            background: $shadow;
                        }

                        &__check {
                            &:after {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &-active-filter {
        list-style: none;
        padding: 0;
        position: absolute;
        left: 360px;
        top: -100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        @include respond-to('large') {
            width: 61vw;
        }
        @include respond-to('desktop', false) {
            width: 44vw;
        }
        @include respond-to('tablet', false) {
            width: 29vw;
        }
        @include respond-to('mobile', false){
            top: 13%;
            left: 140px;
            width: 48vw;
        }

        &__visibleFilter-content {
            overflow-x: auto;
            white-space: nowrap;
            width: 100vw;
        }

        &__dropDown {
            &-Btn {
                background: $white;
                color: $secondary-color;
                box-shadow: inset 0 0 0 2px $secondary-color;
                border-radius: 4px;
                padding: 5px 15px;
                cursor: pointer;
                margin-right: 10px;
                transition: box-shadow .5s, color .5s;
                position: relative;
                width: 180px;
                text-align: center;
                user-select: none;
                white-space: nowrap;
                @include respond-to('mobile'){
                    white-space: nowrap;
                }


                &:hover {
                    box-shadow: inset 0 0 0 80px $secondary-color;
                    color: $white;
                }

            }

            &-content {
                display: none;
                position: absolute;
                top: 110%;
                left: 0;
                width: max-content;
                border: solid 2px #c8befe;
                border-radius: 2px;
                height: auto;
                z-index : 1;
                background-color: white;
                user-select: none;
            }
        }

        .isOpen {
            display: block;
        }

        &__item {
            background: $white;
            color: $secondary-color;
            box-shadow: inset 0 0 0 2px $secondary-color;
            display: inline-block;
            border-radius: 4px;
            padding: 5px 15px;
            cursor: pointer;
            margin-right: 10px;
            transition: box-shadow .5s, color .5s;
            @include respond-to('mobile'){
                height: 32px;
                width: max-content;
            }

            &-select {
                list-style: none;
                color: $secondary-color;
                height: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
                transition: background-color .25s ease-in-out;

                &:hover {
                    background-color: $secondary-color;
                    color: white;
                }
            }

            &__key {
                margin-right: 5px;
            }

            &__icon {
                margin-right: 10px;

                &-dropDown {
                    margin-left: 10px;
                }
            }

            &.disable {
                opacity: .5;
                cursor: initial;

                &:hover {
                    color: $secondary-color;
                    box-shadow: inset 0 0 0 2px $secondary-color;
                }
            }

            &:hover {
                box-shadow: inset 0 0 0 80px $secondary-color;
                color: $white;
            }
        }
    }

    &-loader {
        display: none;
    }
}

.homepage {
    .react-bootstrap-table {
        min-height: auto;
    }

    .homepage {
        &__datatable-container {
            & .table-container {
                thead {
                    th {
                        font-size: 0.9em;
                    }
                }
            }
        }
    }
}


.datatable {
    width: 100%;
    border-spacing: 0;
    table-layout: auto;
    @include table-header-color;

    .checkbox {
        label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__header-homepage {
        position: relative;
        top: 11px;
        &__icon {
            font-size: 1.5em;
            display: flex;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            &-not-late {
                color: $nav-actions-active;
            }
            &-late {
                color: $error;
            }
        }
        &-tabs {
            button {
                height: 42px;
                border: none;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                cursor: pointer;
                font-family: inherit;
                font-weight: 700;
                font-size: 1em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                box-shadow: inset 0 -3px 2px 0 rgba(0, 0, 0, 0.08);
            }
        }

        .homeTask {
            button {
                background-color: $white;
                border-left: solid 1px $last-views;
                border-top: solid 1px $last-views;
                border-right: solid 1px $last-views;
                color: $last-views;

                &:focus {
                    outline: none;
                }
            }

            button.active {
                background-color: $header;
                color: $white;
                border-color: $header;
            }
        }

        .homeQuote {
            button {
                background-color: $white;
                border-left: solid 1px $last-views;
                border-top: solid 1px $last-views;
                border-right: solid 1px $last-views;
                border-color: $bg-quote-datatable-homepage;
                color: $bg-quote-datatable-homepage;

                &:focus {
                    outline: none;
                }
            }

            button.active {
                background-color: $bg-quote-datatable-homepage;;
                color: $white;
                border-color: $bg-quote-datatable-homepage;
            }
        }

        p {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            margin-bottom: 3px;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $font-color-homepage-datatable;

            @include respond-to('mobile') {
                white-space: unset;
            }

            @include respond-to('tablet', false) {
                white-space: unset;
            }
        }
    }

    tr,
    td,
    th {
        border: 0;
    }

    thead {
        text-align: center;
        text-transform: uppercase;

        th {
            font-size: 1.1em;
            padding: 10px;
            font-weight: normal;
            white-space: nowrap;

            &:focus {
                outline: none;
            }

            &:nth-child(2),
            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }

        .toggle-columns {
            cursor: pointer;
            margin-right: 15px;
            font-size: 1.1em;
        }
    }

    tbody {
        tr {
            border: 0;

            &:nth-child(even) {
                background: $table-row;
            }
        }

        td {
            text-align: center;
            color: $font-color-homepage-datatable;
            padding: 10px;
            cursor: pointer;

            &.main-field {
                font-weight: bold;
            }

            &:nth-child(1),
            &:nth-child(2) {
                text-align: left;
            }

            &:last-child {
                text-align: right;

                @include respond-to('mobile') {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
    }

    tfoot {
        tr {
            @include respond-to('mobile', false) {
                border: none;
            }
            @include respond-to('tablet', false) {
                border: none;
            }
        }

        th {
            text-align: center;
            color: $main-color;
            padding: 10px;
            cursor: pointer;
        }

        th:empty {
            @include respond-to('mobile', false) {
                display: none;
            }
            @include respond-to('tablet', false) {
                display: none;
            }
        }

    }

    &__actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &__item:first-child {
            margin-left: 0;
        }

        &__item {
            margin-left: 15px;
            width: 32px;
            height: 29px;
            list-style: none;
            cursor: pointer;
            border-radius: 5px;
            border: solid 1px $main-color;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            transition: .25s ease-in-out;

            &:hover {
                background-color: $main-color;
                color: $white;
            }

            &__icon {
                z-index: 2;
                font-size: 1.2em;
                position: relative;

                .policon-file-export-solid {
                    position: relative;
                    left: 2px;
                    top: 1px;
                }
            }
        }
    }

    &__sort {
        margin-left: 10px;
    }

    &__search-container {
        @include respond-to('mobile') {
            padding-left: 0;
            padding-right: 5px;
        }
    }

    .data__info__phone{
        cursor: pointer;
        text-decoration: underline;
        &:hover {
            color: $input-default-color;
        }
    }

    &__search {
        position: relative;
        width: 100%;
        display: block;

        .sr-only {
            display: none;
        }

        &__input {
            font-family: inherit;
            width: 100%;
            border: 1px solid $main-color;
            color: $main-color;
            height: 35px;
            padding-left: 7px;
            font-size: .9em;
            border-radius: 4px;

            &::placeholder {
                color: $main-color;
                opacity: 1;
            }
        }
    }

    &__no-result {
        text-align: center;
        padding: 50px;

        &__picto {
            font-size: 3em;
        }

        .fa-spin {
            font-size: 3em;
        }

        &__text {
            margin-top: 25px;
        }
    }

    &__filter {
        display: none;
    }

    &__search-filter {
        list-style : none;
        border : 1px solid $main-color;
        border-radius : 5px;
        height : 35px;
        width: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        cursor : pointer;
        transition: all .20s ease-in;
        padding-left: 4px;
        padding-right: 4px;

        &:hover {
            background: $main-color;
            color : $white;
        }

    }

    .react-bs-table-no-data {
        background: $table-row;
        height: 263px;

        @include respond-to('mobile') {
            all: unset;
            background: $table-row;
            height: 100% !important;
            display: flex !important;
            flex-direction: column !important;
            justify-content: center;
            align-items: center;
        }

        @include respond-to('tablet', false) {
            all: unset;
            background: $table-row;
            height: 100% !important;
            display: flex !important;
            flex-direction: column !important;
            justify-content: center;
            align-items: center;
        }
    }
}

.popup-export {

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 1px);
    z-index: 9;
    box-shadow: 0 0 5px $box-shadow;
    background: $white;
    text-align: center;
    color : $active-class;

    @include respond-to('mobile') {
        width: 80%;
    }

    &__overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 8;
        background: $black;
        opacity: .3;
    }

    &__title {
        text-align: center;
        background: $header;
        padding: 30px 50px;
        color: $white;
        font-size: 1.2em;
    }

    &__content {
        height: 70vh;
        overflow: auto;
        padding: 20px 50px;
        text-align: left;
    }

    &__btn {
        margin: 10px 50px 30px;
        background: $active-class;
        border: 0;
        color: $white;
        padding: 10px 25px;
        font-size: 1.1em;
        cursor: pointer;
        &:disabled {
            background-color: $disabled;
            color: $disabled-font-color;
            border-color: $disabled;
            cursor: not-allowed;
            opacity: .9;
        }
    }

    .columns {
        &__item {
            list-style: none;

            input {
                margin-right: 10px;
            }

            label {
                cursor: pointer;
            }
        }
        &__select_all_item {
          text-align: left;
          list-style: none;

          input {
            margin: 20px 10px 20px 50px;
          }

          label {
            cursor: pointer;
          }
        }
    }
}

.homeTask {
    .table-content {
        .react-bootstrap-table {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            box-shadow: 0 2px 2px 0 rgba(67, 67, 67, 0.16);
            .table {
                thead {
                    background-color: $header;
                    tr {
                        th {
                            border-right: solid 2px $white;
                        }
                        .selection-cell-header {
                            display: none;
                        }
                        th:last-child {
                            display: none;
                        }
                    }
                }

                tbody {
                    tr {
                        &:nth-child(even) {
                            background: $input-bg;
                        }
                        td {
                            border-right: solid 2px $white;
                            box-shadow: 0 2px 2px -1px rgba(67, 67, 67, 0.13);
                            white-space: nowrap;
                            font-size: .85em;

                            @include respond-to('mobile') {
                                display: flex;
                                flex-direction: row;
                                white-space: initial;
                            }

                            ul {
                                justify-content: center;

                                li {
                                    margin-left: 0;
                                }
                                li:last-child {
                                    margin-left: 10px;
                                }
                            }
                        }
                        .selection-cell {
                            display: none;
                        }

                        td[data-title="Actions"] {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .pagination {
        li.pagination__item-active {
            .pagination__item__btn {
                background-color: $header;
                border-color: $header;
            }
        }
    }

    #pageDropDown {
        display: none;
    }
}

.homeQuote {
    .table-content {
        .react-bootstrap-table {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            box-shadow: 0 2px 2px 0 rgba(67, 67, 67, 0.16);
            .table {
                thead {
                    background-color: $bg-quote-datatable-homepage;
                    tr {
                        th {
                            border-right: solid 2px $white;
                        }
                        .selection-cell-header {
                            display: none;
                        }
                        th:last-child {
                            display: none;
                        }
                    }
                }

                tbody {
                    tr {
                        &:nth-child(even) {
                            background: $input-bg;
                        }
                        td {
                            border-right: solid 2px $white;
                            box-shadow: 0 2px 2px -1px rgba(67, 67, 67, 0.13);
                            white-space: nowrap;
                            font-size: .85em;

                            @include respond-to('mobile') {
                                white-space: initial;
                            }

                            ul {
                                justify-content: center;

                                li {
                                    margin-left: 0;
                                }
                                li:last-child {
                                    margin-left: 10px;
                                }
                            }
                        }
                        .selection-cell {
                            display: none;
                        }

                        td[data-title="Actions"] {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .pagination {
        li.pagination__item-active {
            .pagination__item__btn {
                background-color: $bg-quote-datatable-homepage;
                border-color: $bg-quote-datatable-homepage;
            }
        }
    }

    #pageDropDown {
        display: none;
    }
}

.align-right {
    float: right;
    white-space: nowrap;
}

@include respond-to('tablet', false) {
    .datatable {
        &,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        tbody {
            td {
                text-align: left;
                display: flex;
                padding: 10px 10px 10px 50%;
                min-height: 39px;
                overflow-wrap: anywhere;

                &:first-child {
                    &:not(.react-bs-table-no-data) {
                        display: none;
                    }
                }
            }
        }

        thead {
            tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }
        }

        td {
            border: 0;
            border-bottom: 1px solid $bg;
            position: relative;
            padding-left: 50%;
            white-space: normal;
            text-align: left;

            &:before {
                position: absolute;
                left: 10px;
                padding-right: 10px;
                text-align: left;
                font-weight: bold;
                content: attr(data-title);
                text-decoration: underline;
            }
        }

        tr {
            border: 1px solid $header;
        }

        &__actions {
            display: flex;
            flex-direction: row;
        }
    }
}

@include respond-to('tablet') {
    .popup-export {
        min-width: 420px;
    }
}

@include respond-to('mobile') {
    .datatable {
        tbody {
            td {
                padding: 10px 10px 10px 60%;
                min-height: 39px;
                overflow-wrap: anywhere;
            }
        }
    }
}
