@use "scss/utils/_vars.scss" as *;

.dashboard-page {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 2rem;
    padding: 1rem;
    border-radius: 4px;
    background: $white;

    @media (max-width: 991px) {
        grid-template-columns: 100%;
    }
}

.dashboard-listing {
    display: flex;
    flex-direction: column;
}

.dashboard-listing__counters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 1.5rem;
}

.dashboard-listing__counter {
    flex: 1;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    position: relative;
    isolation: isolate;
    background: var(--counter-bg, hsl(250, 66%, 86%));
    color: $white;
    padding: 1rem 1.8rem;
    border-radius: 4px;
    box-shadow: 0 0 5px transparentize($black, 0.75);
}

.dashboard-listing__counter__number {
    font-size: 2rem;
    font-weight: 700;
}

.dashboard-listing__counter__name {
    font-size: 0.9rem;
}

.dashboard-listing__counter__icon {
    position: absolute;
    z-index: -1;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    font-size: 3.7rem;
    color: var(--counter-icon-clr, $white);
}

.dashboard-listing__tabs {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
}

.dashboard-listing__tab {
    padding: 0.5rem;
    border: 1px solid var(--counter-bg, $main-color);
    color: var(--counter-bg, $main-color);
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @media (min-width: 768px) {
        padding: 0.6rem 1.75rem;
    }
}

.dashboard-listing__tab.active {
    background: var(--counter-bg, $main-color);
    color: $white;
    cursor: default;
}

.dashboard-listing__listing {
    min-height: 525px;
}

.dashboard-listing__listing,
.dashboard-listing__listing > div {
    flex: auto;
    display: flex;
    flex-direction: column;
}

.dashboard-listing__listing > div > :first-child {
    flex: auto;
}

.dashboard-listing__listing .pol-table__overflow {
    height: 100%;

    @media (max-width: 991px) {
        box-shadow: 1px 1px 6px transparentize(#434343, 0.8);
    }
}

.dashboard-listing__state {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: $table-row;
    box-shadow: 2px 2px 6px transparentize(#434343, 0.8);
    border-radius: 4px;
    font-size: 1rem;
    transition: background 0.3s ease-out, color 0.3s ease-out;
}

.dashboard-listing__state.no-results {
    background: #e1e4ed;
    color: $disabled-font-color;
}

.dashboard-listing__state > i {
    font-size: 1.7em;
}

.dashboard-listing__table thead {
    background: var(--counter-bg, $main-color);
    color: $white;
}

.dashboard-quotes-graph {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 991px) {
        min-height: 400px;
    }
}

.dashboard-quotes-graph__title {
    flex: 1;
    text-align: center;
    font-size: 1rem;
    color: $main-color;
}

.dashboard-quotes-graph__graph {
    position: relative;
    flex: auto;
}

.dashboard-quotes-summary,
.dashboard-tasks-summary {
    overflow-x: auto;
    grid-column: span 2;

    @media (max-width: 991px) {
        grid-column: unset;
    }
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) > p {
    display: inline-block;
    color: $white;
    padding: 0.6rem 3.5rem;
    font-weight: bold;
    font-size: 0.85rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) table {
    width: 100%;
    border-collapse: collapse;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) :is(td, th):not(:first-child) {
    border-left: 2px solid $white;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) :is(thead, tfoot) tr:not(:first-child) {
    border-top: 2px solid $white;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) thead th {
    padding: 0.6rem;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) tbody td {
    padding: 0.4rem 0.5rem;
    white-space: nowrap;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) :where(tbody, tfoot) td:not(:first-child) {
    text-align: center;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) tbody tr:nth-child(even) {
    background: $table-row;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) tfoot tr {
    background: $main-color;
    color: $white;
    font-weight: bold;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) tfoot td {
    padding: 0.6rem;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) thead tr {
    background: #bdbdbd;
    color: $white;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) thead tr th {
    font-weight: 500;
    white-space: nowrap;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) thead tr:first-child th {
    text-transform: uppercase;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) thead tr:first-child th:nth-child(2) {
    border-left: none;
}

:where(
.dashboard-quotes-summary,
.dashboard-tasks-summary
) td a {
    transition: color 0.2s ease-in-out;

    &:hover {
        color: $input-default-color;
    }
}

.dashboard-tasks-summary > p,
.dashboard-tasks-summary thead tr:first-child {
    background: $bg-header-tableTask;
}

.dashboard-quotes-summary > p,
.dashboard-quotes-summary thead tr:first-child {
    background: $bg-header-tableQuote;
}

.dashboard-quotes-summary :is(tbody, tfoot) td:nth-child(2n+1) {
    border-left: none;
}
