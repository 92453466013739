@use 'scss/utils/_vars.scss' as *;

.pol-badge {
    font-size: 0.7rem;
    background: var(--pol-badge-bg, #{$info});
    color: var(--pol-badge-color, #{$white});
    line-height: 1;
    padding: 3px 6px;
    border-radius: 4px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
}

.pol-badge-success {
    --pol-badge-bg: #{$menu-actions};
    --pol-badge-color: #{$white};
}

.pol-badge-warning {
    --pol-badge-bg: #{$warning};
    --pol-badge-color: #{$white};
}

.pol-badge-error {
    --pol-badge-bg: #{$error};
    --pol-badge-color: #{$white};
}

.pol-badge-info {
    --pol-badge-bg: #{$info};
    --pol-badge-color: #{$white};
}
