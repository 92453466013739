.homepage {
    td {
        white-space: nowrap;
    }

    .page-list__content {
        padding-bottom: 30px;
    }

    .stat {
        margin-bottom: 15px;

        &__content {
            background: $header;
            color: $white;
            padding: 15px 25px;
            border-radius: 4px;
            box-shadow: 0 0 5px $box-shadow-column;
            height: 100px;
        }

        &__number {
            font-size: 2.5em;
            font-weight: 700;
            margin-bottom: 0;
            position: relative;
            z-index: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__name {
            position: relative;
            z-index: 1;
            font-size: 1.1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__icon {
            position: absolute;
            right: 30px;
            font-size: 5em;
            top: 50%;
            transform: translateY(-50%);
            color: darken($header, 5%);
        }

        &:nth-child(2) {
            .stat {
                &__content {
                    background: darken($header, 15%);
                }

                &__icon {
                    color: darken($header, 20%);
                }
            }
        }

        &:nth-child(3) {
            .stat {
                &__content {
                    background: darken($header, 25%);
                }

                &__icon {
                    color: darken($header, 30%);
                }
            }
        }

        &:nth-child(4) {
            .stat {
                &__content {
                    background: darken($header, 35%);
                }

                &__icon {
                    color: darken($header, 40%);
                }
            }
        }
    }

    .graph {
        margin-top: 35px;

        &__title {
            text-align: center;
            margin-bottom: 10px;
            font-size: 1.2em;
            color: $main-color;
        }
    }

    .table-stat {
        margin-top: 30px;
        overflow: auto;

        &__link{
            cursor: pointer;
            &:hover{
                color: $input-default-color;
            }
        }
        &__link{
            &-money {
                float: right;
            }
            cursor: pointer;
            &:hover{
                color: $input-default-color;
            }
        }

        &__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.1em;
            color: $white;
            font-weight: 700;
            position: relative;
            cursor: pointer;
            font-family: inherit;
            height: 45px;
            background-color: $header;
            left: 1px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__table {
            border-spacing: 0;
            width: 100%;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &__header {
            color: $white;

            th {
                border: .5px solid #fff;
                padding: 10px;
            }

            tr:first-child {
                th {
                    background-color: $bg-header-tableTask;
                    text-transform: uppercase;
                    font-weight: 400;
                }

                th:last-child {
                    border-top-right-radius: 10px;
                }
            }

            tr:nth-child(2) {
                th {
                    background-color: $bg-subHeader-tableTask;
                }
            }
        }

        &__body {
            td {
                border: .5px solid #fff;
                padding: 5px 10px;

                &:not(:first-child) {
                    text-align: center;
                }
            }

            tr {
                &:nth-child(even) {
                    &:not(.table-stat__body__total) {
                        td {
                            border-left: none;
                            background: $table-row;
                        }
                    }
                }
            }

            &__total {
                td {
                    padding: 10px;
                    color: $white;
                    background: $main-color;
                    font-weight: 700;
                }

                td:first-child {
                    border-bottom-left-radius: 10px;
                }
                td:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }

        &.quote {
            .table-stat {
                &__title {
                    background-color: $bg-quote-datatable-homepage;
                }

                &__table {
                    border-spacing: unset;
                    tr:first-child {
                        th {
                            background-color: $bg-header-tableQuote;
                            text-transform: uppercase;
                            font-weight: 400;
                            border: solid 1px $white;
                        }
                    }

                    tr:nth-child(2) {
                        th {
                            padding-left: 10px;
                            padding-right: 10px;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            font-weight: 400;
                            border: solid 0.5px $white;
                        }
                    }
                }

                &__body {
                    td {
                        border: solid 0.5px $white;

                        &:nth-child(odd){
                            border-left: none;
                        }
                        &:nth-child(even){
                            border-right: none;
                        }
                    }
                    &__total {
                        td {
                            border-radius: 0;
                        }

                        &:last-child {
                            td:first-child {
                                border-bottom-left-radius: 10px;
                            }

                            td:last-child {
                                border-bottom-right-radius: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .table-container.no-datas .react-bootstrap-table-pagination {
        display: block;
    }
}