@use 'scss/utils/_vars.scss' as *;

.quotes-products__thead.empty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 0.5rem;
}

.quotes-products__thead:not(.empty),
.quotes-products__item,
.quotes-products__tbody__section__total,
.quotes-products__prices-infos__grid {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 2fr 1fr 0.5fr 1fr 0.5fr 0.75fr 0.5fr;
    gap: 1rem;
}

.is-show :where(
.quotes-products__thead:not(.empty),
.quotes-products__item,
.quotes-products__tbody__section__total,
.quotes-products__prices-infos__grid
) {
    grid-template-columns: 0.5fr 0.5fr 2fr 1fr 0.5fr 0.5fr 0.5fr 0.75fr 0.5fr;
}

:is(
.quotes-products__thead:not(.empty),
.quotes-products__item,
.quotes-products__tbody__section__total,
.quotes-products__prices-infos__grid
) > * {
    min-width: 0;
}

.quotes-products__prices-infos {
    grid-column: span 8;
    justify-self: flex-end;
}

.quotes-products__thead {
    place-items: center;
    background: $table-row;
    border-radius: 3px;
    color: $input-border-bottom;
    font-size: 0.9rem;
    white-space: nowrap;
    font-weight: bold;
    padding-block: 0.5rem;
}

.quotes-products__thead .quotes-products__no-products > div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.65rem;
}

.quotes-products__thead .quotes-products__no-products i {
    font-size: 1.25rem;
    animation: rotate 1s infinite linear;

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
}

.products__thead__optional span {
    margin-left: 0.25rem;
    display: inline-grid;
    place-items: center;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    cursor: help;
    color: $white;
    background: $input-border-bottom;
}

.quotes-products__thead .products__thead__desc {
    justify-self: flex-start;
}

.quotes-products__thead .products__thead__quantity {
}

.quotes-products__thead .products__thead__discount {
}

.quotes-products__thead .products__thead__vat {
}

.quotes-products__thead .products__thead__total {
    justify-self: flex-end;
}

.quotes-products__thead .products__thead__actions {
    justify-self: stretch;
}

.quotes-products__thead.empty .products__thead__actions {
    min-width: 4.5rem;
}

.quotes-products__actions {
    display: flex;
    justify-content: space-evenly;
}

.quotes-products__actions button {
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    color: $white;
    aspect-ratio: 1 / 1;
    font-size: 1rem;
    border: unset;
    background: $input-border-bottom;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background: darken($input-border-bottom, 10%);
    }

    &:disabled {
        background: $input-border-bottom;
        color: #fff;
        cursor: not-allowed;
    }
}

.quotes-products__tbody__section {
    margin-block: 0.75rem;
    border-bottom: 4px solid $white;
}

.quotes-products__tbody__section > h3 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: $main-color;
    margin-left: 0.5rem;
}

.quotes-products__tbody__section__heading {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.quotes-products__tbody__section__heading > input {
    display: inline-block;
    border: none;
    border-bottom: 2px solid $input-border-bottom;
    margin-bottom: 0.5rem;
    padding-left: 0.4rem;
    padding-top: 0.25rem;
    padding-right: 2ch;
    font-size: 1rem;
    color: $main-color;

    &:focus {
        outline-color: transparent;
    }

    &::placeholder {
        color: transparentize($main-color, 0.6);
    }
}

.quotes-products__tbody__section__heading > button {
    border: none;
    background: unset;
    font-size: 1rem;
    color: $late;
}

.quotes-products__item {
    padding-block: 1rem;
}

.quotes-products__item:nth-child(odd) {
    background: #F5F7FF;
}

.quotes-products__item > div {
    color: #9B91D1;
}

.quotes-products__item .quotes-products__item__label__commentary {
    font-size: 0.65rem;
    font-style: italic;
    white-space: pre-line;
}

.quotes-products__item .quotes-products__item__label > div {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
}

.quotes-products__item .quotes-products__item__label .input-field {
    text-align: left;
}

.quotes-products__item .quotes-products__item__label textarea {
    resize: none;
    border-radius: unset;
    min-height: 3.5rem;
    text-align: left;

    &::placeholder {
        color: #BBB4E0;
        font-family: "Poppins", sans-serif;
        font-size: 0.7rem;
        font-weight: 500;
    }
}

.quotes-products__item .quotes-products__item__optional {
    text-align: center;
    vertical-align: middle;
}

.quotes-products__item .quotes-products__item__optional .input-field-checkbox-container {
    display: inline-grid;
    place-items: center;
    background: unset;
}

.quotes-products__item__attributes dl {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.3rem;
}

.quotes-products__item__attributes dl dt {
    font-weight: bold;
}

.quotes-products__item .quotes-products__item__price {
    justify-self: flex-end;
}

.quotes-products__item .quotes-products__item__quantity {
    justify-self: center;
}

.quotes-products__item .quotes-products__item__discount > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.quotes-products__item .quotes-products__item__discount > div > .input-field {
    flex: auto;
    border-radius: 5px 0 0 5px;
    border-right: unset;
}

.quotes-products__item .quotes-products__item__discount > div > .react-select {
    flex: 1;
}

.quotes-products__item .quotes-products__item__discount .react-select__control {
    border-radius: 0 5px 5px 0;
}

.quotes-products__item .react-select {
    min-width: 4rem;

    &__menu {
        text-align: left;
    }

    &__control {
        background: $white;
        border: 1px solid #9B91D1 !important;
        min-height: 2.3rem;
    }

    &__control--is-focused {
        outline: unset;
        box-shadow: unset;
    }

    &__single-value,
    &__indicator {
        color: #9B91D1;
    }

    &__indicator {
        padding: unset;
        padding-inline: 4px;
    }
}

.quotes-products__item .quotes-products__item__vat > p {
    text-align: center;
}

.quotes-products__item .quotes-products__item__actions > div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.25rem;
}

.quotes-products__item .quotes-products__item__actions .quotes-products__item__actions__btns {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.quotes-products__item .quotes-products__item__actions button {
    border: 1px solid #A097D3;
    color: #A097D3;
    font-size: 1rem;
    background: $white;
    width: 1.5rem;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background: lighten(#A097D3, 27%);
    }
}

.quotes-products__item .quotes-products__item__total {
    font-weight: 600;
    justify-self: flex-end;
    padding-right: 0.5rem;
    text-align: end;
}

.quotes-products__item .quotes-products__item__total .discount {
    font-weight: 400;
    text-decoration: line-through;
    font-size: 0.65rem;
}

.quotes-products__item .input-field {
    width: 100%;
    border: 1px solid #9B91D1;
    background: $white;
    border-radius: 5px;
    height: 2.3rem;
    padding: 0.25rem 0.5rem;
    color: #9B91D1;
    text-align: right;

    &:focus {
        outline: none;
    }
}

.quotes-products__item__product p:first-child {
    font-size: 0.9rem;
    font-weight: 500;
}

.quotes-products__item__product p {
    font-size: 0.65rem;
}

.quotes-products__item .quotes-products__item__img {
    img {
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
    }
}

.quotes-products__tbody__section__total {
    font-size: 0.8rem;
    color: #8070C7;
    background: #F5F3FC;
    text-align: right;
    padding-block: 0.4rem;
    border-bottom: 2px solid transparentize(#8070C7, 0.8);
}

.quotes-products__tbody__section__total span:first-child {
    grid-column: span 7;
}

.quotes-products__tbody__section__total span:last-child {
    font-weight: 500;
}

.quotes-products__tbody__droppable {
    min-height: 4rem;
}

.quotes-products__add-section button {
    border: none;
    background: $input-border-bottom;
    color: $white;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
        background: darken($input-border-bottom, 10%);
    }

    &:disabled {
        background: $disabled;
        color: $disabled-font-color;
        cursor: not-allowed;
    }
}

.quotes-products__add-section button > i {
    font-size: 1.2rem;
}
