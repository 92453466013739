.documents-holder:not(:empty) {
    display: flex;
    padding: 0.5rem 1rem;
}

.documents-holder.multiple {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 0.5rem;
}

.documents-holder .document {
    position: relative;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: space-between;
    column-gap: 0.4rem;
    align-items: center;
    background: #f3f1fc;
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    isolation: isolate;
}

.documents-holder .document p {
    font-size: 0.85rem;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.documents-holder .document .file-icon {
    font-size: 1.5rem;
    color: #7565c0;
    margin-right: 0.4rem;
    vertical-align: middle;
}

.documents-holder .document.is-image {
    aspect-ratio: 1;
    background: unset;
    justify-content: center;
}

.documents-holder .document.is-image .document-delete {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
}

.documents-holder .document.is-image img {
    transition: filter 0.3s ease-in-out;
    object-fit: cover;
    height: 100%;
    user-select: none;
}

.documents-holder .document.is-image.is-delete img {
    filter: grayscale(1);
}

.documents-holder .document.is-image.is-delete .input-field-checkbox-container:before {
    background: unset;
}

.documents-holder .document-delete .input-field-checkbox-container {
    padding: unset !important;
}

.documents-holder .document-delete .input-field-checkbox-container input {
    display: none;
}

.documents-holder .document-delete .input-field-checkbox-container:before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    background: transparent;
    transition: background-color 0.2s ease-in-out;
    pointer-events: none;
}

.documents-holder .document-delete .input-field-checkbox-container[data-checked="true"]:before {
    background: rgba(238, 56, 56, 0.2);
}

.documents-holder .document-delete .input-field-checkbox-container[data-checked="true"] ~ .delete-icon:after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -55%) rotate(15deg);
    font-size: 1.75rem;
    color: #fff;
    //-webkit-text-stroke: 1px #000;
    text-shadow: 0 0 4px #000;
}

.documents-holder .document-delete .delete-icon {
    position: relative;
    font-size: 1.5rem;
    color: #ee3838;
    cursor: pointer;
}
