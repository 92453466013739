@use "scss/utils/_vars" as *;

.admin-saved-request > h2 {
    color: $main-color;
    text-transform: uppercase;
    font-size: 1rem;
}

.admin-saved-request__inputs {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
}

.admin-saved-request__inputs > div {
    display: flex;
    gap: 0.5rem;
}

.admin-saved-request__inputs > p {
    margin-block: 0.75rem 0.5rem;
}

.admin-saved-request__inputs {
    margin-block: 0.5rem 2rem;
}

.admin-saved-request__inputs > div > input {
    flex: auto;
    border: none;
    border-bottom: 2px solid $input-border-bottom;
    outline: none;
    font-family: inherit;
    font-size: 1.2em;
    border-radius: 5px;
    padding-left: 10px;
    width: 180px;
}

.admin-saved-request__inputs > div > button {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    background-color: $main-color;
    color: $white;
    cursor: pointer;
    border: 1px solid $main-color;
    border-radius: 5px;
    padding: 0.5rem 0.75rem;
    font-family: inherit;
    font-size: 0.75rem;
}

.admin-saved-request__inputs > div > button:disabled {
    background-color: $disabled;
    color: $disabled-font-color;
    border-color: $disabled;
    cursor: not-allowed;
    opacity: 0.9;
}

.input-field-group-wrapper {
    margin-top: 1.5rem;
}

.input-field-group-wrapper > .row > div {
    margin-bottom: 2.5rem;
}

.input-field-group__fields {
    box-shadow: 2px 2px 2px transparentize($black, 0.84);
    border-radius: 5px;
}

.input-field-group h3 {
    margin-bottom: 1.35rem;
    color: $main-color;
}
