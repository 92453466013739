.formpage {
    position: relative;
    left: 0;
    width: 100%;
    padding: 15px;
    top: 0;
    border-radius: 4px;
    background: $white;

    &.enter,
    &.exit {
        display: none;
    }

    &__title {
        font-size: 1.3em;
        text-transform: uppercase;
        font-weight: 700;
        color: $main-color;
        margin-bottom: 10px;
        padding: 0 15px;
    }

    &__delete {
        font-size: 1.3em;
        color: $main-color;
        float: right;
    }
}