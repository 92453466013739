* {
    margin: 0;
    padding: 0;
}

body {
    font-family: $ff-poppins;
    background: $bg;
    font-size: 12px;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

.row {
    &.row-fluid {
       margin: 0;
    }
}

[class^='col-'] {
    &.col-fluid {
        padding: 0;
    }
}

.wrap-content {
    padding: 0 15px;
}

.overflowh {
    overflow: hidden;
}

.env-header {
    color: $white;
    text-transform: uppercase;
    position: fixed;
    bottom: 0;
    padding: 10px;
    right: 0;
    min-width: 200px;
    z-index: 999;
    text-align: center;
    background-color: $env-bg;
}
