@use "scss/utils/_vars.scss" as *;

.intranet-export-popup {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.intranet-export-popup__fields {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.intranet-export-popup__field {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    font-size: 0.8rem;
    color: $main-color;

    &:nth-child(odd) {
        background: $lte-border-header;
    }

    &:nth-child(even) {
        box-shadow: 0 2px 2px transparentize($black, 0.9);
    }
}

.intranet-export-popup__field-value {
    font-weight: bold;
}

.intranet-export__btns {
    padding-right: 1rem;
    display: flex;
    gap: 1rem;
}

.intranet-export__btns > button {
    padding: 0.5rem 1.5rem;
    background: none;
    border: 1px solid $main-color;
    border-radius: 4px;
    font-size: 0.8rem;
    color: $main-color;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:where(:hover) {
        background: $main-color;
        color: $white;
    }
}

.intranet-export__btns .intranet-export__btns__export {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    background: $main-color;
    color: $white;

    &:where(:hover) {
        background: $white;
        color: $main-color;
    }

    &:disabled {
        background: $disabled;
        color: $disabled-font-color;
        border-color: $disabled;
        cursor: not-allowed;
    }
}
