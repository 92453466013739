@use "scss/utils/_vars.scss" as *;
@use "scss/utils/_respondTo.scss" as * with (
$breakpoints-min: $breakpoints-min,
$breakpoints-max: $breakpoints-max
);

.form-v4 {
    padding-bottom: 2rem !important;
    position: relative;
    isolation: isolate;
}

.form-v4__loader {
    position: absolute;
    z-index: 1;
    inset: -0.5rem;
    backdrop-filter: blur(3px);
}

.form-v4__loader .is-loading {
    position: sticky;
    top: calc(50% - 6rem);
    display: flex;
    justify-content: center;

    @include respond-to('mobile', false) {
        top: calc(50% - 4rem);
    }
}

.form-v4__loader .is-loading svg {
    width: 12rem;
    overflow: visible;
}

.form-v4 .is-error {
    color: #f13f3f;
    font-weight: 500;
    font-size: 0.85rem;
}

.form-entity-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-entity-loader .loader svg {
    overflow: visible;
    width: 12rem;
    max-width: 100%;
}

.form-entity-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.form-entity-actions .form__back {
    order: 0;
}

.form-entity-actions .form__submit {
    order: 2;
}

.form-entity-actions > :where(:not(.form__back):not(.form__submit)) {
    order: 3;
}

.form-entity-actions .form__recalculate {
    order: 1;
}

.form-entity-actions .form__recalculate .form__submit__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;

    border-color: $actions;
    background-color: $actions;
    color: $black;

    &:hover {
        background-color: darken($actions, 10%);
    }
}

.form-entity-actions .btn {
    @media (max-width: 768px) {
        > span {
            display: none;
        }
    }
}

.form-entity-actions .btn > i {
    font-size: 1.1rem;
    margin: unset;
}

.form-entity-actions .submit-and-add-prospect {
    height: 40px;
    padding-inline: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
}

.form-entity-actions .submit-and-add-prospect:disabled {
    background: $disabled;
    border-color: $disabled;
    color: $disabled-font-color;
    pointer-events: none;
}
