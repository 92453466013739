@use "scss/utils/_vars.scss" as *;

.vo-action__list-hover__container {
    position: relative;
}

.vo-action__list-hover {
    list-style: none;
    padding: 0;
    margin: 0;

    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    box-shadow: 0 0 7px rgba($black, 0.1);
    background: $white;
}

.vo-action__list-hover__item {
    cursor: pointer;
    font-size: 1rem;
    color: $black;
    user-select: none;
}

.vo-action__list-hover__item.square {
    display: grid;
    place-items: center;
    min-width: 2rem;
    border-radius: 0;
    aspect-ratio: 1;
}

.vo-action__list-hover__item.active:not(.disabled) {
    background: darken($secondary-color, 10%);
}

.vo-action__list-hover__item.disabled {
    cursor: not-allowed;
    color: $disabled-font-color;
}

.vo-action__list-hover__item:not(.disabled):hover {
    background: $secondary-color;
}

.vo-action__list-hover__item i {
    font-size: 1.2rem;
}
