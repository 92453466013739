@use "scss/utils/_vars.scss" as *;

.input-display-wrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.input-display-wrapper span {
    font-size: 0.8rem;
    cursor: pointer;
    color: $main-color;
    user-select: none;
}
