.savedSearch {
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;

  h2 {
    font-weight: 700;
    color: $main-color;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 1.3em;
  }

  h2:first-child {
    margin-bottom: 20px;
    font-size: 2em;
    color: $white;
    text-transform: unset;
    font-weight: 500;
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    > p {
      margin-top: 10px;
      margin-bottom: 10px;

      @include respond-to('mobile') {
        color: $font-color-sav-searchs;
        font-weight: 700;
      }

      @include respond-to('tablet') {
        color: $font-color-sav-searchs;
        font-weight: 700;
      }

      @include respond-to('desktop') {
        color: $black;
        font-weight: unset;
      }
    }

    > div {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      flex-direction: row;

      & div:first-child {
        flex: auto;
        z-index: 5;
      }

      .savedSearch_button {
        &--first {
          transition: .15s ease-in-out;
          outline: none;
          background-color: $main-color;
          color: $white;
          cursor: pointer;
          border: 1px solid $main-color;
          border-radius: 5px;
          padding-inline: 0.75rem;
          font-family: inherit;

          &:hover {
            background-color: $white;
            color: $main-color;
          }

          &:disabled {
            background-color: $disabled;
            color: $disabled-font-color;
            border-color: $disabled;
            cursor: not-allowed;
            opacity: .9;
          }

          @include respond-to('mobile') {
            margin-left: 10px;
          }
        }

        &--second {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 0.3rem;
          padding: 0.5rem 0.75rem;
          transition: .15s ease-in-out;
          outline: none;
          background-color: $main-color;
          color: $white;
          cursor: pointer;
          border: 1px solid $main-color;
          border-radius: 5px;
          font-family: inherit;

          &:hover {
            background-color: $white;
            color: $main-color;
          }

          &:disabled {
            background-color: $disabled;
            color: $disabled-font-color;
            border-color: $disabled;
            cursor: not-allowed;
            opacity: .9;
          }

          @include respond-to('mobile') {
            margin-left: 10px;
          }

          .policon-circle-notch {
            animation: 1s linear infinite loadingAnimation;
          }
        }
      }

      input {
        flex: 1;
        border: none;
        border-bottom: 2px solid $input-border-bottom;
        outline: none;
        font-family: inherit;
        transition: .15s ease-in-out;
        font-size: 1.2em;
        border-radius: 5px;
        padding-left: 10px;
        width: 180px;

        @include respond-to('mobile') {
          background-color: transparent;
        }

        @include respond-to('tablet') {
          background-color: transparent;
        }

        @include respond-to('desktop') {
          background-color: $input-bg;
        }
      }

      button {
        font-size: 1em;
      }
    }
  }
}

.extended {
  .savedSearch {
    > div {
      @include respond-to('mobile') {
        flex-direction: column;
        align-items: unset;
      }

      @include respond-to('tablet') {
        flex-direction: column;
        align-items: unset;
      }

      @include respond-to('desktop') {
        flex-direction: column;
        align-items: unset;
      }

      @include respond-to('large') {
        flex-direction: row;
        align-items: center;
      }

      p {
        font-weight: 700;
        color: $font-color-sav-searchs;
        font-size: 1.1em;

        @include respond-to('mobile') {
          width: unset;
          text-align: left;
        }

        @include respond-to('tablet') {
          text-align: left;
          width: unset;
        }

        @include respond-to('desktop') {
          text-align: left;
          width: unset;
        }

        @include respond-to('large') {
          text-align: center;
          width: 120px;
        }
      }

      > div {
        flex: 1;
        
        &:last-child {
          input {
            background-color: transparent;
            border-radius: unset;
            padding-left: unset;
            transition: all .25s ease-in-out;
            width: 250px;

            &:focus {
              padding-bottom: 5px;
            }
          }
        }
      }
    }
  }
}

@keyframes loadingAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
