// sass-lint:disable-all
.st0 {
    fill: #FFFFFF;
}

.st1 {
    fill: #F8F7FF;
}

.st2 {
    fill: #DDD6FF;
}

.st3 {
    fill: #C4B8FF;
}

.st4 {
    fill: #40327A;
}

.st5 {
    fill: #A796F2;
}

.st6 {
    fill: #FFC359;
}

.st7 {
    fill: #FF809F;
}

.st8 {
    fill: #FF95A9;
}

.st9 {
    fill: #008AD1;
}

.st10 {
    fill: none;
}

.st11 {
    fill: #E5659B;
}

.st12 {
    fill: #EEEBFF;
}