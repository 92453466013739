@use "scss/utils/_vars.scss" as *;

.notifs {
    background: $late;
    color: $white;
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: 3ex;
    height: 3ex;
    display: inline-grid;
    place-items: center;
    margin-left: 1ex;
    overflow: hidden;
    white-space: nowrap;
}
