@use "scss/utils/_vars.scss" as *;

.new-view-orders {
    height: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 1rem;
    margin-right: 1rem;
}

.new-view-orders__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-inline: 1rem;
}

.new-view-orders__header__actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.new-view-orders__header__submit.btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.new-view-orders__content {
    display: flex;
    flex-direction: column;
}

.new-view-orders__loader {
    display: grid;
    justify-content: center;
    grid-template-columns: min(300px, 100%);

    svg {
        overflow: visible;
    }
}

.new-view-orders__scrollable {
    flex: auto;
    height: 0;
    overflow: auto;
}

.new-view-orders__content > .new-view-orders__scrollable {
    padding-inline: 1rem;
}

.new-view-orders__content__legends {
    position: relative;
}

.new-view-orders__content__legends__icon {
    cursor: help;
    color: $main-color;
    font-size: 1.5rem;
    width: 2rem;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 3px solid $main-color;
}

.new-view-orders__content__legends__content {
    position: absolute;
    z-index: 2;
    top: 100%;
    right: 0;
    min-width: 200px;
    white-space: nowrap;
    padding: 0.5rem 0.75rem;
    background: #eaecfa;
    font-size: 0.9rem;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;

    &:before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 0.5rem;
    }
}

.new-view-orders__content__legends:hover .new-view-orders__content__legends__content {
    visibility: visible;
    opacity: 1;
}

.new-view-orders__content__legends__content dl {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    line-height: 1;
    gap: 0.3rem 2rem;

    & :nth-child(2n) {
        justify-self: flex-end;
    }
}

.new-view-orders__content__legends__content dl dt {
    line-height: 1.2rem;
    color: $main-color;
}
.new-view-orders__content__legends__content dl dt i {
    font-size: 1.2rem;
}

.new-view-orders__grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: flex-start;
    gap: 0.3rem;

    .vo-col- {
        @for $i from 1 through 12 {
            &#{$i} {
                grid-column: span $i;
            }
        }
    }
}

.new-view-orders__grid:empty:before {
    content: attr(data-empty-text);
    display: flex;
    align-items: center;
    grid-column: span 12;
    min-height: 2.5rem;
}

.new-view-orders__grid.group {
    gap: 1rem;
}

.new-view-orders__group {
    padding: 0.5rem 1.25rem 1rem;
    position: relative;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
    background: #eaecfa;
    border-radius: 4px;
}

:is(.new-view-orders__group__left-actions, .new-view-orders__group__right-actions) {
    position: absolute;
    z-index: 1;
    top: 50%;

    display: flex;
    flex-direction: column;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.new-view-orders__group__left-actions {
    left: 0;
    transform: translate(-50%, -50%);
}

.new-view-orders__group__right-actions {
    right: 0;
    transform: translate(50%, -50%);
}

.new-view-orders__group:hover :is(.new-view-orders__group__left-actions, .new-view-orders__group__right-actions) {
    visibility: visible;
    opacity: 1;
}

.new-view-orders__group.required {
    background: transparentize($error, 0.93);
}

.new-view-orders__grid + .new-view-orders__group.required {
    margin-top: 1rem;
}

.new-view-orders__group__header {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.new-view-orders__group__icon {
    font-size: 1.5rem;
    color: $main-color;
}

.new-view-orders__group__label {
    position: relative;
    font-size: 1rem;
    min-width: 50px;
    color: $main-color;
    border-bottom: 1px solid $input-border-bottom;
    padding-right: 0.5rem;
    overflow: hidden;
}

.new-view-orders__group__label span {
    opacity: 0;
    white-space: nowrap;
}

.new-view-orders__group__label input {
    position: absolute;
    inset: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: transparent;
}

.new-view-orders__group__actions {
    display: flex;
    gap: 0.3rem;
    margin-left: auto;
}

.new-view-orders__group__icon-not-registered {
    color: $error;
    font-size: 1.2rem;
}

.new-view-orders__field {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    min-height: 2.5rem;
    align-items: center;
    border-radius: 4px;
    background: $lte-border-header;
    color: $main-color;
}

.new-view-orders__field:nth-child(even) {
    background: $white;
}

.new-view-orders__group.sortable-ghost,
.new-view-orders__field.sortable-ghost {
    opacity: 0.5;
}

.new-view-orders__group__icon,
.new-view-orders__field__icon {
    cursor: move;
    user-select: none;
}

.new-view-orders__field__label {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    hyphens: auto;
    word-break: break-word;
    line-height: 1.2;
}

.new-view-orders__field__label > i {
    font-size: 1.5rem;
}

.new-view-orders__field__label-icons {
    line-height: 1;
    padding-block: 0.25rem;
}

.new-view-orders__field__label__specific {
    font-style: italic;
    font-weight: 500;
}

.new-view-orders__field__label-icons > i {
    float: left;
    font-size: 1rem;
    margin-right: 0.2rem;
}

.new-view-orders__field__label-icons > span {
    vertical-align: sub;
}

.new-view-orders__field__label-icons > i + span {
    line-height: 1.2;
}

.new-view-orders__field__actions {
    display: flex;
    gap: 0.3rem;
}

.new-view-orders__aside {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: $table-row;
    padding: 1rem;
    border-radius: 4px;
}

.new-view-orders__available-fields {
    flex: 1;
    display: flex;
    flex-direction: column;
    user-select: none;
}

.new-view-orders__available-fields__search {
    margin-bottom: 0.5rem;
}

.new-view-orders__available-fields__search input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid $input-border-bottom;
    border-radius: 4px;
    outline: none;
}

.new-view-orders__available-fields .new-view-orders__field {
    cursor: move;
}

.vo-action__button {
    display: grid;
    place-items: center;
    min-width: 1.75rem;
    aspect-ratio: 1;
    background: none;
    border: 1px solid $main-color;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2rem;
    background: $white;
}
