@use "scss/utils/_vars.scss" as *;

.map {
    position: fixed;
    z-index: 3;
    inset: 0;
    background: $white;
    isolation: isolate;
}

.map__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    color: $main-color;
    padding-inline: 0.75rem;
    height: 50px;
}

.map__header i {
    display: block;
    font-size: 1.3rem;
}

.map__header > div {
    cursor: pointer;
}

.map__header__name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.map__header__name i {
    font-size: 1rem;
}

.leaflet-container {
    height: calc(100vh - 50px);
}

.map__wrapper__map {
    position: relative;
    isolation: isolate;
}

.leaflet-container {
    position: relative;
    z-index: -1;
}

.map__wrapper__map__help {
    position: absolute;
    z-index: 1;
    bottom: 1rem;
    right: 1rem;
    color: #fff;
    max-width: 75ch;
    line-height: 1.75;
    font-size: 0.75rem;
    background: rgba(55, 46, 116, 0.8);
}

.map__wrapper__map__help i {
    display: block;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    background: #372e74;
    aspect-ratio: 1/1;
}

.map__wrapper__map__help.show i {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.1rem;
}

.map__wrapper__map__help:not(.show) div {
    display: none;
}

.map__wrapper__map__help.show div {
    margin: 1.5rem 2.5rem;
}

.map__wrapper__map__prospect {
    position: absolute;
    z-index: 1;
    top: 0.5rem;
    left: 3rem;
    font-size: 0.85rem;
    color: #fff;
    background: rgba(55, 46, 116, 0.6);
}

.map-filter {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    display: flex;
    gap: 1.2rem;
}

.map-filter__distance {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    background: transparentize($black, 0.4);
    color: $white;
    margin-top: 0.75rem;
    padding: 0.75rem;
    font-weight: 500;
}

.map-filter__distance input {
    width: 3rem;
    padding-block: 0.25rem;
    text-align: center;
}

.map-filter__distance button {
    background: $main-color;
    color: $white;
    border: none;
    height: 100%;
    padding-inline: 0.6rem;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
        background: $white;
        color: $main-color;
    }
}

.map-filter__filters__icon {
    width: 2rem;
    aspect-ratio: 1/1;
    background: $white;
    display: grid;
    place-items: center;
    font-size: 1rem;
    cursor: pointer;
}

.map-filter__filters__icon i {
    display: block;
}

.map-circle {
    stroke: #372e74;
    fill-opacity: 0.05;
}

.pulse {
    stroke-dasharray: 10, 10;
    fill-opacity: 0.3;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        stroke-dashoffset: 0;
        fill: #372e74;
    }
    50% {
        stroke-dashoffset: 20;
        fill: transparent;
    }
    100% {
        stroke-dashoffset: 0;
        fill: #372e74;
    }
}
