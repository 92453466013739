.import-files-button {
    display: block !important;
    margin-left: auto;
    margin-bottom: 0.5rem;

    .policon {
        display: inline-block;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}
