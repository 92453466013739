$breakpoints-max: () !default;
$breakpoints-min: () !default;

@mixin respond-to($breakpoint, $mobile-first: true) {
    @if $mobile-first == true {
        @if map-has-key($breakpoints-min, $breakpoint) {
            @media #{inspect(map-get($breakpoints-min, $breakpoint))} {
                @content;
            }
        } @else {
            @error 'No value found for `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$breakpoints` map.';
        }
    } @else {
        @if map-has-key($breakpoints-max, $breakpoint) {
            @media #{inspect(map-get($breakpoints-max, $breakpoint))} {
                @content;
            }
        } @else {
            @error 'No value found for `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$breakpoints` map.';
        }
    }

}
