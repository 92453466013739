@use "scss/utils/_vars.scss" as *;

.manage-task__row {
    padding: 0.5rem;
    background: transparentize($secondary-color, 0.6);
    border-radius: 4px;
    color: $input-color;
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
}

.manage-task__row:not(:last-child) {
    margin-bottom: 0.5rem;
}

.manage-task__row__title {
    font-size: 0.9rem;
}

.manage-task__row__title a {
    font-weight: bold;
}

.manage-task__row__actions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.75rem;
}

.manage-task__row__actions__action {
    border: 1px solid $main-color;
    color: $main-color;
    border-radius: 4px;
    cursor: pointer;
}

button.manage-task__row__actions__action {
    background: none;
    padding: 0.5rem 1rem;
    transition: background 0.3s ease, color 0.3s ease;

    &:where(:hover, .active) {
        background: $main-color;
        color: $white;
    }
}

button.manage-task__row__actions__action:disabled {
    background: $disabled;
    color: $disabled-font-color;
    border-color: $disabled;
    cursor: not-allowed;
}

button.manage-task__row__actions__action.renew-validate {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    background: $main-color;
    color: $white;

    &:hover {
        background: none;
        color: $main-color;
    }
}

.manage-task__row__actions__action.renew {
    grid-column: span 2;
}

.manage-task__row__actions__action:where(
.close,
.renew-date
) {
    grid-column: 1 / span 2;
    grid-row: 1;
}

.manage-task__row__actions__action.renew-date {
    cursor: unset;
    border-color: $white;
    background: $white;
}

.manage-task__row__actions__action.renew-date input {
    display: block;
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    color: $main-color;
    font-size: 0.9rem;
    text-align: center;
    width: 100%;
    height: 100%;

    &:focus {
        outline: transparent;
    }
}
