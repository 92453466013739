.page-list {
    order: 1;

    &__content {
        position: relative;
        left: 0;
        width: 100%;
        padding: 15px;
        top: 0;
        border-radius: 4px;
        background: $white;
        min-height: 55vh;
    }

    &.page-enter {
        transform: translate3d(-100%, 0, 1px);
    }

    &.page-enter-active {
        transform: translate3d(0, 0, 1px);
        transition: transform $transition-page linear;
    }

    &.page-exit {
        transform: translate3d(0, 0, 1px);
    }

    &.page-exit-active {
        transform: translate3d(-100%, 0, 1px);
        transition: transform $transition-page linear;
    }
}
