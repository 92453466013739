$bg: #eee;
$shadow: #f8f8f8;
$main-color: #372e74;
$secondary-color: #c8befe;
$active-class: #3860c4;
$header: #ccc4f3;

$black: #000;
$white: #fff;
$grey: #333;
$soft-green: #c8e6c9;
$disabled-font-color: #5f5f5f;
$user-chevron: #f299b0;
$box-shadow: rgb(200, 200, 200);
$box-shadow-column: rgba(0, 0, 0, .25);
$bg-search: rgba(255, 255, 255, .95);
$bg-search-title: rgba(255, 255, 255, .9);
$bg-search-load: rgba(255, 255, 255, .75);
$bg-search-load-not-extended: rgba(157, 158, 218, .75);
$input-bg: #f3f1fc;
$input-default-color: #8577c7;
$input-color: darken($input-default-color, 5%);
$input-color-focus: lighten($input-default-color, 10%);
$input-bg-multi: rgba(255, 255, 255, .7);
$box-shadow-error: rgba(255, 255, 255, .5);
$disabled : #ccc;
$background-folder : #dcdbde;
$breadscrumb-separator : #ccc4f4;
$input-border-bottom : #7565c0;

$bg-maps-distance: rgba(0, 0, 0, .6);
$bg-maps-popin: rgba(100, 100, 100, .8);

$admin-border-group: $box-shadow-column;
$admin-shadow-drag: rgba(0, 0, 0, .6);
$admin-bg-group: rgba(0, 0, 0, .8);

$last-views: #9589c0;
$success: #70dd85;
$warning: #fcc151;
$error: #e74c3c;
$info: #3498db;
$late: #e86565;

$bg-quote-datatable-homepage : #705add;
$bg-header-tableTask : #BAAFEF;
$bg-subHeader-tableTask : #D1D1D1;
$bg-header-tableQuote: #5E45D9;

$list-hover: $header;

$header-content: $active-class;
$menu-user: #ed7493;
$actions: #fcc151;
$menu-border: $white;

$table-header: #9799a5;
$table-row: #eef0fa;

$round1: $actions;
$round2: $menu-user;
$round3: $last-views;

$menu-prospect: $active-class;
$menu-contacts: #9b59b6;
$menu-actions: #47ad3b;
$menu-devis: #e67e22;
$menu-articles: #00a0b0;
$menu-mails: #95a5a6;

$font-color-homepage-datatable: #494949;

$margin-menu-contacts: #fbf0ff;
$margin-menu-actions: #edffed;
$margin-menu-devis: #fff0d4;
$margin-menu-articles: #e4faff;
$margin-menu-mails: #95a5a6;

$lte-header: #605ca8;
$lte-logo: #555299;
$lte-sidebar: #222d32;
$lte-title-color: #4b646f;
$lte-title-bg: #1a2226;
$lte-connect: #3c763d;
$lte-link: #b8c7ce;
$lte-submenu: #2c3b41;
$lte-submenu-color: #8aa4af;
$lte-submenu-sticky: #1e282c;
$lte-border-box: #d2d6de;
$lte-border-header: #f4f4f4;
$lte-list-link: $last-views;
$lte-active-link: #555299;
$lte-initials: #D3D3D3;
$lte-box-header: #444;
$list-prospect-background: #e1e4f5;
$font-color-sav-searchs: #a49bd6;

$nav-actions: #949aa4;
$nav-actions-active: #74b431;

$potential-strong: #7fdc73;

$border: #ccc;
$loader-init: #dedede;
$loader-final: $shadow;
$close: $error;
$login-load: rgba(150, 150, 150, 0.8);
$timeline-bg: #615990;
$env-bg: #ff685b;
$error-lighter: #FFA3B8;

$border-button-ged: #e6e5f9;
$background-selected-ged : rgba(200, 190, 254, 0.4);

$page404-color1: $border;
$page404-color2: #b1b1b1;
$page404-color3: #b8b8b8;
$page404-color4: #a1a1a1;
$page404-color5: rgba(0, 0, 0, .1);
$page404-color6: rgba(0, 0, 0, .15);
$page404-color7: rgba(0, 0, 0, .2);
$page404-color8: rgba(0, 0, 0, .25);
$page404-color9: rgba(0, 0, 0, .3);

$map-info-header: rgba(55, 46, 116, .6);
$map-info-content: rgba(55, 46, 116, .2);
$map-info-address: rgba(55, 46, 116, .4);
$map-info-footer: $map-info-header;

$background-search-menu: #9D9EDA;

$color-unique: rgb(255, 205, 145);

$transition-page: 400ms;

$ff-poppins: 'Poppins', sans-serif;

$breakpoints-min: (
    'mobile': (max-width: 767px),
    'tablet': (min-width: 768px),
    'desktop': (min-width: 992px),
    'desktop-max': (max-width: 1199px),
    'large': (min-width: 1200px),
);


$breakpoints-max: (
    'mobile': (max-width: 767px),
    'tablet': (max-width: 991px),
    'desktop': (max-width: 1199px),
    'desktop-max': (max-width: 1199px),
);
