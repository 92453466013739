@use "scss/utils/_vars.scss" as *;

.listing-header__action__item.stats > div {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.listing-header__action__item.stats .svg-arrows {
    width: .9rem;
    height: .9rem;
    color: $black;

    &.spin {
        animation: spin 1s linear infinite;

        @keyframes spin {
            to {
                transform: rotate(360deg);
            }
        }
    }
}

.listing-header__action__item.stats .svg-arrows svg {
    display: block;
    width: 100%;
    height: 100%;
}
