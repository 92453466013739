@use "scss/utils/_vars.scss" as *;

.debug-bar {
    --_show-transition: 0.3s;
    position: fixed;
    inset-inline: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 0fr 40px;
    justify-content: flex-end;
    height: 40px;
    transition: grid-template-columns var(--_show-transition);
    z-index: 9999;
    pointer-events: none;
}

.open {
    &.debug-bar {
        grid-template-columns: 1fr 40px;
        pointer-events: auto;
    }

    .debug-bar__toggle .policon {
        transform: rotate(180deg);
    }
}

.debug-bar__toggle {
    pointer-events: auto;
    border: none;
    background: unset;
    background: $secondary-color;
    font-size: 1rem;
    cursor: pointer;
}

.debug-bar__toggle .policon {
    display: block;
    transition: transform 0.3s;
}

.debug-bar__row {
    display: flex;
    background-color: $main-color;
    font-size: 0.9rem;
    color: $white;
    overflow: hidden;
}

.open .debug-bar__row {
    animation: wait-transition var(--_show-transition) forwards;

    @keyframes wait-transition {
        to {
            overflow: unset;
        }
    }
}

.debug-bar__item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-inline: 1rem;
    font-weight: 500;
    border-right: 1px solid $secondary-color;
    max-width: 200px;

    &:hover {
        background-color: lighten($main-color, 20%);
    }
}

.debug-bar__item.uri {
    max-width: 350px;
}

.debug-bar__item :is(span, a) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.debug-bar__item-hover {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 100%;
    left: 0;
    border-bottom: 1px solid $white;
    background: $main-color;
    padding: 0.5rem;
    transition: visibility 0.3s, opacity 0.3s;
}

.debug-bar__item:hover .debug-bar__item-hover {
    visibility: visible;
    opacity: 1;
}

.debug-bar__item-hover hr {
    max-width: 25px;
    margin: 0.25rem auto;
}

.debug-bar__item-hover-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;

    &:not(:last-child) {
        margin-bottom: 0.4rem;
    }
}

.debug-bar__item-hover-row.subrequest {
    display: grid;
    grid-template-columns: 95px 50px max-content;
    gap: unset;
    justify-content: unset;
}

.debug-bar__item-hover-row.subrequest span {
    margin-inline: 0.25rem;
}

.debug-bar__item-hover-row.subrequest span:first-child {
    text-align: center;
}

.debug-bar__item-hover-row.align-start {
    align-items: flex-start;
}

.debug-bar__item-hover-row__list-value {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 0.3rem;
    align-items: flex-end;
}

.debug-bar__item-hover-row__list-value span {
    font-size: 0.65rem;
    padding: 4px;
    background: lighten($main-color, 25%);
    border-radius: 4px;
}

.debug-bar__token-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    min-width: 90px;
}

.debug-bar__token-item__select__icon {
    display: block;
    padding: 0.5rem;
    margin-right: -0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: $main-color;
    }
}

.debug-bar__token-item__select__icon:before {
    display: block;
    transition: transform 0.3s;
}


.debug-bar__token-item__select.open .debug-bar__token-item__select__icon:before {
    transform: rotate(-180deg);
}

.debug-bar__token-item__select__list-container {
    position: absolute;
    bottom: 100%;
    left: 0;
    display: none;
    flex-direction: column-reverse;
    width: max-content;
    min-width: 100%;
    max-height: 350px;
    overflow-x: clip;
    overflow-y: auto;
    background: $main-color;
    border-bottom: 1px solid $white;
}

.debug-bar__token-item__select.open .debug-bar__token-item__select__list-container {
    display: flex;
}

.debug-bar__token-item__select__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    line-height: 1;
}

.debug-bar__token-item__select__list__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:is(:hover, .selected) {
        background-color: lighten($main-color, 20%);
    }
}

.debug-bar__token-item__select__list__item.latest {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: $main-color;
}

.debug-bar__token-item__select__list__item:not(.latest) {
    padding-left: unset;
    display: grid;
    align-items: center;
    grid-template-columns: 75px 45px 95px 50px 1fr;
}

.debug-bar__token-item__select__list__item:not(.latest) span {
    margin-inline: 0.25rem;
}

.debug-bar__token-item__select__list__item:not(.latest) span.pol-badge {
    text-align: center;
}

.debug-bar__token-item__select__list__item:not(.latest) span:first-child {
    justify-self: center;
}

.debug-bar__token-item__select__list__item:not(.latest) span:last-child {
    justify-self: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;

}

.debug-bar__loader {
    padding-left: 1rem;
}

.debug-bar__loader i.policon {
    color: $white;
}
