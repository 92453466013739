@mixin menu-color-mobile {
    &.contacts {
        color: $menu-contacts;
    }

    &.actions {
        color: $menu-actions;
    }

    &.devis {
        color: $menu-devis;
    }

    &.articles {
        color: $menu-articles;
    }

    &.mails {
        color: $menu-mails;
    }
}

@mixin margin-menu-color {
    &.contacts {
        color: $menu-contacts;
        background: $margin-menu-contacts;
    }

    &.actions {
        color: $menu-actions;
        background: $margin-menu-actions;
    }

    &.devis {
        color: $menu-devis;
        background: $margin-menu-devis;
    }

    &.articles {
        color: $menu-articles;
        background: $margin-menu-articles;
    }

    &.mail-type {
        color: $white;
        background: $menu-mails;
    }

    &.prospects {
        color: $active-class;
        background: $table-row;
    }
}

@mixin menu-color {
    &.contacts {
        background: $menu-contacts;
        color: $white;
    }

    &.actions {
        background: $menu-actions;
        color: $white;
    }

    &.devis {
        background: $menu-devis;
        color: $white;
    }

    &.articles {
        background: $menu-articles;
        color: $white;
    }

    &.mail-type {
        background: $menu-mails;
        color: $white;
    }

    &.mails {
        background: $menu-mails;
        color: $white;
    }

    &.prospects {
        background: $active-class;
        color: $white;
    }
}

@mixin table-header-color {
    &.datatable-loader {
        & thead {
            background: $nav-actions;
            color: $white;
        }
    }

    &.contacts {
        & thead {
            background: $menu-contacts;
            color: $white;
        }
    }

    &.actions {
        & thead {
            background: $menu-actions;
            color: $white;
        }
    }

    &.devis {
        & thead {
            background: $menu-devis;
            color: $white;
        }
    }

    &.articles {
        & thead {
            background: $menu-articles;
            color: $white;
        }
    }

    &.mail-type {
        & thead {
            background: $menu-mails;
            color: $white;
        }
    }

    &.prospects {
        & thead {
            background: $active-class;
            color: $white;
        }
    }
}