@use "scss/utils/_vars.scss" as *;

.prospect__view {
    background: $list-prospect-background;
}

.prospect__view__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $menu-prospect;
    color: $white;
    position: relative;
    isolation: isolate;
    border-top-left-radius: 4px;
    height: 2.5rem;
}

.prospect__view__header__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    font-weight: bold;
}

.prospect__view__header i {
    font-size: 1.2rem;
}

.prospect__view__header__close {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
}

.prospect__view__actions {
    display: flex;
    justify-content: space-between;
    color: $menu-prospect;
    margin-inline: 1rem;
    margin-top: 1rem;
    height: 2rem;

    @media print {
        display: none;
    }
}

.prospect__view__actions__relative {
    position: relative;
    isolation: isolate;
    z-index: 1;
}

.prospect__view__actions__list {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.prospect__view__actions__list li {
    font-size: 1.2rem;
}

.prospect__view__actions__list li.disabled {
    background: $disabled;
    color: $disabled-font-color;
    cursor: not-allowed;

    &:hover {
        background: $disabled;
        color: $disabled-font-color;
    }
}

.prospect__view__actions__list li:first-child {
    cursor: default;
}

.prospect__view__actions__list:not(:hover) li:not(:first-child) {
    display: none;
}

.prospect__view__actions__expand,
.prospect__view__actions__list li {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    cursor: pointer;
    border-radius: 4px;
    padding: 0.25rem;
    background: $white;
    box-shadow: 0 2px 2px transparentize($black, 0.84);
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
        background: $main-color;
        color: $white;
    }
}

.prospect__render {
    padding: 1rem;
}

.prospect__render .show-layout > .row:first-of-type {
    margin-inline: 0;
}

.prospect__render.editing {
    background: $white;
    margin: 2.8rem;
    border-radius: 5px;
}

.prospect__render__subscriptions {
    margin-inline: 15px;
}

.prospect__render__subscriptions h4 {
    padding-top: 0.75rem;
    padding-left: 0.5rem;
}

.prospect__render__subscriptions__title,
.prospect__render__subscriptions__desc {
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    background: $lte-border-header;
    color: $main-color;
    font-weight: bold;
}

.prospect__render__subscriptions__desc {
    background: $white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.18);
}

.prospect__render__subscriptions__title {
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.prospect__render__subscriptions:not(.expanded) .prospect__render__subscriptions__title:not(.current) {
    display: none;
}

.prospect__render__subscriptions__btn {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    background: unset;
    border: unset;
    margin: 0.5rem;
    cursor: pointer;
    color: $main-color;
    font-weight: bold;
}

.prospect__render__subscriptions__btn i {
    font-size: 0.6rem;
    transition: transform 0.2s ease-in-out;
}

.prospect__render__subscriptions.expanded .prospect__render__subscriptions__btn i {
    transform: rotate(180deg);
}

.prospect__page.fullpage .prospect__render__subscriptions {
    width: calc(50% - 15px);
}
