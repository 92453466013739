.summaryTask {
  &__header {
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -10px;
    min-height: 60px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);

    > div:first-child {
      padding-left: 45px;
    }

    > div:last-child {
      padding-right: 45px;
    }

    h2 {
      color: $main-color;
      font-size: 1.15em;
    }

    &__button {
      height: 40px;
      width: 40px;
      margin-left: 15px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      cursor: pointer;
      opacity: .5;

      &--active {
        opacity: 1;
      }

      &--ongoing {
        background-color: $success;
      }

      &--late {
        background-color: $late;
      }

      &--closed {
        background-color: $login-load;
      }

      .policon {
        font-size: 2.1em;
        color: white;
      }
    }
  }

  &__container {
    margin-left: -40px;
    margin-right: -40px;
    height: 550px;
    position: relative;
    overflow: hidden;
    margin-bottom: -25px;

    > div {
      z-index: 1;
      height: 550px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-left: 45px;
      padding-right: 45px;
      position: relative;
    }

    &--empty {
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $main-color;
        font-size: 1.1em;
      }
    }
  }

  &__timeline {
    position: relative;
    height: auto;
    margin-bottom: 3px;
    padding-bottom: 35px;

    &:first-of-type:before {
        content: '';
        position: absolute;
        bottom: calc(100% + 0.25rem);
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 3rem;
        background-color: $main-color;
    }

    &:not(:last-of-type):after {
        content: '';
        position: absolute;
        top: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: calc(100% - 2.5rem);
        background-color: $main-color;
    }

    &__state {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.3rem;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @include respond-to('mobile') {
        left: 10%
      }

      .policon {
        font-size: 1.5rem;
        color: $white;
        position: relative;
        left: .5px;
      }

      &--closed {
        background-color: $login-load;
      }

      &--late {
        background-color: $late;
      }

      &--ongoing {
        background-color: $success;
      }
    }

    &__line {
      position: absolute;
      height: calc(100% - 43px);
      width: 2px;
      background-color: $timeline-bg;
      border-radius: 6px;
      left: 50%;
      transform: translateX(-50%);
      top: 46px;

      @include respond-to('mobile') {
        left: 10%;
      }

      &--last {
        display: none;
      }
    }

    &--first {
      height: 50px;
      position: relative;

      > div {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 2px;
        background-color: $timeline-bg;
        border-radius: 6px;

        @include respond-to('mobile') {
          left: 10%;
        }
      }
    }

    &__content {
      position: relative;
      top: 3px;
      width: 45%;

      @include respond-to('mobile') {
        width: 80%;
        padding-top: 50px;
      }

      &--left {
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include respond-to('mobile') {
          left: 20%;
          display: flex;
          align-items: flex-start;
        }
      }

      &--right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        left: 55%;

        @include respond-to('mobile') {
          left: 20%;
          display: flex;
        }
      }

      &__title {
        background-color: $last-views;
        color: white;
        padding: 5px 10px 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        font-size: 1.2em;
        min-width: 125px;
        max-width: 75%;
        position: relative;
        line-break: anywhere;
        text-align: center;
        cursor: pointer;
      }

      &__info {
        background-color: $input-bg;
        box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.16);
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        padding: 5px 5px 15px 5px;
        position: relative;
        width: 100%;

        &--title {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
          align-content: center;
          height: 25%;
          margin-top: 5px;

          div:first-child {
            font-size: 1.15em;
            color: $font-color-sav-searchs;

            span {
              font-weight: 500;
              color: $main-color;

              &:first-child {
                margin-right: 5px;
              }

              &:last-child {
                margin-left: 5px;
              }
            }
          }

          div:last-child {
            color: $font-color-sav-searchs;
            font-size: 1.15em;
            font-weight: 500;
          }
        }

        &--text {
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
          padding: 5px 10px 10px 10px;
          margin-top: 5px;
          position: relative;
          z-index: 2;

          > div {
            margin-bottom: 10px;
            background-color: $white;
            height: 80px;
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
            overflow-x: hidden;
            overflow-y: scroll;
            padding: 5px;

            &::-webkit-scrollbar {
              width: 10px;
            }

            &::-webkit-scrollbar-track {
              background: $header;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
              background: $white;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: $white;
            }

            > div:first-child {
              font-weight: 500;
              color: $header;
              margin-bottom: 5px;
            }
          }

          .resizable-v {
            resize: vertical;
            max-height: 33vh;
            min-height: 80px;
          }
        }

        &--none {
          overflow-y: hidden !important;
          height: unset !important;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $header !important;
            height: 30px !important;
            background-color: $white;
            margin-bottom: 0 !important;
          }
        }
      }

      &--date {
        position: absolute;
        width: 45%;
        top: 5px;

        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: $white;
          background-color: $main-color;
          font-size: 1.35em;
          width: 125px;
          height: 35px;
          border-radius: 6px;
        }

        &--left {
          left: 0;
          display: flex;
          justify-content: flex-end;

          @include respond-to('mobile') {
            left: 20%;
            justify-content: flex-start;
          }
        }

        &--right {
          left: 55%;

          @include respond-to('mobile') {
            left: 20%;
          }
        }
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;

    &--finish {
      left: unset;
      right: 0;
    }

    &--all {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
