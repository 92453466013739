@use "scss/utils/_vars.scss" as *;

.resume-tab {
  height: 100%;
}

.resume-tab__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $main-color;
  margin-bottom: 1rem;
}

.resume-tab__header__actions {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.resume-tab__header__actions__filters {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.resume-tab__body {
  position: relative;
  isolation: isolate;
  margin-inline: -2rem;
  height: calc(100% - 3.5rem);
  border-top: 3px solid transparentize($black, 0.95);
  overflow: hidden;
}

.resume-tab__body__scroll {
  padding-top: 3.5rem;
  padding-inline: 2rem;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.resume-tab__body__bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  object-fit: cover;
}
