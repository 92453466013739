@use 'scss/utils/_vars.scss' as *;

.reoffer-checkbox {
    padding-top: 2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.reoffer-checkbox input[type="checkbox"] {
    width: 1.3rem;
    height: 1.3rem;
    accent-color: $input-default-color;
    cursor: pointer;
}

.reoffer-checkbox input[type="checkbox"] + label {
    font-size: 0.85rem;
    font-weight: bold;
    cursor: pointer;
}
