@use "scss/utils/_vars.scss" as *;

.quote-state-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 1rem;
}

.quote-state-form > button {
    background: none;
    align-self: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 2.5rem;
    border: 1px solid $menu-actions;
    border-radius: 4px;
    color: $menu-actions;
    cursor: pointer;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
        background: $menu-actions;
        color: $white;
    }

    &:disabled {
        border-color: $disabled;
        background: $disabled;
        color: $white;
        cursor: not-allowed;
    }
}

.quote-state-form__field {
    display: flex;
    flex-direction: column;
    color: $main-color;

    &:first-child {
        margin-top: 0.5rem;
    }

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}

.quote-state-form__field .input-field-wrapper {
    width: 100%;
}

.quote-state-form__field .react-select {
    border: 1px solid transparent;
    border-radius: 4px;
}

.quote-state-form__field .input-field__errors {
    order: 1;
    color: $error;
}

.quote-state-form__field .input-field__errors + .react-select {
    border-color: $error;
}

.quote-state-form__field .show-field__value {
    font-weight: bold;
}

.quote-state-form__field textarea {
    border: none;
    border-radius: 4px;
    width: 100%;
    min-height: 5rem;
    max-height: 12rem;
    resize: vertical;
    background: $input-bg;
    color: $input-border-bottom;
    padding: 0.5rem;

    &:focus {
        outline: transparent;
    }

    &::placeholder {
        color: transparentize($input-border-bottom, 0.4);
    }
}
