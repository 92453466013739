.automated-status__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.automated-status__form .input-field-wrapper {
    width: 100%;
}

.table-admin th.actions {
    width: 0;
    text-align: center;
}

.automated-status__actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.automated-status__actions .btn.btn {
    margin: unset;
    padding: 0.5rem;
    line-height: 1;
    display: grid;
    place-items: center;
    min-width: 2rem;
    aspect-ratio: 1;
}

.automated-status__actions .btn i {
    padding: unset;
    margin: unset;
}
