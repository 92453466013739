.admin {
    position: relative;
    min-height: 100vh;
    display: grid;
    grid-template-areas:
            "header header"
            "sidebar wrapper";
    grid-template-columns: 250px 1fr;
    grid-template-rows: auto 1fr;
    transition: grid-template-columns .3s ease-in-out;

    &__header {
        grid-area: header;
        position: relative;
        max-height: 100px;
        z-index: 1030;
        background: $lte-header;

        @include respond-to('mobile') {
            display: flex;
            flex-direction: column;
        }

        &__logo {
            transition: width .3s ease-in-out;
            display: block;
            float: left;
            height: 50px;
            font-size: 1.5em;
            line-height: 50px;
            text-align: center;
            width: 250px;
            padding: 0 15px;
            font-weight: 300;
            overflow: hidden;
            color: $white;
            background: $lte-logo;
            border-bottom: 0 solid transparent;

            @include respond-to('mobile') {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &:hover {
                color: $white;
            }
        }

        &__nav {
            transition: margin-left .3s ease-in-out, max-width .3s ease-in-out;
            margin-bottom: 0;
            margin-left: 250px;
            max-width: calc(100% - 250px);
            border: 0;
            min-height: 50px;
            border-radius: 0;
            z-index: 1000;
            position: relative;
            padding-right: 30px;

            @include respond-to('mobile') {
                margin-left: 0;
                max-width: none;
            }

            .nav {
                list-style: none;

                &__item {
                    color: $white;
                    display: inline-block;
                    margin-left: 25px;
                    font-size: 1.6em;
                }
            }
        }

        &__toggle {
            color: $white;
            font-size: 1.2em;
            padding: 0 15px;
            cursor: pointer;
        }
    }

    &__sidebar {
        grid-area: sidebar;
        min-height: 100%;
        height: 0;
        overflow-y: auto;
        z-index: 810;
        transition: transform .3s ease-in-out, width .3s ease-in-out;
        background-color: $lte-sidebar;
        color: $white;

        @include respond-to('mobile') {
            padding-top: 100px;
        }

        &-logo {
            background-color: $white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;
            max-height: 350px;

            img {
                max-width: 100%;
            }
        }

        .user {
            white-space: nowrap;
            overflow: hidden;
            padding: 10px;
            position: relative;

            &:before,
            &:after {
                content: '';
                display: table;
            }

            &:after {
                clear: both;
            }

            &__image {
                float: left;

                &__img {
                    border-radius: 50%;
                    transition: width .3s, height .3s;
                }
            }

            &__info {
                padding: 5px 5px 5px 15px;
                line-height: 1;
                position: absolute;
                left: 55px;

                &__etat {
                    margin-top: 8px;
                    text-decoration: none;
                    padding-right: 5px;
                    font-size: 11px;

                    &:before {
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background: $lte-connect;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                }
            }
        }

        .title {
            color: $lte-title-color;
            background: $lte-title-bg;
            padding: 10px;
            font-size: 12px;
            overflow: hidden;
            text-overflow: clip;
            white-space: nowrap;
        }

        .menu {
            &__item {
                &__customer {
                    background-color: $lte-submenu;

                    &__link {
                        padding: 10px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        color: $lte-link;
                        cursor: default;

                        .policon {
                            font-size: 1.4em;
                            margin-right: 10px;
                        }
                    }
                }

                &__link {
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    color: $lte-link;
                    cursor: pointer;

                    .policon {
                        font-size: 1.4em;
                        margin-right: 10px;
                    }

                    &.collapsed,
                    &:hover {
                        color: $white;
                    }
                }

                &__select {
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    position: relative;
                    align-items: center;
                    color: $lte-link;
                    cursor: pointer;
                    background-color: $lte-submenu;

                    > div {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }

                    &:hover {
                        color: $white;
                    }

                    .submenu {
                        display: none;
                        z-index: 1;
                        position: absolute;
                        width: 100%;
                        background-color: $white;
                        top: 100%;
                        left: 0;
                        user-select: none;
                        max-height: 200px;
                        overflow-x: auto;

                        li {
                            padding: 5px;
                            padding-left: 10px;
                            border-bottom: 1px solid $lte-submenu-color;
                            color: $lte-submenu-color;
                            transition: .20s ease-in-out;

                            &:hover {
                                background-color: $lte-submenu-color;
                                color: $white;
                            }
                        }
                    }

                    .isOpen {
                        display: block;
                    }

                    .policon {
                        font-size: .8em;
                        margin-right: 20px;
                        transition: .25s ease-in-out;

                        &--reverse {
                            transform: rotate(-180deg);
                        }
                    }
                }

                .submenu {
                    background: $lte-submenu;
                    font-size: .9em;
                    display: none;
                    &__item {
                        padding: 10px;
                        color: $lte-submenu-color;

                        &:hover {
                            color: $white;
                        }

                        &__picto {
                            margin-right: 10px;
                        }
                    }

                    &__active{
                        background: $lte-active-link;
                        color: $white;
                    }
                }
            }

            .disabled {
                pointer-events: none;
                color: $disabled;
                opacity: .5;
            }

            .collapsed {
                .submenu {
                    display: block;
                }
            }
        }
    }

    &__wrapper {
        grid-area: wrapper;
        padding: 30px 15px;

        .page {
            &:not(.page-admin) {
                display: none;
            }
        }
    }

    &__title {
        text-align: left;
        padding-left: 15px;
        font-size: 1.5em;
        margin-bottom: 20px;
        font-weight: 400;
        text-transform: uppercase;
    }

    &.is-sticky {
        grid-template-columns: 50px 1fr;

        .admin {
            &__header {
                &__logo {
                    position: relative;
                    width: 50px;
                    font-size: 1em;
                    @include respond-to('mobile') {
                        width: 100%;
                        font-size: 1.5em;
                        height: 50px;
                    }
                }

                &__logo {
                    visibility: hidden;

                    @include respond-to('mobile') {
                        visibility: visible;
                    }

                    &:after {
                        font-size: 1.5em;
                        content: 'POL';
                        visibility: visible;
                        display: block;
                        position: absolute;
                        background-color: inherit;
                        top: 0;
                        left: 0;
                        width: 100%;

                        @include respond-to('mobile') {
                            visibility: hidden;
                        }
                    }
                }

                &__nav {
                    margin-left: 50px;
                    max-width: calc(100% - 50px);

                    @include respond-to('mobile') {
                        margin-left: 0;
                        max-width: none;
                    }
                }
            }

            &__sidebar {

                &-logo {
                    display: none;
                }

                .react-select {
                    &__indicators {
                        display: none;
                    }

                    &__value-container {
                        flex-direction: column;
                        max-width: 100%;
                    }
                }
            }
        }

        .menu {
            &__item {
                position: relative;

                &__link {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .policon {
                        margin-right: 0;
                    }

                    &:hover {
                        background: $lte-submenu-sticky;
                    }
                }

                &__select {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .policon {
                        margin-right: 0;
                    }

                    span {
                        display: none;
                    }

                    .submenu {
                        position: absolute;
                        left: 50px;
                        top: 0;
                        width: 200px;
                    }
                }

                &__text {
                    display: none;
                }

                &__picto {
                    margin: auto;
                }
            }
        }

        .submenu {
            position: absolute;
            left: 50px;
            top: 0;
            list-style: none;
            white-space: nowrap;
            background: $lte-submenu-sticky;
        }

        .title {
            display: none;
        }

        .user {
            &__image {
                position: relative;
                left: -3px;
                &__img {
                    width: 30px;
                    height: 30px;
                    display: block;
                    margin: auto;
                }
            }

            [data-initials]:before {
                width: 35px;
                height: 35px;
                font-size: 1.2em;
            }
        }

        .collapsed {
            background: $lte-submenu-sticky;

            .submenu {
                display: none;
            }

            &:hover {
                .submenu {
                    display: block;
                }
            }
        }
    }

    main.box {
        height: 100%;
    }

    .box {
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 3px;
        background: $white;
        border-top: 3px solid $lte-link;
        margin-bottom: 20px;
        width: 100%;
        box-shadow: 0 1px 5px $box-shadow-column;

        &__header {
            color: $lte-box-header;
            display: block;
            padding: 10px;
            margin-bottom: 10px;
            position: relative;
            border-bottom: 1px solid $lte-border-header;

            &:after {
                content: "";
                display: block;
                clear: both;
            }

            &__title {
                display: inline-block;
                margin: 0;
                font-size: rem-size(18px);
            }

            &__tools {
                float: right;
                margin-left: 20px;
            }

            &__text {
                display: inline-block;
                font-size: 1.2em;
                margin: 0;
            }

            &__agenda {
                float: right;
                font-size: 1.5em;
                margin-right: 5px;
            }
        }

        &__body {
            padding: 10px;
            flex: 1;
        }
    }

    [data-initials]:before {
        background: $lte-initials;
        color: $black;
        content: attr(data-initials);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 0.5em;
        font-size: 2em;
        width: 50px;
        height: 50px;
    }
}
