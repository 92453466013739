@use 'scss/utils/_vars.scss' as *;

.quotes-products-table-attributes-popup {
    position: fixed;
    inset: 0;
    background: hsla(0, 0%, 0%, 0.5);
    z-index: 9;
    display: grid;
    place-items: center;
}

.quotes-products-table-attributes-popup__content {
    max-height: 85%;
    overflow: auto;
    background: $white;
    border-radius: 6px;
    min-width: 300px;
    max-width: 80%;
}

.quotes-products-table-attributes-popup__header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    background: $input-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    color: $main-color;
}

.quotes-products-table-attributes-popup__header i {
    cursor: pointer;
    font-size: 1.3rem;
}

.quotes-products-table-attributes-popup__body {
    padding: 1rem;
}

.quotes-products-table-attributes-popup__attributes {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.quotes-products-table-attributes-popup__attribute {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 0.5rem;
}

.is-show .show-field__value {
    font-weight: bold;
    color: $main-color;
}

.quotes-products-table-attributes-popup__attribute-label {
    font-size: 0.8rem;
    color: $main-color;
    font-weight: 500;
}

.quotes-products-table-attributes-popup__attributes .input-field {
    border: none;
    border-radius: 3px;
    padding: 0.5rem;
    font-size: 0.9rem;
    color: $input-color;
    background: $input-bg;
    width: 100%;
}

.quotes-products-table-attributes-popup__attributes .input-field:focus {
    outline-color: transparent;
}

.quotes-products-table-attributes-popup__attributes .input-field::placeholder {
    color: transparentize($main-color, 0.4);
}

:where(
.quotes-products-table-attributes-popup__no-attribute,
 .quotes-products-table-attributes-popup__loading
 ) {
    text-align: center;
    color: $main-color;
    font-size: 0.9rem;
}

.is-show .quotes-products-table-attributes-popup__group:nth-child(even) {
    box-shadow: 0 0 0 8px $input-bg;
    background: $input-bg;
}

.quotes-products-table-attributes-popup__group-title {
    font-size: 1rem;
    font-weight: 500;
    color: $main-color;
    margin-bottom: 0.5rem;
}

.quotes-products-table-attributes-popup__group-attributes {
    display: flex;
    gap: 1rem;
}

.quotes-products-table-attributes-popup__group-attributes__field {
    flex: auto;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    line-height: 1;
    color: $main-color;
}

.quotes-products-table-attributes-popup__group-attributes__field input[type='checkbox'] {
    width: 1.4rem;
    height: 1.4rem;
    accent-color: $input-default-color;
    cursor: pointer;
}

.quotes-products-table-attributes-popup__footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
    border-top: 2px solid $input-bg;
}

.quotes-products-table-attributes-popup__footer > button {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: none;
    font-size: 0.9rem;
    font-weight: 500;
    color: $white;
    background: $input-color;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
        background: darken($input-color, 5%);
    }
}
