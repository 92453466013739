@use "v4/features/front/listingViewOrdersManagement/components/ViewOrdersList/ViewOrdersList" as *;

.preselected-export {
    padding: 1rem 0.5rem 0.5rem;
}

.preselected-export__btns {
    height: 28px;
    display: flex;
    justify-content: flex-end;
}

.preselected-export__btn {
    @extend .view-orders-list__btn-validate;
}

.preselected-export.selected-export ~ * {
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
}
