.quotes-products__prices-infos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    row-gap: 0.25rem;
    column-gap: 0.25rem;
    text-align: right;
    background: #F5F3FC;
    color: #8070C7;
    width: 28%;
    min-width: min-content;
    padding: 0.5rem;
    margin-block: 2rem 1rem;
    font-size: 0.8rem;
    white-space: nowrap;
}

.quotes-products__prices-infos.is-show {
    margin-right: 0.5rem;
}

.quotes-products__prices-infos p {
    line-height: 1.3;
}

.quotes-products__prices-infos hr {
    grid-column: span 2;
    border: none;
    background: transparentize(#D2E5FA, 0.4);
    height: 1px;
    margin-block: 0.65rem;
}

.quotes-products__prices-infos .small {
    font-size: 0.65rem;
}

.quotes-products__prices-infos .alt-text {
    color: #7e7999;
}

.quotes-products__prices-infos p:last-child {
    font-weight: bold;
}
