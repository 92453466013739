.datatable tbody {
    position: relative;
    isolation: isolate;
}

.ui-table-wrapper {
    position: relative;
    height: 100%;
}

.listing-table-tbody__loader {
    text-align: center !important;
    position: absolute;
    z-index: 3;
    inset: 0;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.listing-table-tbody__loader > div {
    width: 12rem;
    margin-inline: auto;
}

.listing-table-tbody__loader > div svg {
    overflow: visible;
}

.datatable .empty td:not(.listing-table-tbody__loader):first-of-type,
.datatable td#table-error {
    text-align: center;
    padding-bottom: 15vw;
    cursor: unset;
}

.datatable .empty {
    height: 15rem;
}
