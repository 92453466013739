@use "scss/utils/_vars.scss" as *;

.extended-filters__wrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    list-style: none;
    min-height: 3.5rem;
}

.extended-filters__tab {
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    color: $disabled-font-color;
    border-radius: 6px 6px 0 0;
    border: 1px solid $table-header;
    border-bottom: none;
    cursor: pointer;
    font-weight: 500;
    background: #eee;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out, padding-bottom 0.2s ease-in-out;
}
.extended-filters__tab:is(.active, :hover) {
    background: $white;
    //color: $white;
    border: 1px solid $table-header;
    border-bottom: none;
    padding-bottom: 0.8rem;
}
