.quotes-generate-pdf {
    .form-control {
        background: #F2EFFC;
        border: none;
        padding: 0.5rem;
        border-radius: 4px;
        color: #9B91D1;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: #9B91D1;
            font-weight: 400;
        }

        &-checkbox {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            label {
                margin: 0 0.5rem;
            }
        }
    }

    &__body {
        &__buttons {
            text-align: right;
            margin-top: 20px;

            button {
                font-weight: bold;
                cursor: pointer;
                padding: 0.5rem 2rem;
                transition: background-color 0.2s ease-in-out;

                + button {
                    margin-left: 10px;
                }

                &[disabled] {
                    opacity: 0.5;
                }
            }

            &-return {
                border: 1px solid #2F56B7;
                background: transparent;

                &:hover {
                    background: transparentize(#9B91D1, 0.85);
                }
            }

            &-validate {
                border: 1px solid #2F56B7;
                background: #2F56B7;
                color: #fff;

                &:hover {
                    background: darken(#2F56B7, 5%);
                }
            }
        }
    }

    &__loader {
        position: absolute;
        z-index: 10;
        inset: 0;
        background-color: rgba(255, 255, 255, 0.5);

        .is-loading {
            height: 100%;
        }

        .loader {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        svg {
            max-height: 50%;
            display: block;
            margin: auto;
        }
    }
}
