.campaign-popup__container {
    position: fixed;
    inset: 0;
    background: transparentize(#000, 0.16);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.campaign-popup {
    background: #fff;
    min-width: 40%;
}

.campaign-popup__header {
    position: relative;
    text-align: center;
    background: #CCC4F3;
    color: #fff;
    font-weight: bold;
    padding: 0.75rem;
    font-size: 0.9rem;
}

.campaign-popup__close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    cursor: pointer;
    font-size: 1.25rem;
}

.campaign-popup__body {
    padding: 1rem;
    position: relative;
    font-size: 0.75rem;
}

.campaign-popup__body button {
    background: none;
    font-weight: bold;
    cursor: pointer;
    padding: 0.5rem 2rem;
    transition: background-color 0.2s ease-in-out;
    border: 1px solid #2F56B7;
    background: #2F56B7;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    margin-top: 1rem;

    i {
        margin-left: 0.5rem;
        animation: spin 1s linear infinite;
    }

    &:hover {
        background: none;
        color: #2F56B7;
    }
}

.campaign-popup__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.campaign-popup__buttons button {
    margin-inline: unset;
    margin-top: unset;
}

.campaign-popup__buttons button:first-child {
    background: none;
    color: #2F56B7;

    &:hover {
        background: #2F56B7;
        color: #fff;
    }
}

.campaign-popup__choice {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.campaign-popup__choice button {
    padding-block: 2rem;
    width: 100%;
    background: #8577c7;
    border: unset;
    font-size: 1rem;
    margin-top: unset;

    &:hover {
        background: darken(#8577c7, 5%);
        color: #fff;
    }
}

.campaign-popup__loader {
    width: 50%;
    margin-inline: auto;
    text-align: center;
}
