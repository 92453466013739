$nodeMarkSize: rem-size(4px);

.treeNodes {
  margin-bottom: 20px;

  ul {
    list-style: none;
  }

  &__Node {
    padding: 10px 20px;

    &__Label {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-left: solid $nodeMarkSize rgba($lte-submenu, .4);
      background-color: rgba($lte-link, .4);
      line-height: 40px;
      @include transition(border-color .2s, background-color .2s);

      &--name {
        flex: 1;

        input {
          width: 100%;
        }
      }

      strong {
        margin: 0 5px;
        font-weight: normal;
      }

      &:hover {
        border-color: $lte-link;
        background-color: rgba($lte-submenu, .8);
        color: #fff;

        .treeNodes__Node {
          &__Tools,
          &__Handle {
            opacity: 1;
            color: #fff;
          }
        }
      }
    }

    &__Tools,
    &__Handle {
      color: $lte-link;
      opacity: 1;
      @include transition(opacity .2s);
      font-size: rem-size(14px);
    }

    &__Handle,
    &__Tools .action {
      display: inline-block;
      padding: 0 10px;

      &:hover {
        background-color: rgba($lte-link, 0.4);
      }
    }
  }

  &__Droppable {
    border: solid 1px rgba($lte-link, .4);
  }

  &--droppable {
    background-color: rgba($lte-active-link, 0.2);
  }

  &--dragging {
    border: 0;

    .treeNodes__Droppable {
      visibility: hidden;
    }
  }
}