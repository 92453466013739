.last-visits {
    color: $main-color;
    text-align: left;
    position: fixed;
    right: -100vw;
    top: 0;
    bottom: 0;
    width: 80vw;
    background: $bg;
    padding: 30px 15px;
    z-index: 6;
    transition: right .5s ease-in-out;

    &__title {
        text-align: center;
        background: $active-class;
        color: $white;
        margin: 0 0 20px;
        border-radius: 4px;
        padding: 6px 15px;
        text-transform: uppercase;
        font-size: 0.8em;
    }

    &__list {
        list-style: none;

        &__item {
            padding: 0 15px;
            display: inline-block;
            position: relative;
            white-space: nowrap;
            line-height: 40px;
            font-size: 0.8em;
            border-bottom: 1px solid $secondary-color;
            color: $last-views;

            @include respond-to('mobile') {
                &__loader {
                    display: block;
                }
            }

            &__name {
                margin-left: 10px;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    &-open {
        right: 0;
        .last-visits {
            &__list {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@include respond-to('tablet') {
    .last-visits {
        display: block;
        padding: 0;
        width: auto;
        position: initial;
        font-size: 1.1em;
        margin-top: 10px;
        border-radius: 4px;
        background: $white;

        &__title {
            border-right: 1px solid $main-color;
            padding: 5px 15px;
            max-width: 150px;
            text-align: center;
            line-height: 15px;
            height: 50px;
            background: none;
            margin: 0;
            text-transform: none;
            border-radius: 0;
            color: inherit;
            font-size: 0.8em;
        }

        &__list {
            max-height: 40px;
            overflow: hidden;

            &__item {
                border-right: 1px solid $secondary-color;
                border-bottom: 0;
                font-size: 0.8em;
            }
        }
    }
}