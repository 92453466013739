.page-details {
    position: relative;
    z-index: 2;
    order: 2;
    $page-details: &;

    .spin {
        animation-name: spin;
        animation-duration: 3000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }

    .badge {
        > i {
            margin-right: 2px;
            padding: 1px;
        }

        float: right;
        margin-left: 10px;
        border-radius: 5px;
        height: 18px;
        color: $white;
        text-align: center;
        font-weight: 300;
        font-size: 0.7em;
        margin-top: 2px;
        padding: 2px;
        min-width: 35px;

        &__success {
            background-color: $menu-actions;
        }

        &__error {
            background-color: $error;
        }
    }

    &__address {
        background: $table-row;
        color: $main-color;
        padding: 15px;

        &:nth-child(odd) {
            background: $white;
        }

        &__title {
            font-weight: 700;
            margin-bottom: 10px;
        }
    }

    .data__info__phone {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: $input-default-color;
        }
    }

    &__content {
        width: 100%;
        background: $white;
        z-index: 3;
        border-radius: 4px;
        position: relative;

        &:before,
        &:after {
            content: '';
            display: table;
        }

        &:after {
            clear: both;
        }

        &:not(.expanded) {
            #{$page-details} {
                &__prospect {
                    background-color: $list-prospect-background;

                    &__close {
                        &__mobile {
                            transform: rotate(-180deg);
                        }
                    }

                    .prospect__list {
                        &__item {
                            flex: 0 0 100%;
                            max-width: 100%;

                            &:not(:nth-child(-n+8)) {
                                display: none;
                            }
                        }
                    }

                    .data {
                        &__info,
                        &__group {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }

                        &__group {
                            &:not(:first-child) {
                                display: none;
                            }
                        }

                        &__info {
                            &:nth-child(n+12) {
                                margin-bottom: 0;
                            }

                            &:nth-child(n+13) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &__close {
        position: absolute;
        right: 3px;
        top: 0;
        height: 40px;
        width: 40px;
        font-size: 1.1em;
        color: $white;
        cursor: pointer;
        z-index: 6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 30px;
            border-radius: 5px;
            border: solid 1px $white;
            transition: .25s ease-in-out;

            &:hover {
                background-color: $close;
                border-color: $close;
            }
        }
    }

    &__scroll {
        width: 200%;
        display: flex;
        flex-direction: row;
        height: 100%;

        &:before,
        &:after {
            content: '';
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &__container {
        position: relative;
        overflow: hidden;

        .table-container {
            float: none;
            flex: 1;
            flex-basis: 50%;
            margin-top: 0;
            max-width: 50%;

            .table-content {
                margin-top: 0;
                width: 100%;
            }

            .result_header_sub_datatable {
                text-align: right;
            }
        }

        &-infos {
            .page-details__scroll {
                margin-left: -100%;
            }
        }

        &-modify {
            .data__content {
                margin-left: -100%;
            }
        }
    }

    &__tab-content {
        padding: 15px;
    }

    &__main {
        width: 100%;
        padding: 15px 0;
        float: left;
        position: relative;

        &__nav {
            list-style: none;
            margin-bottom: -1px;
            position: relative;
            z-index: 1;
            padding: 0;
            display: flex;
            height: 40px;
            overflow-y: hidden;
            background: $main-color;

            .item {
                background: $nav-actions;
                color: $white;
                text-transform: uppercase;
                white-space: nowrap;
                cursor: pointer;
                text-align: center;
                width: auto;
                transition: width .5s linear;
                border-left: 1px solid $menu-border;
                min-width: 40px;
                display: flex;
                align-items: center;

                &-active {
                    @include menu-color;
                    background: $nav-actions-active;
                }

                &__hover {
                    display: flex;
                    align-items: center;
                    padding: 10px 11px 9px;
                }

                &__icon {
                    font-size: 1.4em;
                    display: inline-block;
                }

                &__text {
                    display: none;
                    margin-left: 10px;
                }
            }
        }
    }

    .data {
        &__container {
            width: 100%
        }

        &__stats {
            &-header {
                display: flex;
                flex-direction: row;
                margin-top: 30px;
            }

            &-svg {
                margin-top: 95px;
                display: flex;
                justify-content: flex-end;
            }

            &-img {
                width: 100%;
                max-width: 500px;
            }
        }

        &__info {
            padding: 0;
            font-weight: 700;
            color: $main-color;
            background: $white;
            text-align: left;
            border-radius: 5px;
            margin-bottom: 7px;

            &:last-child {
                margin-bottom: 0;
            }

            &__timestamp {
                opacity: 0.5;
            }

            &__transparent {
                background: none;
            }

            &__text {
                text-overflow: ellipsis;
                padding: 8px 15px;
                overflow: hidden;
                white-space: nowrap;
                border-radius: 5px;
                width: 100%;

                &.quote-lines {
                    padding: unset;
                    .data__info__label {
                        margin-left: 15px;
                    }
                }

                &--details {
                    white-space: unset;
                }

                &__area {
                    margin-top: 10px;
                    overflow-y: scroll;
                    white-space: initial;
                    display: block;
                    max-height: 300px;
                }
            }

            &__sub-text {
                text-overflow: ellipsis;
                overflow-x: auto;
                white-space: nowrap;
                border-radius: 5px;
                padding: 5px;
                width: 100%;
                text-align: right;
            }

            &__label {
                font-weight: 400;
                padding-right: 10px;

                &--time {
                    margin-left: 5px;
                    font-weight: 300;
                }
            }

            &__label-info {
                font-weight: 400;
                padding-right: 5px;
            }

            &.odd {
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
            }

            &.even {
                background: $lte-border-header;
            }

            &.field-left {
                text-align: left;

                .data__info {
                    &__label {
                        float: none;
                        margin-right: 15px;
                    }
                }
            }

            &--large {
                .data__info__text {
                    height: 100px;
                }
            }
        }

        &__title {
            color: $main-color;
            font-weight: 700;
            font-size: 1.15em;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            margin-bottom: 12px;

            &__content {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &__btn {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                height: 30px;
                width: 170px;
                font-size: 0.9em;
                margin-bottom: 10px;
                margin-top: 20px;
                border-radius: 5px;
                border: solid 1px $main-color;
                color: $main-color;
                background-color: $white;
                transition: .20s ease-in-out;
                font-weight: normal;

                &:disabled {
                    background-color: $disabled;
                    color: $disabled-font-color;
                    border-color: $disabled;
                    cursor: not-allowed;
                    opacity: .9;
                }

                &__icon {
                    font-size: 1.3em;
                }

                &:hover {
                    background-color: $main-color;
                    color: $white;
                }
            }

            &__actions {
                cursor: pointer;
                background-color: $main-color;
                color: $white;
                font-weight: 400;
                font-size: 1em;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
                border-radius: 5px;
                width: 185px;
                height: 40px;

                &--disable {
                    background-color: $disabled;
                    color: $disabled-font-color;
                    cursor: not-allowed;
                }

                @include respond-to('mobile', false) {
                    height: 35px;
                    width: 165px;
                    font-size: .9em;
                }
            }

            &__mail {
                position: relative;
                margin-right: 10px;
                cursor: pointer;
                background-color: $main-color;
                color: $white;
                font-weight: 400;
                font-size: .88em;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
                border-radius: 5px;
                width: 60px;
                height: 40px;

                @include respond-to('mobile', false) {
                    width: 45px;
                    height: 35px;
                }

                &__disabled {
                    background-color: $page404-color4;
                }
            }

            &__icon {
                border-radius: 50%;
                background-color: $white;
                color: $main-color;
                font-size: 1em;
                width: 20px;
                height: 20px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                &--disable {
                    background-color: $disabled;
                    color: $disabled-font-color;
                    cursor: not-allowed;
                }
            }
        }

        &__content {
            position: relative;
            flex: 1;
            width: 200%;
            height: 100%;
            margin-top: -1px;

            &:before,
            &:after {
                content: '';
                display: table;
            }

            &:after {
                clear: both;
            }

            &__info {
                width: 50%;
                float: left;

                .data__prospect__container {
                    background-color: $white;
                }

                .even {
                    background-color: $table-row;
                }
            }
        }

        &__new {
            flex: 1;
            max-width: 50%;
            flex-basis: 50%;

            .form {
                height: 100%;
            }
        }

        &__modify {
            width: 50%;
            float: left;
            height: 100%;

            .form {
                height: 100%;
                margin: 0 -15px;
            }
        }

        &-details {
            flex-direction: column;
            max-width: 50%;
            flex: 1;
            flex-basis: 50%;
            z-index: 2;
            position: relative;
            top: -54px;

            .data__title {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                position: relative;

                &__container-button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    margin-top: 2px;
                }

                &__edit {
                    padding: 7px;
                    text-align: right;
                    margin: 15px 0;

                    &__btn-exported {
                        > span {
                            margin-right: 10px;
                            font-size: 1.3em;
                        }

                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: center;
                        padding: 10px;
                        height: 40px;
                        width: 120px;
                        font-size: .95em;
                        border-radius: 5px;
                        border: solid 1px $menu-actions;
                        color: $menu-actions;
                        background-color: $white;
                        transition: .20s ease-in-out;
                        font-weight: normal;

                        &:hover {
                            background-color: $main-color;
                            color: $white;
                        }
                    }

                    &__btn {
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: center;
                        height: 40px;
                        width: 90px;
                        font-size: .95em;
                        border-radius: 5px;
                        border: solid 1px $main-color;
                        color: $main-color;
                        background-color: $white;
                        transition: .20s ease-in-out;
                        font-weight: normal;

                        &:disabled {
                            background-color: $disabled;
                            color: $disabled-font-color;
                            border-color: $disabled;
                            cursor: not-allowed;
                            opacity: .9;
                        }

                        &__large {
                            width: 130px;
                            font-size: 1.15em;
                            margin-bottom: 10px;
                        }

                        &__icon {
                            margin-top: 3px;
                            font-size: 1.1em;

                            &__large {
                                font-size: 1.2em;
                            }
                        }

                        &:hover {
                            background-color: $main-color;
                            color: $white;
                        }
                    }
                }

                &__return {
                    margin-right: 5px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    cursor: pointer;
                    font-size: .88em;
                    width: 90px;
                    height: 25px;
                    border-radius: 5px;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
                    color: $main-color;
                    transition: .15s ease-in-out;
                    font-weight: normal;

                    @include respond-to('tablet') {
                        flex: none;
                        max-width: none;
                    }

                    &:hover {
                        color: $white;
                        background-color: $main-color;
                    }

                    &__icon {
                        font-size: .88em;
                        margin-right: 5px;
                    }
                }

                &--left {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    font-weight: 700;
                    font-size: 1.15em;
                    color: $main-color;
                    margin-bottom: 15px;
                    height: 30px;
                    margin-left: -15px;
                    margin-bottom: 12px;
                }
            }
        }

        &-exit {

            .data__modify {
                &-exit {
                    animation: none;
                }
            }
        }

        .data-part {
            margin-bottom: 20px;
        }

        .field-address {
            margin-top: 20px;

            .infos-loader {
                &:before {
                    bottom: 2px;
                    top: 2px;
                }
            }

            .data {
                &__title {
                    font-weight: 700;
                    background: $table-row;
                    display: block;
                    border: 1px solid $white;
                    border-bottom: 0;
                    border-top: 0;
                    font-size: 1em;
                    padding: 5px 10px;
                    margin: 0 -15px;
                    color: $main-color;
                    text-transform: initial;
                }

                &__info {
                    padding: 0;
                    background: $table-row;
                    border-bottom: 0;
                    border-top: 0;

                    &__label {
                        display: none;
                    }

                    &__text {
                        font-weight: 400;
                        margin: 0;
                        text-align: left;
                        padding: 2px 10px;
                        width: 100%;
                    }
                }
            }
        }

        &__prospect {
            &__container {
                box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.10);
                background-color: #eaecfa;
                border-radius: 5px;
            }
        }
    }

    .data__prospect__row {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    &__prospect {
        width: 100%;
        float: left;
        position: relative;
        z-index: 5;
        background-color: $list-prospect-background;
        box-shadow: 0 -1px 5px $box-shadow;

        .is-loading {
            max-width: 15%;
            margin-inline: auto;
            text-align: center;
            margin-bottom: 2.5rem;
        }

        &__close {
            border-radius: 3px;
            background: $white;
            font-size: .95em;
            color: $active-class;
            cursor: pointer;
            z-index: 3;
            margin-right: 15px;
            width: auto;
            padding: 0 5px 0 5px;
            height: 25px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
            transition: .20s ease-in-out;

            &--less {
                border-radius: 3px;
                background: $white;
                font-size: .85em;
                color: $active-class;
                cursor: pointer;
                z-index: 3;
                margin-right: 15px;
                width: auto;
                padding: 0 5px 0 5px;
                height: 25px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
                transition: .20s ease-in-out;

                &:hover {
                    background-color: $active-class;
                    color: $white;
                }
            }

            &:hover {
                background-color: $active-class;
                color: $white;
            }

            &__icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-left: 2px;
            }

            &__mobile {
                transition: transform .5s ease-in-out;
                display: block;
            }
        }

        &__title {
            background: $header-content;
            padding: 10px 15px;
            overflow: hidden;
            color: $white;
            line-height: 20px;
            height: 40px;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: row;
            justify-content: center;

            &__picto {
                font-size: 1.4em;
            }
        }

        &__content {
            list-style: none;
            color: $main-color;

            .data__title--left {
                margin-top: 15px;
                margin-bottom: 10px;
                font-size: 1.150em;
                font-weight: 500;
                color: $main-color;
                height: 30px;
                white-space: nowrap;
            }
        }

        &__name {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.1em;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            p {
                text-overflow: ellipsis;
                overflow: hidden;
                margin-left: 10px;
            }
        }

        &__pictos {
            text-align: right;
            padding: 0;

            .item {
                display: inline-block;
                margin-left: 15px;
                cursor: pointer;

                &.disable {
                    opacity: .5;
                    cursor: not-allowed;
                }

                &__icon {
                    font-size: 1.2em;
                }
            }
        }

        .form {
            padding: 15px 15px 0;

            &__edit {
                &--view {
                    margin-left: 40px;
                    margin-bottom: 40px;
                    margin-top: 10px;
                    border-radius: 5px;
                    background-color: $white;

                    @include respond-to('mobile') {
                        margin-left: 15px;
                        margin-right: 15px;
                    }

                    @include respond-to('tablet') {
                        margin-left: 15px;
                        margin-right: 15px;
                    }

                    @include respond-to('desktop') {
                        margin-right: 15px;
                        margin-left: 40px;
                    }
                }
            }

            & + .page-details__edit {
                display: none;
            }
        }
    }

    &__edit {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        margin-top: 9px;
        z-index: 4;

        @include respond-to('mobile') {
            justify-content: flex-end;
        }

        @include respond-to('tablet', false) {
            justify-content: flex-end;
        }

        &__dropDown {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 25px;
            border-radius: 3px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
            margin-left: 15px;
            background-color: $white;
            color: $active-class;
            font-size: 1.2em;
            cursor: pointer;
            transition: .20s ease-in-out;
            position: relative;

            @include respond-to('mobile', false) {
                width: 100px;
                position: relative;
                visibility: hidden;

                .policon-actions {
                    display: none;
                }
            }

            @include respond-to('tablet', false) {
                width: 100px;
                position: relative;
                visibility: hidden;

                .policon-actions {
                    display: none;
                }
            }

            &__content {
                display: none;
                position: absolute;
                top: 100%;
                height: auto;

                @include respond-to('mobile') {
                    visibility: visible;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    top: 0;
                }

                @include respond-to('tablet', false) {
                    visibility: visible;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    top: 0;
                }

                &--right {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    font-size: 1.2em;

                    .disable {
                        opacity: 0.5;
                    }

                    &__item {
                        width: 28px;
                        height: 25px;
                        border-radius: 3px;
                        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
                        background-color: $white;
                        color: $active-class;
                        cursor: pointer;
                        transition: .20s ease-in-out;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        transition: .20s ease-in-out;
                        margin-right: 5px;

                        &__icon {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }

                        &:hover {
                            background-color: $active-class;
                            color: $white;
                        }
                    }
                }

                .disable {
                    opacity: 0.5;
                }

                &__item {

                    width: 28px;
                    height: 25px;
                    border-radius: 3px;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
                    background-color: $white;
                    color: $active-class;
                    cursor: pointer;
                    transition: .20s ease-in-out;
                    margin-top: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    transition: .20s ease-in-out;

                    @include respond-to('mobile') {
                        margin-top: 0;
                        margin-right: 5px;
                    }

                    @include respond-to('tablet', false) {
                        margin-top: 0;
                        margin-right: 5px;
                    }

                    &__icon {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    &:hover {
                        background-color: $active-class;
                        color: $white;
                    }
                }

                &:hover {
                    display: block;
                }

                &__item {
                    list-style: none;
                }
            }

            &:hover {
                background-color: $active-class;
                color: $white;

                .page-details__edit__dropDown__content {
                    display: block !important;

                    @include respond-to('mobile') {
                        display: flex !important;
                    }

                    @include respond-to('tablet', false) {
                        display: flex !important;
                    }
                }
            }
        }
    }

    &.page-enter {
        transform: translate3d(0, 0, 1px);
    }

    &.page-enter-active {
        transform: translate3d(-100%, 0, 1px);
        transition: transform $transition-page linear;
    }

    &.page-exit {
        transform: translate3d(-100%, 0, 1px);
    }

    &.page-exit-active {
        transform: translate3d(0, 0, 1px);
        transition: transform $transition-page linear;
    }
}

@include respond-to(desktop) {
    .page-details {
        &__content {
            // sass-lint:disable-block nesting-depth
            display: flex;
            flex-direction: row;

            &.expanded {
                .page-details {
                    &__infos {
                        width: 100%;
                        padding-right: 50px;
                        z-index: 5;

                        &__close {
                            left: auto;
                            right: 0;
                            transform: rotate(-180deg);
                        }
                    }

                    &__main {
                        margin-left: -80%;
                    }

                    &__prospect {
                        padding: 0 45px 0 0;

                        &__close {
                            &__icon {
                                margin-right: 5px;
                            }
                        }

                        &__title {
                            margin-right: -45px;
                        }
                    }
                }
            }

            &:not(.expanded) {
                // sass-lint:disable-block nesting-depth
                .page-details {
                    &__infos {
                        &__close {
                            &__mobile {
                                transform: none;
                            }
                        }
                    }

                    &__prospect {
                        width: 20%;
                    }
                }
            }
        }

        &__prospect {
            &__close {
                border-radius: 3px;
            }
        }

        &__tab-content {
            padding: 10px 40px;
        }

        &__main {
            width: 80%;
            padding: 0 0 15px;

            &__nav {
                .item {
                    max-width: 40px;
                    position: relative;

                    &:hover {
                        color: $white;
                        max-width: 100%;

                        .item__text {
                            max-width: 300px;
                            z-index: 2;
                            padding-right: 10px;
                        }
                    }

                    &__text {
                        z-index: 1;
                        background: $nav-actions;
                        color: $white;
                        top: 0;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        max-width: 0;
                        margin-left: 0;
                    }

                    &-active {
                        max-width: 100%;

                        .item__text {
                            display: inline-block;
                            position: relative;
                            padding-right: 10px;
                            left: 0;
                            background: transparent;
                            max-width: 100%;
                            border: 0;

                            &:after {
                                display: none;
                            }
                        }
                    }

                    &-open {
                        max-width: none;

                        .item__text {
                            max-width: none;
                            padding-right: 10px;
                        }
                    }
                }
            }
        }

        .flex-col-end {
            display: flex;
            flex-direction: column;
            align-content: flex-end;
            margin-top: 20px;
        }
    }
}

@include respond-to('mobile') {
    .page-details .data__stats-header {
        display: flex;
        flex-direction: column;
    }
    .page-details .data-details .data__title--left {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: self-start;
        margin-left: 5px;
    }
}

.fullpage {
    .page-details__content:not(.expanded) .page-details__prospect {
        width: 100%;
        animation: none;
    }
}

