@use "scss/utils/_vars.scss" as *;

.confirm__overlay {
    position: fixed;
    z-index: 9999; // because of admin
    inset: 0;
    background: transparentize($black, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirm {
    background: $white;
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 60vh;
    max-width: 50vw;
}

.confirm__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 0.9rem;
    background: $white;
    color: $main-color;
    padding: 0.5rem 1rem;
    font-weight: 500;
    border-bottom: 1px solid $main-color;
}

.confirm__header i {
    cursor: pointer;
    font-size: 1.4rem;
}

.confirm__content {
    padding-inline: 1.5rem;
    padding-top: 1.5rem;
    font-size: 0.85rem;
}

.confirm__btns {
    display: flex;
    justify-content: space-evenly;
    padding: 1.5rem;
    gap: 1.5rem;
}

.confirm__btns .btn {
    flex: 1;
    border-color: var(--principal-color);
    color: var(--principal-color);
    font-weight: bold;
}

.confirm__btns .confirm__btns__confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background: var(--principal-color);
    color: $white;
    line-height: 1;

    &:hover {
        background: $white;
        color: var(--principal-color);
    }
}

.confirm__btns .confirm__btns__confirm i:before {
    display: inline-block;
    animation: spin 1s linear infinite;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.confirm__btns .confirm__btns__cancel {
    &:hover {
        background: var(--principal-color);
        color: $white;
    }
}
