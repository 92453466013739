@keyframes dash {
    to {
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        width: 0;
    }

    60% {
        width: 90vw;
    }

    100% {
        width: 100vw;
    }
}

@keyframes dataLoader {
    0% {
        background-position: 0 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

@keyframes error {
    from {
        right: -300px;
    }

    to {
        right: 5px;
    }
}

@keyframes toTop {
    from {
        top: 100%;
    }

    to {
        top: 0;
    }
}

@keyframes toBottom {
    from {
        top: 0;
    }

    to {
        top: 100%;
    }
}

@keyframes hoverEdit {
    0% {
        margin-top: 0;
    }

    10% {
        margin-top: -8px;
    }

    20% {
        margin-top: 0;
    }
}

@keyframes slideInfoIn {
    from {
        left: 100%;
    }

    to {
        left: 0;
    }
}

@keyframes slideInfoOut {
    from {
        left: 0;
    }

    to {
        left: 100%;
    }
}

@keyframes horizontalMove {
    0% {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(7px, 0, 1px);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bubbles {
    0% {
        r: 2;
    }

    50% {
        r: 3.5;
    }

    100% {
        r: 2;
    }
}