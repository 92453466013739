@use "scss/utils/_vars.scss" as *;

.table-admin {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.table-admin thead {
    background-color: $nav-actions;
    color: $white;
}

.table-admin :is(th, td) {
    padding: 0.5rem 1rem;
}

.table-admin tbody tr:nth-child(even) {
    background-color: $table-row;
}
