.loginpage {
    background: $bg;
    padding: 4vw;
    position: relative;
    height: 100vh;
    display: flex;
    overflow: hidden;

    .round {
        position: absolute;
        z-index: 1;
        width: 140px;
        height: 140px;
        border-radius: 50%;

        &:first-child {
            background: $round1;
            left: 40%;
            top: 30px;
        }

        &:nth-child(2) {
            background: $round2;
            left: 20%;
            bottom: 30px;
        }

        &:nth-child(3) {
            background: $round3;
            left: 70%;
            bottom: -10px;
        }
    }

    .login {
        width: 100%;
        background: $white;
        position: relative;
        z-index: 3;

        &__images {
            align-self: center;
            padding: 15px;
            max-height: 100%;

            &__logo {
                width: 180px;
            }

            &__text {
                color: $main-color;
                text-align: center;
                font-size: 1.1em;
                margin: 0;
            }

            &__presentation {
                width: 80%;
                margin-left: 10%;
            }
        }

        &__form {
            background: $white;
            padding: 0 30px;

            &__content {
                height: 100%;
            }

            &__field {
                display: block;
                margin-bottom: 15px;

                &__label {
                    display: block;
                    color: $main-color;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    font-size: 1.1em;
                }

                &__input {
                    border: 0;
                    border-bottom: 1px solid $secondary-color;
                    width: 100%;
                    padding: 10px 0;
                    font-size: 1em;

                    &:focus {
                        outline: none;
                    }
                }
            }

            &__btn {
                margin-top: 20px;
                font-weight: bold;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                height: 40px;
                width: 120px;
                border-radius: 5px;
                border: solid 1px $white;
                color: $white;
                background-color: $active-class;
                transition: .20s ease-in-out;

                &:hover {
                    background-color: $white;
                    color: $active-class;
                    border-color: $active-class;
                }
            }

            &__forget {
                color: $main-color;
                opacity: .6;
                margin-bottom: 15px;
                text-align: right;
            }

            &__error {
                display: block;
                margin-top: 20px;
                color: $menu-user;
            }
        }

        &__loader {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $white;
            cursor: wait;
            z-index: 9;

            &__logo {
                max-width: 350px;
                min-width: 30%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 1px);
                overflow: visible;
                padding: 15px;

                svg {
                    overflow: visible;
                    position: relative;
                }

                .bar {
                    animation: dash linear;
                    transform-origin: 112px 70px;
                    animation-duration: 2s;
                    animation-iteration-count: infinite;
                    animation-fill-mode: both;

                    &-2 {
                        animation-duration: 2s;
                    }

                    &-3 {
                        animation-duration: 3s;
                    }
                }
            }
        }
    }

    .powered-by {
        &__text {
            margin-bottom: 0;
            color: $main-color;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    .bubbles {
        &:nth-of-type(4),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(10),
        &:nth-of-type(11) {
            animation: bubbles 3s linear infinite;
        }

        &:nth-of-type(4) {
            animation-delay: .5s;
        }

        &:nth-of-type(6) {
            animation-delay: 1s;
        }

        &:nth-of-type(7) {
            animation-delay: 1.5s;
        }

        &:nth-of-type(10) {
            animation-delay: 0s;
        }

        &:nth-of-type(11) {
            animation-delay: .5s;
        }
    }

    &.exit {
        display: none;
    }
}

@include respond-to('desktop') {
    .loginpage {
        .login {
            &__images {
                &__text {
                    text-align: left;
                    font-size: 1.3em;
                }

                &__presentation {
                    margin-top: 40px;
                }
            }

            &__form {
                &__field {
                    margin-bottom: 25px;

                    &__label {
                        margin-bottom: 5px;
                        font-size: 1.2em;
                    }

                    &__input {
                        font-size: 1.1em;
                    }
                }

                &__btn {
                    margin-top: 35px;
                }
            }
        }
    }
}

@include respond-to('large') {
    .loginpage {
        .login {
            overflow: hidden;

            &__images {
                &__presentation {
                    width: auto;
                    max-width: 80%;
                    margin: 0 auto;
                    display: block;
                }
            }

            &__form {
                box-shadow: 0 0 152px $shadow, 0 0 30px $bg;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
                margin: -30% 0;
                padding: 30% 40px;

                &__field {
                    &__input {
                        min-width: 60%;
                        width: auto;
                    }
                }
            }
        }
    }
}

.login__form__btn:disabled {
    filter: grayscale(1);
    opacity: .6;
    cursor: not-allowed;
}
