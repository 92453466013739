.input-compare {
    display: flex;
    justify-content: space-between;
    height: 40px;
}

.input-compare input {
    flex: 6;
    padding: 0.6rem 0.5rem;
    border-radius: 6px;
    font-size: 1.2em;
    border: unset;
    background: #f3f1fc;
    color: #7565c0;
    width: 50%;

    &:focus {
        outline: none;
    }
}

.input-compare input:first-of-type {
    border-radius: 0 6px 6px 0;
}

.input-compare input + input {
    margin-left: 0.2rem;
}

.input-compare + label {
    left: 5.2rem !important;
}

.input-compare__container {
    flex: 1;
    max-width: 3.5rem;
}

.input-compare .input-compare__control {
    border: 2px solid #eef0fa;
    box-shadow: unset;
    flex-wrap: nowrap;
    color: #7565c0;
    border: unset;
    border-radius: 6px 0 0 6px;
    background: #f3f1fc;
    height: 40px;
}

.input-compare .input-compare__single-value {
    color: #7565c0;
    font-size: 1rem;
    font-weight: 500;
}

.input-compare .input-compare__value-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-compare .input-compare__option {
    font-size: 0.85rem;
    font-weight: 500;
    color: #000;
    cursor: pointer;
    background: transparent;

    &:focus {
        background-color: #a59bd6;
        color: #fff;
    }

    &:hover {
        background: lighten(#7565c0, 12%);
        color: #fff;
    }

    &--is-selected {
        background: #a59bd6;
        color: #fff;
    }
}

.input-compare .input-compare__indicator {
    padding-inline: 0;
}
