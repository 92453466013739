@use "scss/utils/_vars.scss" as *;

.eventnames-list {
    background: $input-bg;
    padding: 1rem;
    border-radius: 6px;
    margin-left: 30px;
    color: $main-color;
}

.eventnames-list > p {
    font-size: 1rem;
}

.eventnames-list ul {
    list-style: none;
}

.eventnames-list ul li:before {
    content: '👁️‍🗨️ ';
}

.eventnames-list ul li {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.eventnames-list ul li i {
    font-size: 1rem;
    cursor: pointer;
}
