.form {
    max-width: 100%;
    position: relative;
    z-index: 2;
    &__button-container {
        position: absolute;
        width: 100%;
        top: -64px;

        @include respond-to('mobile') {
            position: relative;
            top: 0px;
        }
    }

    &__scrollable {
        &__container {
            margin-right: -12px;
            padding-top: 20px;
        }
    }

    .template {
        &__propsect_business {
            right: 15px;
            top: -48px;

            @include respond-to('mobile') {
                top: 0px;
                right: 0;

                .form__submit {
                    padding-right: 8px;
                }
            }

            @include respond-to('tablet') {
                .form__submit:last-child {
                    padding-right: 0;
                }
            }

            @include respond-to('large') {
                .form__submit:last-child {
                    padding-right: 15px;
                }
            }

            .form__submit:last-child {
                margin-left: -15px;
                .form__submit__btn {
                    width: 285px;
                }
            }
        }

        &__propsect_individual {
            top: -48px;
            right: 15px;

            @include respond-to('mobile') {
                top: 0;
                right: 0;

                .form__submit {
                    padding-right: 8px;
                }
            }

            @include respond-to('tablet') {
                .form__submit {
                    padding-right: 0;
                }
            }

            @include respond-to('large') {
                .form__submit {
                    padding-right: 15px;
                }
            }
        }
    }

    .field-select{
        &__item {
            display: block;
            cursor: pointer;
            padding: 7px 15px;

            &:hover {
                color: $white;
                background: $list-hover;
            }
            &__selected {
                color: $white;
                background: $list-hover;
            }
        }
    }

    .field {
        position: relative;
        margin-bottom: 15px;
        align-self: flex-start;
        width: 100%;
        padding: 0;

        &-written {
            .field__input,
            .field__select {
                &:not([type='checkbox']) {
                    // sass-lint:disable-block nesting-depth
                    & + label {
                        color : $input-color-focus;
                        top: -6px;
                        font-size: .9em;
                    }
                }
            }

            .field__textarea {
                & + label {
                    color: $input-color-focus;
                    top: -8px;
                    font-size: .9em;
                }
            }
        }

        &-focused {
            .field__input,
            .field__select {
                &:not([type='checkbox']) {
                    // sass-lint:disable-block nesting-depth
                    & + label {
                        top: -6px;
                        font-size: .9em;
                    }
                }
            }

            .field__textarea {
                & + label {
                    top: -8px;
                    font-size: .9em;
                }
            }
        }

        &__potential {
            margin-top: 10px;
        }

        &-error {
            &:hover {
                .form__error__content {
                    display: block;
                }
            }

            .field__input {
                color: $error;
            }

            .field__label {
                color: $error;
            }

            .field__icon {
                opacity: 0;
            }
        }

        &__span{
            & + label {
                padding-top: 5px;
            }
        }

        &__radio {
            background-color: $input-bg;
            padding-left: 0;
            border-radius: 6px;
            position: relative;

            > div:first-child {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                position: relative;
                width: 100%;
                top: -2.5px;

                > div {
                    height: 25px;
                    margin-left: 15px;
                    position: relative;
                    width: 85%;
                }

                > div:first-child {
                    height: 0 !important;
                    margin-left: 0 !important;

                    @include respond-to('mobile') {
                        margin-top: 15px;
                    }
                }
            }
        }

        &__checkbox {
            //Container
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: $input-bg;
            border-radius: 6px;
            padding: 10px;
            width: 100%;
            height: 40px;

            div:first-child {
                display: block;
                position: relative;
                top: -1.5px;
            }

            &--multiple {
                height: unset;
                position: relative;
                margin-top: 10px;
                padding: 0;

                > div:first-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    > div {
                        height: 25px;
                        margin-left: 20px;
                        position: relative;
                        width: 85%;
                    }

                    >div:first-child {
                        height: 0 !important;
                        margin-left: 0 !important;

                        @include respond-to('mobile') {
                            margin-top: 15px;
                        }
                    }
                }

                .field__input-checkbox {
                    //l'Input
                    padding: 0;
                    height: initial;
                    width: initial;
                    margin-bottom: 0;
                    display: none !important;
                    cursor: pointer;

                    &:checked {
                        + .label-checkbox:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 3px;
                            left: 7px;
                            width: 6px;
                            height: 12px;
                            border: solid $white;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }

                        + .label-checkbox:before {
                            background-color: $input-border-bottom;
                            border: $input-border-bottom;
                        }

                        + .label-checkbox {
                            color: $input-border-bottom !important;
                        }
                    }
                }

                .label-checkbox {
                    display: block;
                    margin-left: -6px;
                    position: relative;
                    cursor: pointer;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: $font-color-sav-searchs !important;
                    user-select: none;

                    &:before {
                        content:'';
                        background-color: transparent;
                        border: solid 2px $font-color-sav-searchs;
                        border-radius: 6px;
                        height: 20px;
                        width: 20px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }
            }
        }

        &__input {
            width: 100%;
            height: 40px;
            padding: 10px;
            font-size: 1.2em;
            border: 0;
            border-radius: 6px;
            background: $input-bg;
            color: $input-color;

            &--checkbox {
                //l'Input
                padding: 0;
                height: initial;
                width: initial;
                margin-bottom: 0;
                display: none !important;
                cursor: pointer;

                &:checked {
                    + .field__label--checkbox:after, .label-checkbox:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 11px;
                        left: 7px;
                        width: 6px;
                        height: 12px;
                        border: solid $white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }

                    + .field__label--checkbox:before, .label-checkbox:before {
                        background-color: $input-border-bottom;
                        border: $input-border-bottom;
                    }

                    + .field__label--checkbox, .label-checkbox {
                        color: $input-border-bottom !important;
                    }
                }
            }

            &-radio {
                display: none;
                &__disabled {
                    opacity: 0.4;
                    pointer-events: none;
                }
                & + label {
                    cursor: pointer;
                    padding-left: 25px;
                    position: relative;
                    margin-right: 10px;
                    color: $font-color-sav-searchs;
                    display: block;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    height: 20px;
                    text-overflow: ellipsis;

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 2px;
                        border-radius: 50%;
                        border: solid 2px $font-color-sav-searchs;
                        background: transparent;
                        width: 17px;
                        height: 17px;
                    }
                }

                &:checked {
                    & + label {
                        color: $input-color;
                        &:after {
                            content: '';
                            position: absolute;
                            left: 4px;
                            top: 6px;
                            border-radius: 50px;
                            background: $input-color;
                            width: 9px;
                            height: 9px;
                        }
                        &:before {
                            border: solid 2px $input-color;
                        }
                    }
                }
            }

            &-date {
                padding: 0;
                font-size: 1em;

                input {
                    outline: 0;
                    border-radius: 6px;
                    display: block;
                    width: 100%;
                    padding: 10px;
                    color: $input-color;
                    font-size: 1.2em;
                    background-color: #f3f1fc;
                    border: 0;
                }
            }

            &:focus {
                outline: none;

                & + label {
                    top: -6px;
                    font-size: .9em;
                    color: $input-color-focus;
                }
            }

            &[type='checkbox'] {
                display: inline-block;
                width: auto;
                float: left;

                &:focus {
                    & + label {
                        font-size: 1.1em;
                    }
                }

                & + label {
                    height: 35px;
                    line-height: 35px;
                    left: 10px;
                    top: 2px;
                    pointer-events: initial;
                    cursor: pointer;
                    position: relative;
                    transform: none;
                }

                & ~ .field__border {
                    display: none;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        &__textarea {
            width: 100%;
            padding: 10px;
            font-size: 1.2em;
            border: 0;
            border-radius: 6px;
            background: $input-bg;
            color: $input-color;
            max-width: 100%;
            min-width: 100%;
            min-height: 40px;

            &:focus {
                outline: none;

                & + label {
                    color: $input-color-focus;
                    top: -8px;
                    font-size: .9em;
                }
            }

            & + label {
                top: 10px;
                transform: none;
            }
        }

        &__select {
            font-size: 1.2em;
            border: 0;
            appearance: none;
            display: block;
            height: 100%;
            width: 100%;
            padding: 0 10px;
            border-radius: 6px;
            background: $input-bg;
            color: $input-color;

            &:focus {
                outline: none;

                & + label {
                    color: $input-color-focus;
                    top: 0;
                    font-size: .9em;
                }
            }
        }

        &__slider {
            // sass-lint:disable-block no-vendor-prefixes
            appearance: none;
            width: 97%;
            background: $input-bg;
            outline: none;
            opacity: .6;
            background: linear-gradient(to right, $secondary-color 0%, $secondary-color 0%, #fff 0, #fff 100%);
            border: solid 1px $secondary-color;
            border-radius: 8px;
            height: 7px;
            transition: background 450ms ease-in;

            &:hover {
                opacity: .9;
            }

            &::-webkit-slider-thumb {
                width: 14px;
                -webkit-appearance: none;
                border-radius: 50%;
                height: 14px;
                cursor: ew-resize;
                background: $input-color;
                margin-top: -14px;
            }

            &__datalist {
                display: flex;
                flex-flow: wrap;
                justify-content: space-between;
                color: $secondary-color;
                width: 97%;
                margin-top: 3px;

                .active {
                    color: $input-color;
                }

                option {
                    display: inline-block;

                    &:first-child {
                        padding-left: 5px;
                    }

                    &:nth-child(2) {
                        padding-left: 5px;
                    }

                    &:nth-child(4) {
                        margin-right: -2px;
                    }

                    &:last-child {
                        margin-right: -4px;
                    }
                }

                & + label {
                    top: -10px;
                    left: 15px;
                }
            }
        }

        &__label {
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            transition: top .1s linear, font-size .1s linear;
            pointer-events: none;
            font-size: 1.1em;
            color: $input-color-focus;
            white-space: nowrap;
            overflow: hidden;
            max-width: calc(100% - 50px);
            display: block;
            text-overflow: ellipsis;

            &--checkbox {
                display: block;
                margin-left: -6px;
                position: relative;
                cursor: pointer;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $font-color-sav-searchs !important;
                user-select: none;

                &:before {
                    content:'';
                    background-color: transparent;
                    border: 2px solid #0079bf;
                    border: solid 2px $font-color-sav-searchs;
                    border-radius: 6px;
                    height: 20px;
                    width: 20px;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 5px;
                }
            }

            &__admin{
                padding-left: 15px;
            }
            &-radio {
                position: relative;
                left: 15px;
                top: -4px;
                font-size: .9em;

                @include respond-to('mobile') {
                    top: -20px
                }
            }
            &-tag {
                position: relative;
                left: 0;
                margin-left: 5px;
                margin-top: 2px;
            }
        }
        &__display-info{
            display: inline-flex;
        }
        &__icon {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            color: $input-color;
        }

        &__list {
            list-style: none;
            padding-left: 15px;

            &__add {
                text-align: center;
                margin-left: 15px;
                border-radius: 15px;
                border: 1px solid $header;
                margin-top: 15px;
                cursor: pointer;
                text-transform: uppercase;
                padding: 5px 15px;
                color: $header;
            }
        }

        &__item {
            border: 0;
            margin-bottom: 10px;
            position: relative;

            &:hover {
                .field__item__delete {
                    opacity: 1;
                }
            }

            @at-root [data-rbd-droppable-id="form-listing-admin"] .field__item:after {
                content: '';
                position: absolute;
                left: 100%;
                top: 0;
                bottom: 0;
                width: 0.8rem;
                background: $input-border-bottom;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                cursor: grabbing;
            }

            &__delete {
                position: absolute;
                padding: 7px 10px 0;
                left: 100%;
                top: 0;
                bottom: 0;
                color: $menu-user;
                cursor: pointer;
                opacity: 0;
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                width: 100%;
            }
            @at-root [data-rbd-droppable-id="form-listing-admin"] .field__item__delete {
                left: calc(100% + 0.8rem);
            }

            &__position {
                position: absolute;
                right: calc(100% + 2px);
                top: 0;
                font-size: 0.65rem;
                opacity: 0.7;
                white-space: nowrap;
                color: $main-color;
            }

            &.QuoteState {
                display: flex;
                gap: 1rem;

                .field__item__delete {
                    position: revert;
                    padding: unset;
                    inset: unset;
                    justify-content: unset;
                    gap: 1rem;
                    opacity: 1;

                    > span i {
                        display: inline-block;
                        font-size: 1rem;
                        margin-top: 0.7rem;
                    }

                    .quote-state {
                        flex: 1;
                        display: flex;
                        gap: 1rem;

                        > * {
                            flex: 1;
                        }
                    }
                }
            }

            .field__input {
                padding-left: 10px;
                border-radius: 4px;
                background: $bg;
            }
        }

        &__autocomplete {
            position: absolute;
            background: $white;
            z-index: 2;
            top: calc(100% + 5px);
            left: 16px;
            right: 16px;
            box-shadow: 0 0 5px $box-shadow;
            border-radius: 4px;
            overflow-y: auto;
            max-height: 350px;

            .list {
                list-style: none;

                &__item {
                    display: block;
                    cursor: pointer;
                    padding: 7px 15px;

                    &:hover {
                        color: $white;
                        background: $list-hover;
                    }
                    &__selected {
                        color: $white;
                        background: $list-hover;
                    }
                }
            }
        }

        [class*='-placeholder'] {
            color: $input-color-focus;
        }

        [class^='css-'] {
            color: $input-color;
        }

        [class*='-control'] {
            background: $input-bg;
            border: 0;
        }

        [class*='-multiValue'] {
            background: $input-bg-multi;
        }

        [class*='-Svg'] {
            color: $input-color;
        }
    }

    &__submit {
        text-align: right;
        margin: 15px 0;

        @include respond-to('mobile') {
            flex: none;
            max-width: none;
            width: auto;
        }

        &__btn {
            &__disabled{
                background-color: $page404-color1;
                pointer-events: none;
            }
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.3rem;
            height: 40px;
            padding-inline: 1rem;
            border-radius: 5px;
            border: solid 1px $main-color;
            color: $main-color;
            background-color: $white;
            transition: .20s ease-in-out;

            &:disabled {
                background-color: $disabled;
                border-color: $disabled;
                cursor: default;
                color: $disabled-font-color;
                pointer-events: none;
            }

            &__icon {
                font-size: 1.1em;

                .policon-mails-send {
                    font-size: 1.5em;
                    display: block;
                }
            }

            &:hover {
                background-color: $main-color;
                color: $white;
            }
        }
    }

    &__back {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        font-size: 1em;
        width: 90px;
        height: 25px;
        border-radius: 5px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
        color: $main-color;
        transition: .15s ease-in-out;
        font-weight: normal;

        @include respond-to('mobile') {
            flex: none;
            max-width: none;
        }

        &:hover {
            color: $white;
            background-color: $main-color;
        }

        &__icon {
            font-size: .88em;
        }
    }

    &__sub-label{
        color: $lte-active-link;
        font-size: .88em;
    }

    &__label {
        &__admin{
            text-align: right;
            color: $lte-active-link;
            &__info{
                font-style: italic;
                font-size: 0.9em;
            }
            &__mt-10{
                margin-top: 10px;
            }
        }


    }

    &__content {
        &__admin{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 13px;
        }
    }

    &__error {
        pointer-events: none;

        &__icon {
            position: absolute;
            right: 25px;
            color: $error;
            font-size: 1.5em;
            top: 10px;
            z-index: 1;
        }

        &__error-import{
            color: $error;
            background: $white;
            display: none;
            padding: 2px 7px;
            border-radius: 4px;
            white-space: nowrap;
            z-index: 3;
            box-shadow: 0 0 5px $box-shadow-column;
            font-size: .8em;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 8px 0;
                border-color: $white transparent transparent;
                z-index: 1;
            }
        }

        &__icon-import {
            right: 25px;
            color: $black;
            font-size: 1.5em;
            top: 10px;
            z-index: 1;
        }

        &__content {
            position: absolute;
            color: $error;
            bottom: 100%;
            left: calc(100% - 60px);
            display: none;
            background: $white;
            padding: 2px 7px;
            border-radius: 4px;
            white-space: nowrap;
            z-index: 3;
            box-shadow: 0 0 5px $box-shadow-column;
            font-size: .8em;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 8px 0;
                border-color: $white transparent transparent;
                position: absolute;
                top: 100%;
                left: 18px;
                z-index: 1;
            }
        }
    }

    &-loader {
        .field {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                z-index: 2;
                background: linear-gradient(270deg, $loader-final, $loader-init);
                background-size: 400% 400%;
                animation: dataLoader 5s ease infinite;
                left: 0;
                bottom: 0;
                border-radius: 6px;
                top: 0;
                width: 100%;
            }

            &__label {
                display: none;
            }
        }
    }

    &-scrollable {
        overflow-y: auto;
        position: relative;
        max-height: 90%;
    }

    &-search {
        background: none;
        position: relative;
        z-index: 3;

        .field {
            &__label {
                color: $input-color;

            }

            &__submit {
                &-search {
                    width: 100%;
                    text-align: center;
                }

                &__btn {
                    padding: 5px 20px;
                }
            }
        }
    }

    .group {
        &__content {
            margin-bottom: 15px;
            border-radius: 4px;
            min-inline-size: auto;
            border: 0;
        }

        &__title {
            color: $main-color;
            font-weight: 700;
            font-size: 1.1em;
            height: 30px;
            margin-bottom: 8px;

            &:not(.col-12) {
                min-height: 23px;
            }

            &.col-12 {
                &:not(:blank) {
                    min-height: 23px;
                }
            }

            &__text {
                display: inline-block;
                white-space: nowrap;
            }
        }

        &__container {
            width: 100%;
            box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
            border-radius: 5px;
            margin-left: 15px;
            margin-right: 0px;

            .group__item:last-child {
                margin-bottom: 0;
            }

            .field:last-child {
                margin-bottom: 0;
            }
        }
    }

    .rdt {
        // sass-lint:disable-block class-name-format
        position: relative;

        &Picker {
            top: 100%;
            font-size: 1.1em;
            border-radius: 4px;
            color: $input-color;
        }

        &Days {
            th {
                font-weight: 500;
            }
        }

        &Day {
            border-radius: 4px;

            &:hover {
                background: $input-color-focus;
            }
        }

        &Today {
            background: $input-bg;

            &:before {
                opacity: 0;
            }
        }

        &Open {
            & + label {
                top: -6px;
                font-size: .9em;
                color: $input-color-focus;
            }
        }
    }

    .input-compare-icon{
        font-size: 1.4em;
        color: $input-color;
        position: absolute;
        right: 10px;
        top: 30%;
    }

    .input-compare{
        position: relative;
    }

    &__admin {
        .field {
            padding-left: 15px;
            padding-right: 15px;

            &__label {
                padding-left: 15px;
                &--checkbox {
                    padding-left: 0;
                    user-select: none;
                }
            }

            &__radio {
                margin-left: 15px;
            }

            &__input-radio {
                + label {
                    height: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &:before {
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:after {
                        top: 50% !important;
                        transform: translateY(-50%);
                    }
                }
            }
        }

    }

    .quote-state{
        text-align: left;
        margin-right: -450px;
        display: inline-block;

        .quote-field__input{
            margin-right: 10px;
            margin-left: 30px;
            display: inline-block;
        }
        .quote-field__label{
            display: inline-block;
        }
    }

    &__flex-column {
        display: flex;
        flex-direction: column;
    }

    &__separator {
      margin-top: 10px;
    }

    .btn-fl-right {
        float: right;
        margin-top: 15px;
        cursor: pointer;
        background-color: #372e74;
        color: #fff;
        font-weight: 400;
        font-size: 1em;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        width: 185px;
        height: 40px;
    }

    .input__display-info {
        display: flex;
        height: 50px;
        &__item {
            margin: 3px;
            font-size: 1.1em;
            color: $input-color-focus;
            white-space: nowrap;
            overflow: hidden;
            max-width: calc(100% - 50px);
            display: block;
            text-overflow: ellipsis;
            -webkit-user-select: all;  /* Chrome 49+ */
            -moz-user-select: all;     /* Firefox 43+ */
            -ms-user-select: all;      /* No support yet */
            user-select: all;          /* Likely future */
        }
    }

    .input-read-only {
        color: $grey;
        input {
          color: $grey;
        }
    }
}

.content-search {
    .field__input:focus + label {
        @include respond-to('mobile') {
            color: $input-color-focus;
        }

        @include respond-to('tablet') {
            color: $input-color-focus;
        }

        @include respond-to('desktop') {
            color: $black;
        }
    }

    .field-written .field__input:not([type='checkbox']) + label {
        @include respond-to('mobile') {
            color: $input-color-focus;
        }

        @include respond-to('tablet') {
            color: $input-color-focus;
        }

        @include respond-to('desktop') {
            color: $black;
        }
    }

    .form-search .field__label-radio {
        @include respond-to('mobile') {
            color: $font-color-sav-searchs;
        }

        @include respond-to('tablet') {
            color: $font-color-sav-searchs;
        }

        @include respond-to('desktop') {
            color: $black;
        }
    }

    .field-focused .field__label {
        @include respond-to('mobile') {
            color: $input-color-focus;
        }

        @include respond-to('tablet') {
            color: $input-color-focus;
        }

        @include respond-to('desktop') {
            color: $black;
        }
    }
}

.extended {
    .field__input:focus + label {
        color: $input-color-focus;
    }

    .field-written .field__input:not([type='checkbox']) + label {
        color: $input-color-focus;
    }

    .field__input[type='checkbox'] + label {
        color: $input-color-focus;
    }

    .field-focused .field__label {
        color: $input-color-focus;
    }

    .form-search .field__label-radio {
        color: $font-color-sav-searchs;
    }
}

// Override TinyMCE
.tox .tox-editor-header{
    z-index: 0 !important;
}
