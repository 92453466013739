@use "scss/utils/_vars.scss" as *;

.move-contact-popup__content {
    position: relative;
    min-height: 11rem;
}

.move-contact-popup__loader {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    background: hsla(0, 100%, 100%, 0.7);

    @supports (backdrop-filter: blur(3px)) {
        backdrop-filter: blur(3px);
        background: unset;
    }
}

.move-contact-popup__loader svg {
    height: 100%;
}

.move-contact-popup__grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 1rem;
}

.move-contact-popup__field-wrapper {
    display: flex;
    gap: 0.5rem;
}

.move-contact-popup__field-wrapper .input-field__errors {
    color: $error;
    order: 2;
}

.move-contact-popup__field-wrapper[data-type='select'] {
    grid-column: span 2;
    flex-direction: column;
}

.move-contact-popup__field-wrapper[data-type='select'] .react-select {
    order: 1;
}

.move-contact-popup__field-wrapper[data-type='checkbox'] {
    align-items: center;
}

.move-contact-popup__field-wrapper[data-type='checkbox'] .input-field-checkbox-container {
    display: grid;
    place-items: center;
}

.move-contact-popup__field-wrapper[data-type='checkbox'] input {
    accent-color: $input-default-color;
    width: 1.2rem;
    height: 1.2rem;
}

.move-contact-popup__btn-confirm[disabled] {
    background: $disabled;
    border-color: $disabled;
    color: $disabled-font-color;
    pointer-events: none;
}
