@use "scss/utils/_vars.scss" as *;

.inline-loader {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 0.9rem;
}

.inline-loader > i {
  line-height: 0;
  font-size: 1.4em;
  color: $main-color;
}
