@use "scss/utils/_vars.scss" as *;

.listing-header {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding-block: 0.75rem;
}

.listing-header__filters {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.listing-header__actions {
    display: flex;
    gap: 0.5rem;
}

.listing-header__action {
    line-height: 0;
    border: 1px solid $main-color;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    padding: 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    color: $main-color;
    background: transparent;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    position: relative;

    &:hover {
        background: $main-color;
        color: $white;
    }
}

.listing-header__action.disabled {
    border-color: $disabled-font-color;
    color: $disabled-font-color;
    background: $disabled;
    cursor: not-allowed;
}

.listing-header__action > a:before {
    content: "";
    position: absolute;
    inset: 0;
}

.listing-header__action i {
    font-size: 1.2rem;
}

.listing-header__action > p {
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
}

.listing-header__action.dropdown {
    position: relative;
}

.listing-header__action__dropdown {
    line-height: 1.5;
    display: none;
    list-style: none;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    width: max-content;
    background: $white;
    border: 1px solid $main-color;
    font-size: 0.85rem;
    color: $main-color;
}

.listing-header__action.dropdown:is(:hover, :focus, :active) .listing-header__action__dropdown {
    display: block;
}

.listing-header__action__item {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;

    &:not(.listing-header__action__item__disabled):hover {
        background: $main-color;
        color: $white;
    }
}

.listing-header__action__item__disabled {
    background: $disabled;
    color: $disabled-font-color;
    pointer-events: none;
}

.listing-searchbar input {
    height: 100%;
    min-height: 2.2rem;
    min-width: 150px;
}
