@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7cpild');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7cpild#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7cpild') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7cpild') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7cpild##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.policon-Polygone {
  &:before {
    content: $policon-Polygone; 
  }
}
.policon-abc {
  &:before {
    content: $policon-abc; 
  }
}
.policon-actions {
  &:before {
    content: $policon-actions; 
  }
}
.policon-add {
  &:before {
    content: $policon-add; 
  }
}
.policon-add-client {
  &:before {
    content: $policon-add-client; 
  }
}
.policon-add-company {
  &:before {
    content: $policon-add-company; 
  }
}
.policon-add-fine {
  &:before {
    content: $policon-add-fine; 
  }
}
.policon-add-full {
  &:before {
    content: $policon-add-full; 
  }
}
.policon-address-book {
  &:before {
    content: $policon-address-book; 
  }
}
.policon-affichage-icone {
  &:before {
    content: $policon-affichage-icone; 
  }
}
.policon-affichage-ligne {
  &:before {
    content: $policon-affichage-ligne; 
  }
}
.policon-arrow-left {
  &:before {
    content: $policon-arrow-left; 
  }
}
.policon-arrow-right {
  &:before {
    content: $policon-arrow-right; 
  }
}
.policon-arrow-right-bis {
  &:before {
    content: $policon-arrow-right-bis; 
  }
}
.policon-bars {
  &:before {
    content: $policon-bars; 
  }
}
.policon-bin {
  &:before {
    content: $policon-bin; 
  }
}
.policon-building {
  &:before {
    content: $policon-building; 
  }
}
.policon-business {
  &:before {
    content: $policon-business; 
  }
}
.policon-calculator {
  &:before {
    content: $policon-calculator; 
  }
}
.policon-check-circle {
  &:before {
    content: $policon-check-circle; 
  }
}
.policon-chevron-circle-left {
  &:before {
    content: $policon-chevron-circle-left; 
  }
}
.policon-chevron-down {
  &:before {
    content: $policon-chevron-down; 
  }
}
.policon-circle-notch {
  &:before {
    content: $policon-circle-notch; 
  }
}
.policon-client {
  &:before {
    content: $policon-client; 
  }
}
.policon-clock {
  &:before {
    content: $policon-clock; 
  }
}
.policon-clock-full {
  &:before {
    content: $policon-clock-full; 
  }
}
.policon-close {
  &:before {
    content: $policon-close; 
  }
}
.policon-cog {
  &:before {
    content: $policon-cog; 
  }
}
.policon-cogs {
  &:before {
    content: $policon-cogs; 
  }
}
.policon-company {
  &:before {
    content: $policon-company; 
  }
}
.policon-completed-task {
  &:before {
    content: $policon-completed-task; 
  }
}
.policon-contact {
  &:before {
    content: $policon-contact; 
  }
}
.policon-copy {
  &:before {
    content: $policon-copy; 
  }
}
.policon-database {
  &:before {
    content: $policon-database; 
  }
}
.policon-delete {
  &:before {
    content: $policon-delete; 
  }
}
.policon-document {
  &:before {
    content: $policon-document; 
  }
}
.policon-download {
  &:before {
    content: $policon-download; 
  }
}
.policon-draggable {
  &:before {
    content: $policon-draggable; 
  }
}
.policon-dwl-csv {
  &:before {
    content: $policon-dwl-csv; 
  }
}
.policon-edit {
  &:before {
    content: $policon-edit; 
  }
}
.policon-edit-client {
  &:before {
    content: $policon-edit-client; 
  }
}
.policon-engrenage {
  &:before {
    content: $policon-engrenage; 
  }
}
.policon-envelope {
  &:before {
    content: $policon-envelope; 
  }
}
.policon-envelope-open-text {
  &:before {
    content: $policon-envelope-open-text; 
  }
}
.policon-euro {
  &:before {
    content: $policon-euro; 
  }
}
.policon-euro-sign {
  &:before {
    content: $policon-euro-sign; 
  }
}
.policon-exclamation-circle {
  &:before {
    content: $policon-exclamation-circle; 
  }
}
.policon-exe {
  &:before {
    content: $policon-exe; 
  }
}
.policon-export {
  &:before {
    content: $policon-export; 
  }
}
.policon-external-link {
  &:before {
    content: $policon-external-link; 
  }
}
.policon-eye {
  &:before {
    content: $policon-eye; 
  }
}
.policon-eye-cancel {
  &:before {
    content: $policon-eye-cancel; 
  }
}
.policon-eye-slash {
  &:before {
    content: $policon-eye-slash; 
  }
}
.policon-file {
  &:before {
    content: $policon-file; 
  }
}
.policon-file-bis {
  &:before {
    content: $policon-file-bis; 
  }
}
.policon-file-contract {
  &:before {
    content: $policon-file-contract; 
  }
}
.policon-file-export-solid {
  &:before {
    content: $policon-file-export-solid; 
  }
}
.policon-file-invoice-dollar {
  &:before {
    content: $policon-file-invoice-dollar; 
  }
}
.policon-file_present {
  &:before {
    content: $policon-file_present; 
  }
}
.policon-filters {
  &:before {
    content: $policon-filters; 
  }
}
.policon-flow {
  &:before {
    content: $policon-flow; 
  }
}
.policon-folder {
  &:before {
    content: $policon-folder; 
  }
}
.policon-folder-settings {
  &:before {
    content: $policon-folder-settings; 
  }
}
.policon-folder-zip {
  &:before {
    content: $policon-folder-zip; 
  }
}
.policon-globe {
  &:before {
    content: $policon-globe; 
  }
}
.policon-home-pol {
  &:before {
    content: $policon-home-pol; 
  }
}
.policon-key {
  &:before {
    content: $policon-key; 
  }
}
.policon-list {
  &:before {
    content: $policon-list; 
  }
}
.policon-list-check {
  &:before {
    content: $policon-list-check; 
  }
}
.policon-mail {
  &:before {
    content: $policon-mail; 
  }
}
.policon-mail-historical {
  &:before {
    content: $policon-mail-historical; 
  }
}
.policon-mails-received {
  &:before {
    content: $policon-mails-received; 
  }
}
.policon-mails-send {
  &:before {
    content: $policon-mails-send; 
  }
}
.policon-map {
  &:before {
    content: $policon-map; 
  }
}
.policon-map-marker {
  &:before {
    content: $policon-map-marker; 
  }
}
.policon-minus {
  &:before {
    content: $policon-minus; 
  }
}
.policon-move-client {
  &:before {
    content: $policon-move-client; 
  }
}
.policon-ongoing-task {
  &:before {
    content: $policon-ongoing-task; 
  }
}
.policon-p-square {
  &:before {
    content: $policon-p-square; 
  }
}
.policon-paragraph {
  &:before {
    content: $policon-paragraph; 
  }
}
.policon-pdf {
  &:before {
    content: $policon-pdf; 
  }
}
.policon-percent {
  &:before {
    content: $policon-percent; 
  }
}
.policon-phone {
  &:before {
    content: $policon-phone; 
  }
}
.policon-phone-alt {
  &:before {
    content: $policon-phone-alt; 
  }
}
.policon-plus {
  &:before {
    content: $policon-plus; 
  }
}
.policon-plus-circle {
  &:before {
    content: $policon-plus-circle; 
  }
}
.policon-question {
  &:before {
    content: $policon-question; 
  }
}
.policon-quit {
  &:before {
    content: $policon-quit; 
  }
}
.policon-quote {
  &:before {
    content: $policon-quote; 
  }
}
.policon-refresh {
  &:before {
    content: $policon-refresh; 
  }
}
.policon-return {
  &:before {
    content: $policon-return; 
  }
}
.policon-search {
  &:before {
    content: $policon-search; 
  }
}
.policon-search-plus {
  &:before {
    content: $policon-search-plus; 
  }
}
.policon-sign-out {
  &:before {
    content: $policon-sign-out; 
  }
}
.policon-sort {
  &:before {
    content: $policon-sort; 
  }
}
.policon-sort-down {
  &:before {
    content: $policon-sort-down; 
  }
}
.policon-sort-up {
  &:before {
    content: $policon-sort-up; 
  }
}
.policon-store-alt {
  &:before {
    content: $policon-store-alt; 
  }
}
.policon-subrequests {
  &:before {
    content: $policon-subrequests; 
  }
}
.policon-svg {
  &:before {
    content: $policon-svg; 
  }
}
.policon-tab {
  &:before {
    content: $policon-tab; 
  }
}
.policon-tags {
  &:before {
    content: $policon-tags; 
  }
}
.policon-tasks {
  &:before {
    content: $policon-tasks; 
  }
}
.policon-times-circle {
  &:before {
    content: $policon-times-circle; 
  }
}
.policon-tools {
  &:before {
    content: $policon-tools; 
  }
}
.policon-trash {
  &:before {
    content: $policon-trash; 
  }
}
.policon-txt {
  &:before {
    content: $policon-txt; 
  }
}
.policon-unique {
  &:before {
    content: $policon-unique; 
  }
}
.policon-unlock {
  &:before {
    content: $policon-unlock; 
  }
}
.policon-user {
  &:before {
    content: $policon-user; 
  }
}
.policon-user-link {
  &:before {
    content: $policon-user-link; 
  }
}
.policon-validation {
  &:before {
    content: $policon-validation; 
  }
}
.policon-video-camera {
  &:before {
    content: $policon-video-camera; 
  }
}
.policon-warning {
  &:before {
    content: $policon-warning; 
  }
}
.policon-web {
  &:before {
    content: $policon-web; 
  }
}
.policon-width-1-3 {
  &:before {
    content: $policon-width-1-3; 
  }
}
.policon-width-1-4 {
  &:before {
    content: $policon-width-1-4; 
  }
}
.policon-width-2-3 {
  &:before {
    content: $policon-width-2-3; 
  }
}
.policon-width-3-4 {
  &:before {
    content: $policon-width-3-4; 
  }
}
.policon-width-full {
  &:before {
    content: $policon-width-full; 
  }
}
.policon-width-half {
  &:before {
    content: $policon-width-half; 
  }
}
.policon-zip {
  &:before {
    content: $policon-zip; 
  }
}

