@use "scss/utils/_vars.scss" as *;

button.quote-line-description-btn {
    line-height: 2.4;
}

.quote-line-description-editor .input-field-rich-text-editor .tox-tinymce {
    min-height: 200px;
}

.quote-line-description-editor .input-field-rich-text-editor .tox-tinymce {
    transition: border-color 0.2s ease-in-out;
}

.quote-line-description-editor.has-error .input-field-rich-text-editor .tox-tinymce {
    border-color: $error;
}

.quote-line-description-editor.has-error > p {
    color: $error;
    margin-top: 0.3rem;
}

.quote-line-description-editor button:disabled {
    background-color: $disabled;
    border-color: $disabled;
    color: $disabled-font-color;
    cursor: not-allowed;
}

.tox.tox-tinymce-aux {
    z-index: 9999; // It shouldn't exists but the CSS is pretty bad for now for handling z-index
}
