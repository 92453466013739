@use "scss/utils/_vars.scss" as *;

.vo-action__config__button__container {
    .vo-action__list-hover {
        z-index: 2;
        padding-block: 0.25rem;
    }

    .vo-action__list-hover__item.active:not(:hover) {
        background: none;
    }

    .vo-action__config__item,
    .vo-action__config__item__submenu__item {
        position: relative;
        display: grid;
        grid-template-columns: 1rem 1fr;
        align-items: center;
        gap: 0.25rem;
        line-height: 1;
        font-size: 0.8rem;
        padding: 0.5rem 0.5rem;
        white-space: nowrap;
    }

    .vo-action__config__item i {
        font-size: inherit;
    }

    .vo-action__config__item__submenu {
        list-style: none;

        position: absolute;
        z-index: 1;
        bottom: -0.25rem;
        right: 100%;
        background: $white;
        padding-block: 0.25rem;
        opacity: 0;
        box-shadow: 0 0 7px rgba($black, 0.1);
        visibility: hidden;
        transition: visibility 0.2s, opacity 0.2s;
    }

    .vo-action__config__item:hover .vo-action__config__item__submenu {
        opacity: 1;
        visibility: visible;
    }

    .vo-action__config__item__submenu__item:hover {
        background: $secondary-color;
    }
}
