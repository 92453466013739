.quotes-products__popup {
    background: #fff;
    min-width: 55%;
    max-height: 90vh;
    height: fit-content;
    //overflow-y: auto;
}

.quotes-products__popup__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #CCC4F3;
    color: #fff;
    font-weight: bold;
    padding: 1rem;
    font-size: 0.9rem;
}

.quotes-products__popup__body {
    padding: 1rem;
    position: relative;
}

.quotes-products__popup__body__add-form {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    padding-block: 1rem;
}

.quotes-products__popup__body__add-form button {
    flex: none;
    aspect-ratio: 1 / 1;
    background: #2F56B7;
    color: #fff;
    border: none;
    font-size: 1.5rem;
    padding-inline: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background: darken(#2F56B7, 10%);
    }
}

.quotes-products__popup__body__add-form .react-select {
    min-width: 350px;

    &__control {
        border-color: transparent !important;
        box-shadow: unset !important;
    }

    &__single-value, &__input {
        color: #9B91D1;
        font-weight: bold;
    }

    &__value-container {
        cursor: text;
    }
}

.quotes-products__popup__body__add-form:not(.advanced) .input-field-wrapper:first-of-type {
    flex: auto;
}

:where(
.quotes-products__popup__body__add-form.advanced,
.quotes-products__popup__body__total-quantity,
.quotes-products__popup__body__table__price,
.quotes-products__popup__body__table__quantity
) > .input-field {
    flex: 1;
    background: #F2EFFC;
    border: none;
    padding: 0.75rem 1rem;
    color: #9B91D1;
    font-weight: bold;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #9B91D1;
        font-weight: 400;
    }
}

.quotes-products__popup__body__advanced {
    display: flex;
    justify-content: flex-end;
    padding-block: 1rem;
}

.quotes-products__popup__body__advanced button {
    border: none;
    background: none;
    text-decoration: underline;
    color: #9B91D1;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: darken(#9B91D1, 10%);
    }
}

.quotes-products__popup__body__buttons {
    display: flex;
    justify-content: flex-end;
    padding-block: 1rem;
    column-gap: 1rem;
}

.quotes-products__popup__body__buttons.button-search-container {
    padding-top: 0;
}

.quotes-products__popup__body__buttons button {
    background: none;
    font-weight: bold;
    cursor: pointer;
    padding: 0.5rem 2rem;
    transition: background-color 0.2s ease-in-out;
}

.quotes-products__popup__body__buttons .quotes-products__popup__body__button-return {
    border: 1px solid #2F56B7;

    &:hover {
        background: transparentize(#9B91D1, 0.85);
    }
}

.quotes-products__popup__body__buttons .quotes-products__popup__body__button-validate {
    border: 1px solid #2F56B7;
    background: #2F56B7;
    color: #fff;

    &:hover {
        background: darken(#2F56B7, 5%);
    }
}

.quotes-products__popup__body__add-form .loader {
    position: absolute;
    inset: 0;
    backdrop-filter: blur(3px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.75rem;
}

.quotes-products__popup__body__add-form .react-select__menu {
    max-height: 250px;

    > div {
        max-height: inherit;
    }
}

.quotes-products__popup__body__search-count-results {
    font-size: 0.9rem;
    color: #7565c0;
}

.quotes-products__popup__body__table-loader-container {
    position: relative;
}

.quotes-products__popup__body__table-container {
    overflow-y: auto;
    height: 35vh;
    position: relative;
}

.quotes-products__popup__body__table {
    color: #9B91D1;

    thead {
        background: #F5F3FC;

        th {
            text-transform: initial !important;
            font-weight: 500 !important;
        }

        th:nth-child(1) {
            width: 75px;
        }

        th:nth-child(4) {
            width: 100px;
        }

        th:nth-child(5) {
            width: 17%;
        }
    }

    tbody {

        &.empty {
            height: unset;

            td:not(.listing-table-tbody__loader):first-of-type {
                padding-bottom: unset;
            }
        }

        tr {
            .quotes-products__popup__body__table__desc {
                p:first-child {
                    font-weight: 500;
                    font-size: 0.85rem;
                }

                p:not(:first-child) {
                    font-weight: 300;
                    font-size: 0.65rem;
                }
            }

            .quotes-products__popup__body__table__price {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.25rem;
            }

            .quotes-products__popup__body__table__quantity {
                input {
                    text-align: right;
                    padding-right: 0;
                }
            }

            &:nth-child(even) :where(.quotes-products__popup__body__table__quantity, .quotes-products__popup__body__table__price) input {
                background: #fff;
            }

            img {
                width: 100%;
            }

            td {
                cursor: initial;
                color: #9B91D1;
            }
        }
    }
}

.quotes-products__popup__body__sub-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 2rem;
    margin-top: 1rem;
}

.quotes-products__popup__body__total-sections,
.quotes-products__popup__body__total-quantity {
    display: flex;
    gap: 0.5rem;
}

:where(
    .quotes-products__popup__body__total-sections,
    .quotes-products__popup__body__total-quantity
) label {
    align-self: center;
    color: #7565c0;
    max-width: 6rem;
}

.quotes-products__popup__body__total-quantity input {
    height: 100%;
    max-width: 6rem;
}

.quotes-products__popup__body__total-sections > .react-select{
    min-width: 10rem;
}

.quotes-products__popup__body__total {
    background: #F5F3FC;
    color: #9B91D1;
    font-weight: 600;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0.5rem;
    white-space: nowrap;
}
