.mapspage {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: $white;

    .leaflet {
        &-container {
            height: calc(100% - 50px);
            width: 100%;
        }
    }

    .leaflet-top {
        @include respond-to('mobile') {
            top: 45px;
        }
    }

    .maps {
        position: relative;
        height: 100%;
    }

    .distance {
        position: absolute;
        z-index: 999;
        top: 10px;
        right: 50px;
        background: $bg-maps-distance;
        padding: 10px 15px;
        color: $white;

        @include respond-to('mobile') {
            right: unset;
            top: 0;
            width: 100%;
        }

        &__title {
            display: inline-block;
        }

        &__input {
            width: 55px;
            padding: 5px;
            margin: 0 10px;
            border: 0;
            appearance: none;
            text-align: center;
            outline: none;
        }

        &__btn {
            cursor: pointer;
            display: inline-block;
            margin-left: 15px;
            padding: 5px 15px;
            background: $main-color;
            color: $white;
            border: 0;
            font-size: 1.1em;
            text-transform: uppercase;
            outline: none;
        }
    }

    &__popin {
        position: absolute;
        z-index: 9999;
        max-width: 350px;
        min-width: 300px;
        background: $bg-maps-popin;
        color: $white;
        cursor: initial;

        .label-checkbox {
            color: $black;
        }

        &.help {
            max-width: 550px;
            right: 25px;
            bottom: 25px;

            @include respond-to('mobile') {
                width: 100%;
                max-width: unset;
                right: unset;
                bottom: 0;
            }

            @include respond-to('mobile') {
                &.close {
                    bottom: 10px;
                    left: 10px;
                }
            }
        }

        &.info {
            font-size: .85em;
            left: 25px;
            top: 110px;

            .close {
                background: none;
                padding: 0;
                right: 0;
                font-size: 1.4em;
                z-index: 1;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                padding-right: 10px;
                padding-left: 5px;
            }
        }

        &.search {
            right: 0;
            top: 0;
            max-width: none;
            width: 100%;
            max-height: calc(100% - 50px);
            overflow-y: auto;
            background: $bg-search-title;

            @include respond-to('mobile') {
                top: 55px;
                right: 10px;
                border-radius: 2px;
            }

            .close {
                background: none;
                color: $main-color;
                z-index: 5;
            }

            .title {
                text-transform: uppercase;
                font-weight: 700;
                position: sticky;
                padding: 10px 15px;
                background: $bg-search-title;
                top: 0;
                color: $main-color;
                font-size: 1.3em;
                margin-bottom: 0;
                padding-right: 25px;
                z-index: 5;
            }

            .form {
                padding-top: 0;
            }
        }

        &.close {
            min-width: 0;
            width: auto;

            .popin-toggle {
                display: none;
            }

            .close {
                position: relative;
                padding: 7px 10px 5px;
                display: block;
                right: 0;
                top: 0;
                font-size: 1em;

                &__icon {
                    font-size: 1.2em;
                }
            }

            .title {
                padding: 0;
                font-size: 1em;
            }
        }

        .list {
            list-style: none;
            padding: 20px 35px;
            background: $map-info-content;

            &__item {
                font-size: 1.1em;
                display: block;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .close {
            position: absolute;
            right: 0;
            top: 0;
            padding: 7px 10px 5px;
            background: $bg-maps-distance;
            cursor: pointer;

            &__icon {
                font-size: 1.2em;
            }
        }

        .info {
            &__header {
                padding: 10px 35px 10px 15px;
                text-transform: uppercase;
                background: $map-info-header;
                font-size: 1.2em;
                position: relative;

                &__icon {
                    font-size: 1.2em;
                    margin-right: 10px;
                }

                &__name {
                    display: inline-block;
                    font-size: 1.2em;
                }
            }

            &__content {
                font-size: 1.2em;
                padding: 20px 30px;
                background: $map-info-content;

                .data {
                    margin: 15px 0;

                    &__label {
                        display: inline-block;
                        margin-right: 10px;
                    }

                    &__value {
                        display: inline-block;
                        font-weight: 700;
                    }
                }
            }

            &__address {
                font-weight: 700;
                font-size: 1.35em;
                padding: 10px 30px;
                background: $map-info-address;

                &__line {
                    &:last-child {
                        text-transform: uppercase;
                    }
                }
            }

            &__footer {
                position: relative;
                background: $map-info-footer;
                padding: 8px 30px;
                font-size: 1.2em;
                font-weight: 700;
                color: $white;

                &__icon {
                    font-size: 1.2em;
                }

                &__number {
                    font-size: 1.2em;
                    margin-left: 10px;
                    display: inline-block;
                }

                .link {
                    position: absolute;
                    right: 0;
                    display: flex;
                    align-items: center;
                    padding: 0 12px;
                    top: 0;
                    bottom: 0;
                    cursor: pointer;
                    background: darken($secondary-color, 10%);
                    box-shadow: inset 0 0 0 30px $secondary-color;
                    transition: box-shadow .2s linear;
                    color: $white;

                    &:hover {
                        box-shadow: inset 0 0 0 0 $secondary-color;
                    }
                }
            }
        }
    }

    &__header {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        color: $main-color;
        text-transform: uppercase;
        font-size: 1.2em;
        justify-content: space-between;

        .company {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__icon {
                font-size: 1.1em;
                margin-right: 10px;

                &-close {
                    font-size: 1.3em;
                }
            }

            &__name {
                display: inline-block;
            }
        }

        .map-icon {
            text-align: left;

            &__icon {
                font-size: 1.6em;
            }
        }

        .close {
            text-align: right;

            &__icon {
                cursor: pointer;
                font-size: 1.2em;
            }
        }
    }

    .map-error {
        color: $black;
        position: absolute;
        z-index: 999;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        top: 45%;
    }
}

@include respond-to(desktop) {
    .mapspage {
        &__popin {
            &.search {
                &:not(.close) {
                    width: 900px;
                }
            }
        }
    }
}

@include respond-to(large) {
    .mapspage {
        &__popin {
            &.search {
                &:not(.close) {
                    width: 1150px;
                }
            }
        }
    }
}
