@use "scss/utils/_vars.scss" as *;

.move-task__popup {
    width: min(100%, 450px);
}

.move-task__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.move-task__form .input-field-wrapper {
    align-self: unset;
}

.move-task__form small {
    margin-left: 0.5rem;
    color: $main-color;
}

.move-task__form label.disabled {
    filter: grayscale(1);
    cursor: not-allowed;
}

.btn.move-task__submit {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.5rem;
    text-transform: uppercase;
    border-color: $main-color;
    color: $white;
    background-color: $main-color;

    &:hover {
        background-color: $white;
        color: $main-color;
    }

    &:disabled {
        background-color: $disabled;
        color: $disabled-font-color;
        border-color: $disabled;
    }
}
