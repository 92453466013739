.is-loading {
    .loader {
        .bar {
            animation: dash linear;
            transform-origin: 112px 70px;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;

            &-2 {
                animation-duration: 2s;
            }

            &-3 {
                animation-duration: 3s;
            }
        }
    }

    &.loading-last-visits {
        .last-visits__list__item {
            &__loader {
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: linear-gradient(270deg, $loader-final, $loader-init);
                    background-size: 400% 400%;
                    animation: dataLoader 5s ease infinite;
                }
            }
        }
    }

    &.loading-form-search {
        .table-loader {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: $bg-search-load;
            display: block;
            text-align: center;
            z-index: 5;
            padding-top: 50px;

            svg {
                width: 200px;
                overflow: visible;
            }

            .loader-message {
                margin-top: 20px;
            }
        }
    }
}

    .form-loader {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: $bg-search-load;
        display: block;
        text-align: center;
        z-index: 450;

        svg {
            width: 200px;
            overflow: visible;

            @include respond-to('mobile') {
                margin-top: 50px;
            }

            @include respond-to('tablet') {
                margin-top: 50px;
            }
        }

        .loader-message {
            margin-top: 20px;
        }

        .bar {
            animation: dash linear;
            transform-origin: 112px 70px;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-fill-mode: both;

            &-2 {
                animation-duration: 2s;
            }

            &-3 {
                animation-duration: 3s;
            }
        }
    }

.content-search {
    .form-loader {
        width: 104%;
        color : white;

        @include respond-to('mobile') {
            background: $bg-search-load;
        }

        @include respond-to('tablet') {
            background: $bg-search-load;
        }

        @include respond-to('desktop') {
            background: $bg-search-load-not-extended;
        }
    }
}

.extended {
    .form-loader {
        width: 104%;
        background: $bg-search-load;
        color : unset;
    }
}

.admin-loader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: $bg-search-load;
    display: block;
    text-align: center;
    z-index: 450;
    padding-top: 15%;
    height: 115vh;

    @include respond-to('mobile') {
        padding-top: 45%;
    }

    svg {
        width: 200px;
        overflow: visible;
    }

    .loader-message {
        margin-top: 20px;
    }

    .bar {
        animation: dash linear;
        transform-origin: 112px 70px;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;

        &-2 {
            animation-duration: 2s;
        }

        &-3 {
            animation-duration: 3s;
        }
    }
}

.progress-bar {
    &__container-style {
        height: 25px;
        width: 15%;
        background-color: $font-color-sav-searchs;
        border-radius: 50px;
        margin: 50px auto;
    }
    &__label {
        color: $white;
        font-weight: 800;
        text-align: center;
        display: inline-block;
        padding-top: 3px;
    }
}


[class*='loading-table'] {
    .datatable {
        &__loader,
        &__number {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 2;
                background: linear-gradient(270deg, $loader-final, $loader-init);
                background-size: 400% 400%;
                animation: dataLoader 5s ease infinite;
            }
        }
    }
}
.popup__loader {
    > svg {
        height: 150px;
        overflow: visible;
    }
}
.page-details {
    &__infos {
        .infos-loader {
            margin-top: 20px;

            & + .infos-loader {
                margin-top: 0;
            }
        }
    }

    .infos-loader {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            z-index: 2;
            background: linear-gradient(270deg, $loader-final, $loader-init);
            background-size: 400% 400%;
            animation: dataLoader 5s ease infinite;
            left: 5px;
            right: 5px;
            bottom: 8px;
            top: 8px;
        }

        &.page-details__infos__title {
            &:before {
                left: 15px;
                right: 15px;
                bottom: 10px;
                top: 10px;
            }
        }
    }
}

@include respond-to('desktop') {
    .is-loading {
        &.loading-last-visits {
            .last-visits__list__item {
                &__loader {
                    position: relative;
                }
            }
        }
    }

    [class*='loading-table-'] {
        .datatable {
            &__loader,
            &__number {
                display: inline-block;
                position: relative;
            }
        }
    }
}
