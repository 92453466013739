.content-search__error {
    margin-top: 5rem;
    background: #ffffff;
    border-radius: 5px;
    padding: 1rem;
}

.content-search__error p {
    font-size: 0.9rem;
    font-weight: 500;
}

.content-search__error button {
    appearance: none;
    display: block;
    width: max-content;
    margin-inline: auto;
    border: none;
    background: #eef0fa;
    border-radius: 4px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;

    &:hover {
        background: darken(#eef0fa, 5%);
    }
}
