/* Transitions mixins */
/* ================================================== */
@mixin transition($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes : ('');
  @each $prefix in $prefixes {
    #{$prefix}transition : $transition;
  }
}

@mixin transition-delay($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes : ('');
  @each $prefix in $prefixes {
    #{$prefix}transition-delay : $transition;
  }
}

@mixin transition-duration($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes : ('');
  @each $prefix in $prefixes {
    #{$prefix}transition-duration : $transition;
  }
}

@mixin transition-property($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes : ('');
  @each $prefix in $prefixes {
    #{$prefix}transition-property : $transition;
  }
}

