@use "scss/utils/_vars.scss" as *;

.row-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.row-actions-btn,
.row-actions__group {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.25rem;
    padding: 0.4rem;
    background: transparent;
    color: $main-color;
    border: 1px solid $main-color;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
        background: $main-color;
        color: $white;
    }
}

.row-actions__group {
    position: relative;
}

.row-actions__group__list {
    list-style: none;

    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: visibility 0s, opacity 0.2s ease-in-out;

    position: absolute;
    isolation: isolate;
    z-index: 999;
    top: calc(100% + 1px); // 1px is border width
    right: -1px; // 1px is border width
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    background: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.row-actions__group.active .row-actions__group__list {
    visibility: visible;
    opacity: 1;
    pointer-events: all;

    &:before {
        content: '';
        position: absolute;
        z-index: 999999999;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 2rem;
    }
}

.row-actions__group__list__line {
    overflow: hidden;
    user-select: none;

    &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.row-actions__group__list__line > div {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    color: $main-color;
    max-width: 250px;
    font-size: 0.85rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
        background: $main-color;
        color: $white;
    }
}

.row-actions__group__list__line > div i {
    font-size: 1rem;
}
