@use "scss/utils/_vars.scss" as *;
@use "scss/utils/_respondTo.scss" as * with (
$breakpoints-min: $breakpoints-min,
$breakpoints-max: $breakpoints-max
);

.pol-table__wrapper {
    position: relative;
}

.pol-table__wrapper.is-loading {
    position: relative;
    min-height: 400px;
    overflow: hidden;
}

.pol-table__wrapper > .is-loading {
    position: absolute;
    inset: 0;
    top: 2.1rem;
    z-index: 2;
    backdrop-filter: blur(3px);

    @include respond-to('tablet', false) {
        top: 3rem;
    }
}

.pol-table__wrapper > .is-loading .loader {
    position: sticky;
    top: calc(50% - 8rem);
    display: flex;
    justify-content: center;

    @include respond-to('mobile', false) {
        top: calc(50% - 4rem);
    }
}

.pol-table__wrapper > .is-loading svg {
    height: 16rem;

    @include respond-to('mobile', false) {
        height: 8rem;
    }
}

.pol-table__overflow {
    overflow-x: auto;
    overflow-y: clip;
    min-height: 20rem;
}

.pol-table__wrapper label[for="pol-table-check-all"] {
    display: none;

    @include respond-to('tablet', false) {
        display: inline-block;
        padding: 0.5rem;
        cursor: pointer;
        color: $main-color;

        border: 1px solid $main-color;
        border-radius: 6px;

        margin-block: 0.5rem;

        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
            background: $main-color;
            color: $white;
        }
    }

    @media print {
        display: none;
    }
}

.pol-table__wrapper .action-view-orders-list {
    position: absolute;
    z-index: 1;
    top: 2.1rem;
    right: 0;
    background: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.pol-table {
    width: 100%;
    border-collapse: collapse;
}

.pol-table {
    // default
    & thead {
        background: $table-header;
    }

    // specifics
    &.contacts {
        & thead {
            background: $menu-contacts;
        }
    }

    &.actions {
        & thead {
            background: $menu-actions;
        }
    }

    &.devis {
        & thead {
            background: $menu-devis;
        }
    }

    &.articles {
        & thead {
            background: $menu-articles;
        }
    }

    &.mail-type {
        & thead {
            background: $menu-mails;
        }
    }

    &.prospects {
        & thead {
            background: $active-class;
        }
    }
}

.is-loading .pol-table thead {
    visibility: hidden;
    opacity: 0;
}

.pol-table thead {
    transition: opacity .3s ease-in-out;
    text-transform: uppercase;

    @include respond-to('tablet', false) {
        display: none;
    }

}

.pol-table thead th {
    color: $white;
    height: 2.1rem;
}

.pol-table thead th[data-type] {
    white-space: nowrap;
    font-size: 0.8rem;
    padding-inline: 1rem;
    font-weight: normal;

    &:focus {
        outline: none;
    }
}

.pol-table thead th.sortable span {
    cursor: pointer;
    position: relative;
    padding-right: 16px;

    &:before,
    &:after {
        display: block;
        position: absolute;
        top: 50%;
        right: -2px;
        font-size: 8px;
    }

    &:before {
        content: '▲';
        transform: translateY(-100%);
    }

    &:after {
        content: '▼';
    }
}

.pol-table th.sortable .active {
    &[data-sort="asc"]:after {
        color: rgba(255, 255, 255, 0.5);
    }

    &[data-sort="desc"]:before {
        color: rgba(255, 255, 255, 0.5);
    }
}

.pol-table thead th[data-type="string"],
.pol-table thead th[data-type="unknown"] {
    text-align: left;
}

.pol-table thead th[data-type="image"] {
    width: 6rem;
    min-width: 6rem;
}

.pol-table th > i {
    margin-inline: 1rem;
    vertical-align: middle;
    font-size: 1rem;
    cursor: pointer;
}

.pol-table__check-all {
    width: 0; // also another thing that i don't understand with td but it works exactly as i want now
}

.pol-table__view-orders {
    text-align: right;
    width: 0; // same as above
}

.pol-table input[type="checkbox"] {
    aspect-ratio: 1/1;
    width: 1rem;
    accent-color: $main-color;
    margin-inline: 1rem;
    vertical-align: middle;
    display: block;

    @include respond-to('tablet', false) {
        margin: unset;
    }

    @media print {
        display: none;
    }
}

.pol-table tbody tr {
    position: relative;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:nth-child(even) {
        background: $table-row;
    }

    &:hover {
        background: darken($table-row, 2%);
    }

    @include respond-to('tablet', false) {
        border-bottom: 3px solid #eeeeee;
        display: block;

        &:not(:last-child) {
            margin-bottom: .75rem;
        }
    }
}

.pol-table tbody tr.selectable {
    cursor: auto;
}

.pol-table tbody td[data-checkbox] {
    position: relative;
    z-index: 1;
}

.pol-table tbody td,
.pol-table tfoot td[data-type="money"] {
    height: 2.5rem; // same as min-height for td... wonder why (table display ?)

    @include respond-to('tablet', false) {
        display: block;
        text-align: right !important;
        height: auto;
        min-height: 1.5rem;
        padding: 0.2rem 1rem !important;

        &[data-label]:before {
            content: attr(data-label) ' :';
            color: $black;
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #ddd;
        }
    }
}

.pol-table tbody td[data-type],
.pol-table tfoot td[data-type] {
    padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
    .pol-table tbody td {
        white-space: normal !important;
    }
}

.pol-table tbody td:not([data-type="longtext"]) {
    white-space: nowrap;
}

.pol-table tbody td[data-type="image"] {
    padding: 0.25rem;
    text-align: center;
}

.pol-table tbody td[data-type="image"] > * {
    aspect-ratio: 1/1;
}

.pol-table tbody td[data-type="image"] > div {
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to('tablet', false) {
        display: inline-block;
    }
}

.pol-table tbody td[data-type="image"] > img {
    display: block;
    width: 100%;
    object-fit: cover;

    @include respond-to('tablet', false) {
        margin-left: auto;
        width: unset;
    }
}

.pol-table:not(.dashboard-listing__table) :is(tbody td[data-type="money"], tfoot td[data-type="money"]) {
    position: relative;

    &:before {
        --_border-width: 2px;
        content: '';
        position: absolute;
        inset: 0 0 0 calc(-1 * var(--_border-width));
        border-inline: var(--_border-width) solid $main-color;
    }
}

.pol-table tbody td[data-type="money"],
.pol-table tfoot td[data-type="money"] {
    text-align: right;
}

.pol-table tfoot td[data-type="money"] {
    font-weight: bold;
    color: $main-color;
}

.pol-table tfoot tr:last-of-type {
    background: $table-row;
}

.pol-table tfoot tr:last-of-type td[data-type="money"] {
    background: $main-color;
    color: $white;
}

.pol-table tfoot .tfoot-label {
    position: sticky;
    left: 0;
    padding-inline: 1rem;
    color: $main-color;
}

.pol-table tbody td[data-type="percent"] {
    text-align: center;
}

.pol-table tbody td[data-type="boolean"] {
    text-align: center;
}

.pol-table tbody td[data-type="icon"] {
    text-align: center;
}

.pol-table tbody td[data-type="icon"] i {
    font-size: 1.2em;
}

.pol-table tbody td[data-type="TelType"] {
    white-space: nowrap;
}

.pol-table tbody td[data-type="TelType"] .liveo-format {
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;

    &:hover {
        color: $lte-active-link;
    }
}

.pol-table tbody td[data-type="TelType"] .liveo-format i {
    font-size: 0.85rem;
}

.pol-table tbody td[data-type="TelType"] .liveo-format,
.pol-table tbody td[data-type="email"] a {
    position: relative;
    z-index: 1;
    color: $main-color;

    &:hover {
        color: $lte-active-link;
    }
}

.pol-table tfoot td:not([data-type="money"]) {
    @include respond-to('tablet', false) {
        display: none;
    }
}

.pol-table tbody td[data-type="rowActions"] {
    position: relative;
    z-index: 1;

    @include respond-to("tablet", false) {
        z-index: unset;
    }
}

.pol-table tbody tr:hover td[data-type="rowActions"] {
    z-index: 2;
}

.pol-table tbody td[data-type="files"] {
    min-width: 20rem;
}

.pol-table tbody td[data-type="pol-actions"] {
    @media (max-width: 991px) {
        display: contents;
    }
}

.pol-table .table-link {
    position: absolute;
    inset: 0;
}

.pol-table tr.selectable .table-link {
    pointer-events: none;
}

.pol-table .table-actions {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    font-size: 1rem;
    padding: 0.5rem;
    position: relative;

    @include respond-to('tablet', false) {
        justify-content: flex-end;
        padding: 0.2rem 1rem;
    }
}

.pol-table .table-actions > li {
    position: relative;
    cursor: pointer;
    aspect-ratio: 1/1;
    color: $main-color;
    border-radius: 4px;
    border: 1px solid $main-color;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

    &[data-disabled="true"] {
        cursor: not-allowed;
        color: $disabled-font-color;
        border-color: $disabled-font-color;
        background: $disabled;
    }

    &:not([data-disabled="true"]) {
        &:not(.has-dropdown):hover {
            background: $main-color;
            color: $white;
        }
    }
}

.pol-table .table-actions > li.has-dropdown {
    position: relative;
    margin-right: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pol-table .table-actions > li a:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
}

.pol-table .table-actions li > * {
    display: block;
    padding: 0.4rem;
}

.pol-table .table-actions li i {
    display: block;
}

.pol-table .pol-table__no-results {
    height: auto;
    padding-block: 1.5rem;
    background: $table-row;
    cursor: auto;

    &:hover {
        background: $table-row;
    }
}

.pol-table .pol-table__no-results > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.85rem;
    color: $font-color-homepage-datatable;
}

.pol-table .pol-table__no-results i {
    font-size: 1.5rem;
}

.pol-table [id^="pol-table-tooltip"] {
    max-width: 400px;
    word-break: break-all;
}

.pol-table__badge {
    display: inline-block;
    line-height: 1;
    padding: 0.2rem 0.25rem;
    border-radius: 4px;
    font-size: 0.5rem;
    letter-spacing: 0.5px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;
    background: $input-default-color;
    vertical-align: middle;
    margin-left: 0.4rem;
}

.pulse-bg {
    animation: pulse-bg 1.5s ease-in-out;
}

@keyframes pulse-bg {
    50% {
        background-color: $input-color-focus;
    }
}
