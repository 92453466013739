.is-map {
    .main-content {
        z-index: 9;
    }
}

.page {
    position: relative;
    min-width: 100%;
    min-height: 490px;
    display: inline-block;
    flex-basis: 100%;
}


.bg-env-development {
    background-image:url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 50" width="100" height="50" xmlns="http://www.w3.org/2000/svg"><g fill="LightGray" font-size="10"  ><text  x="20" y="10" dominant-baseline="central" text-anchor="middle">dev</text><text  x="50" y="35" dominant-baseline="central" text-anchor="middle">dev</text><text  x="70" y="10" dominant-baseline="central" text-anchor="middle">dev</text><text  x="2" y="35" dominant-baseline="central" text-anchor="middle">dev</text><text  x="102" y="35" dominant-baseline="central" text-anchor="middle">dev</text></g></svg>') !important;
    background-repeat: repeat !important;
    background-size: 300px 150px !important;
    background-color: #848484 !important;
}


.bg-env-preprod{
    background-image:url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 50" width="100" height="50" xmlns="http://www.w3.org/2000/svg"><g fill="LightGray" font-size="10"  ><text  x="20" y="10" dominant-baseline="central" text-anchor="middle">preprod</text><text  x="50" y="35" dominant-baseline="central" text-anchor="middle">preprod</text><text  x="70" y="10" dominant-baseline="central" text-anchor="middle">preprod</text><text  x="2" y="35" dominant-baseline="central" text-anchor="middle">preprod</text><text  x="102" y="35" dominant-baseline="central" text-anchor="middle">preprod</text></g></svg>') !important;
    background-repeat: repeat !important;
    background-size: 300px 150px !important;
    background-color: #848484 !important;
}
