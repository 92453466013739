@use 'scss/utils/_vars.scss' as *;

.header__filters {
    margin-left: 0.5rem;
    position: relative;
    z-index: 1;
}

.header__separator + .header__filters {
    margin-left: unset;
}

.header__filters__list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 0.3rem;
}

.header__filters__list li {
    --_color: #{$input-default-color};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.4rem;
    color: var(--_color);
    border: 2px solid var(--_color);
    white-space: nowrap;
    border-radius: 4px;
    padding: 0.4rem 0.7rem;
    cursor: pointer;
    transition: color ease-in-out .3s;

    &.is-default {
        --_color: #{$warning};
        font-weight: 500;
    }

    &:before {
        content: '';
        position: absolute;
        z-index: -2;
        inset: 0;
        background: $white;
    }

    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background: var(--_color);
        transition: width ease-in-out .3s;
    }

    &:hover {
        color: $white;

        &:after {
            width: 100%;
        }
    }
}

.header__filters__list li > i {
    font-size: 0.9rem;
}

.header__filters__list .header__filters__dropdown {
    min-width: 7.6rem;
    cursor: default;

    @media (max-width: 768px) {
        margin-left: 0.5rem;
        min-width: unset;
    }
}

.header__filters__list .header__filters__dropdown:hover ul {
    display: flex;
}

.header__filters__list > li > ul {
    margin-left: -2px; // to compensate the border width
    padding-top: 0.2rem;
    position: absolute;
    z-index: 2;
    top: 110%;
    left: 0;
    display: none;
    flex-direction: column;
    row-gap: 0.2rem;
    min-width: 7.6rem;

    &:before {
        position: absolute;
        bottom: 100%;
        content: '';
        display: block;
        width: 100%;
        height: 7px;
    }
}

.header__filters__list > li > ul > li {
    justify-content: flex-start;
}
