@use 'scss/utils/_vars.scss' as *;

.duplicate-popup {
    position: fixed;
    inset: 0;
    background: transparentize($black, 0.4);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.duplicate-popup__content {
    background: $white;
    border-radius: 6px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.duplicate-popup__content > p {
    font-size: 0.9rem;
    color: $main-color;
    font-weight: 500;
}

.duplicate-popup__content__buttons {
    display: flex;
    justify-content: space-between;
}

.duplicate-popup__content__button-cancel,
.duplicate-popup__content__button-confirm {
    cursor: pointer;
    padding: 0.5rem 1rem;
    background: $active-class;
    border: 1px solid $active-class;
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
}

.duplicate-popup__content__button-cancel {
    background: $white;
    color: $active-class;
    transition: background-color 0.2s ease-in-out, color 0.3s ease-in-out;

    &:hover {
        background: $active-class;
        color: $white;
    }
}

.duplicate-popup__content__button-confirm {
    transition: background-color 0.2s ease-in-out, color 0.3s ease-in-out;

    &:disabled {
        background: $disabled;
        border: $disabled;
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        background: $white;
        color: $active-class;
    }
}

.duplicate-popup__loader {
    padding: 1.5rem;
    border-radius: 6px;
    background: $white;
}

.duplicate-popup__loader svg {
    width: 20vh;
}
