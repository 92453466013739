.prospect__page {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 7fr;

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
}

.prospect__page.fullpage {
    grid-template-columns: 1fr;
}

.prospect__page:not(.fullpage) .prospect__view__header__close {
    display: none;
}

.prospect__page.fullpage .prospect__view__actions {
    justify-content: flex-end;
    gap: 0.5rem;
}

.prospect__page.fullpage .prospect__view__actions__list {
    position: relative;
    flex-direction: row;
    height: 100%;
}

.prospect__page.fullpage .prospect__view__actions__list li {
    display: flex;
    aspect-ratio: 1/1;
}

.prospect__page.fullpage .prospect__view__actions__list li:first-of-type {
    display: none;
}

.prospect__page:not(.fullpage) .prospect__view .show-layout > .row {
    display: block;
    margin: unset;
}

.prospect__page:not(.fullpage) .prospect__view .show-layout .show-layout__meta {
    display: none;
}

.prospect__page:not(.fullpage) .prospect__view .show-layout > .row > [class*="col-"] {
    &:first-child {
        flex: none;
        max-width: unset;
    }

    &:not(:first-child) {
        display: none;
    }
}

.prospect__page .prospect__tab {
    height: 100%;
}

.prospect__page.fullpage .prospect__tab {
    overflow: hidden;
    height: 0;
}
