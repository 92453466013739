.btn {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 20px;
  line-height: 40px;
  border-radius: 5px;
  border: solid 1px #000;
  background-color: transparent;
  @include transition(background-color .2s);

  + .btn {
    margin-left: 10px;
  }

  &:hover {
    background-color: #000;
    color: #fff;
    cursor: pointer;
  }

  i {
    margin-right: 5px;
  }

  &-primary {
    border-color: $main-color;
    color: $main-color;

    &:hover {
      background-color: $main-color;
      color: #fff;
    }
  }

  &-secondary {
    border-color: $secondary-color;
    color: $secondary-color;

    &:hover {
      background-color: $secondary-color;
      color: $main-color;
    }
  }

  &:disabled {
    background-color: $disabled;
    color: $disabled-font-color;
    border-color: $disabled;
    cursor: not-allowed;
  }
}
