@import 'variables';
@import 'style';

.spinner {
    display: inline-block;
    animation: spin 1s infinite linear;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}
