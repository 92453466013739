@use "scss/utils/_vars" as *;

.show-group {
    margin-top: 1rem;
}

.show-group h3 {
    font-size: 0.8rem;
    font-weight: 500;
    color: $main-color;
}

.show-group__fields {
    margin-top: 1rem;
    box-shadow: 2px 2px 1px transparentize($black, 0.9);
    background-color: #eaecfa;
    border-radius: 5px;
}

.show-group__fields > .row {
    row-gap: 0.25rem;
}

.show-group__fields > .row > [class*="col-"]:nth-child(even) .show-field {
    background: $white;
    box-shadow: 0 2px 2px transparentize($black, 0.82);
}

.show-group .show-field {
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    background: $lte-border-header;
    color: $main-color;
}

.show-group .show-field:is(.text-rich-editor, .textareatype) .show-field__value {
    color: initial;
}

.show-field.text-rich-editor * {
    padding: revert;
}

.show-group .show-field:not(.text-rich-editor, .textareatype) .show-field__value {
    font-weight: bold;
}

.show-group .show-field:is(.text-rich-editor, .textareatype) .show-field__value {
    display: block;
    margin-block: 0.5rem;
}

.show-group .show-field__value img {
    width: 100%;
}

.show-entity-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-entity-loader .loader svg {
    overflow: visible;
    width: 12rem;
    max-width: 100%;
}

.liveo-format {
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;

    &:hover {
        color: $lte-active-link;
    }
}

.liveo-format i {
    font-size: 0.85rem;
}

.show-layout__meta {
    text-align: right;
    color: $main-color;
    font-weight: 400;
    opacity: 0.7;
}

.show-layout__meta p {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 0.6ex;

    & + p {
        margin-top: 0.5rem;
    }
}
