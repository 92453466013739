.main-content {
    background: linear-gradient(to bottom, $header 0%, $header 50px, $header 50px, transparent 50px, transparent 100%);
    position: relative;

    &__body {
        border-radius: 4px;
        position: relative;
        //overflow-x: hidden;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;

        .page-admin {
            display: none;
        }
    }

    .header {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 40px;

        /* Nombre de résultats page prospects */

        &__margin {
            min-width: 8px;
            @include menu-color;
            height: 100%;

            @media print {
                display: none;
            }
        }

        &__number {
            color: $header-content;
            opacity: .6;
            min-width: 127px;
            text-align: center;

            @include respond-to('mobile') {
                display: none;
            }
        }

        &__separator {
            width: 2px;
            background-color: $table-header;
            height: 65%;
            margin-left: 10px;
            margin-right: 10px;

            @include respond-to('mobile') {
                display: none;
            }
        }

        &__title {
            background-color: $table-row;
            color : $active-class;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 7px;
            padding-right: 7px;
            height: 100%;
            text-align: center;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            font-size: 1em;

            @media print {
                align-items: flex-start;
            }
        }
    }

    .content-add {
        margin-left: auto;

        @media print {
            display: none;
        }

        &__list {
            list-style: none;
            padding: 5px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: max-content;


            .action {
                color: $white;
                background-color: $main-color;
                cursor: pointer;
                width: 45px;
                height: 45px;
                text-align: center;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-left: 10px;

                &--disable {
                    background-color: $disabled;
                    color: $disabled-font-color;
                    cursor: not-allowed;
                }

                &__picto {
                    cursor: inherit;
                    font-size: 1.2rem;
                    display: block;
                }

                &__dropdown{
                    position: relative;
                    display: inline;
                    margin-right: 5px;
                    width: 100px;

                    &__content {
                        display: none;
                        position: absolute;
                        background-color: $bg-search;
                        min-width: 160px;
                        padding: 12px 16px;
                        p{
                            color:$black;
                        }
                    }

                    &__item{
                        width: 100%;
                        display: inline-block;
                        color: $white;
                        font-size: 1.2em;
                        margin: 0 15px;
                    }
                    &__item:hover{
                        background-color: $table-header;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    &__content:hover {
                        display: block;
                    }
                    &:hover{
                        +.action__dropdown__content {
                            display: block;
                        }
                    }
                }
            }

            .btn {

                &__primary{
                    font-size: 1.6em;
                }
            }
        }
    }

    .d-print-none {
        @media print {
            display: none;
        }
    }

    .content-action {
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-left: -5px;


        &__list {
            list-style: none;
            padding: 5px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: fit-content;

            .action {
                color: $active-class;
                cursor: pointer;
                width: 40px;
                height: 35px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-left: 5px;
                border : 1px solid $active-class;
                border-radius : 5px;
                transition: all .20s ease-in;
                &:hover {
                    background: $active-class;
                    color : $white;
                }

                > a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }


                &__dropdown {
                    margin-left : 5px;
                    width: 40px;
                    height: 35px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    position: relative;


                    &__content {
                        display: none;
                        position: absolute;
                        left: 0px;
                        top: 100%;
                        background-color: $table-row;
                        min-width: 280px;
                        height: auto;
                        z-index: 3;
                        border : solid 2px $active-class;
                        border-radius : 3px;
                        p{
                            color: $main-color;
                        }
                    }

                    &__item{
                        width: 280px;
                        display: inline-flex;
                        align-items: center;
                        gap: 0.5rem;
                        color: $white;
                        font-size: 0.9em;
                        cursor: pointer;
                        padding : 8px;
                        user-select: none;
                        .icon-li {
                            .spin {
                                animation-name: spin;
                                animation-duration: 3000ms;
                                animation-iteration-count: infinite;
                                animation-timing-function: linear;

                                @keyframes spin {
                                    from {
                                        transform:rotate(0deg);
                                    }
                                    to {
                                        transform:rotate(360deg);
                                    }
                                }
                            }
                            display: flex;
                            flex-direction: row;
                            > svg {
                                color: $black;
                                margin-top: 2px;
                            }
                            > p {
                                margin-left: 10px;
                            }
                        }

                        & i {
                            color: black;
                        }

                        &__disabled {
                            pointer-events: none;
                            background-color: $disabled;
                            filter: grayscale(1);
                        }
                        &:hover {
                            background-color: $active-class;

                            p{
                                color : white;
                            }
                        }
                    }

                    &__Btn {
                        cursor: pointer;
                    }

                    &:hover {
                        .action__dropdown__content {
                            display: block;
                        }
                    }

                }
            }

            .btn {

                &__secondary{
                    font-size: 1.6em;
                    border : 1px solid $active-class;
                    color: $active-class;
                    border-radius : 5px;
                    transition: all .20s ease-in;

                    &:hover {
                        background: $active-class;
                        color : $white;
                    }
                }


            }

        }
    }

    .content-search {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 400px;
        height: 100%;
        background: $white;
        z-index: 7;
        padding: 0 30px;
        text-align: left;
        transform: translateX(100%);
        transition: transform .4s ease-in-out;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include respond-to('mobile') {
            background-color: $white;
            width: 100%;
        }

        @include respond-to('tablet') {
            width: 100%;
            background-color: $white;
        }

        @include respond-to('desktop') {
            background-color: $background-search-menu;
            width: 80%;
        }

        .savedSearch {
            h2:first-child {
                @include respond-to('mobile') {
                    color: $main-color;
                }

                @include respond-to('tablet') {
                    color: $main-color;
                }

                @include respond-to('desktop') {
                    color: $white;
                }
            }
        }

        &.active-search {
            transform: translateX(0);
            box-shadow: 5px 0 15px $box-shadow-column;
        }

        &.hidden-search {
            display: none;
        }

        &__title {
            text-transform: uppercase;
            font-weight: 700;
            color: $main-color;
            font-size: 1.3em;
            margin-bottom: 10px;
            padding-right: 25px;
        }

        &__close {
            position: absolute;
            z-index: 1;
            top: 20px;
            right: 20px;
            font-size: 1.7em;
            color: $white;
            cursor: pointer;
            display: inline-block;
            padding: 5px;
            border-radius: 50%;

            @include respond-to('mobile') {
                color: $main-color;
            }

            @include respond-to('tablet') {
                color: $main-color;
            }

            @include respond-to('desktop') {
                color: $white;
            }
        }

        &__expend {
            position: fixed;
            left: 8px;
            font-size: 1.2em;
            top: 30px;
            transform: rotate(180deg);
            color: $white;
            cursor: pointer;
            transition: all .25s ease-in-out;
            width: 15px;

            &:hover {
                transform: rotate(180deg) translateX(35%);
            }

            @include respond-to('mobile') {
                display: none;
            }

            @include respond-to('tablet') {
                display: none;
            }

            @include respond-to('desktop') {
                color: $white;
                display: block;
            }
        }

        .open {
            transform: rotate(0deg);
        }

        .group {
            padding: 0;
        }

        .form {
            padding: 0 0 30px;
        }

        &__field {
            position: relative;

            &__input {
                display: block;
                border: 0;
                width: 100%;
                padding: 15px 0;
                background: none;
                font-size: 1.2em;

                &:focus,
                &:valid {
                    outline: none;

                    + label {
                        top: 0;
                        font-size: .9em;
                        color: $main-color;
                    }

                    ~ span {
                        &:before,
                        &:after {
                            width: 50%;
                            z-index: 1;
                        }

                        &:before {
                            left: 0;
                        }
                    }
                }
            }

            &__label {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.2em;
                transition: top .2s linear, font-size .2s linear;
                color: $secondary-color;
            }

            &__bar {
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0;
                    height: 2px;
                    background: $main-color;
                    transition: width .2s linear, left .2s linear;
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background: $secondary-color;
            }
        }
    }

    .extended {
        background-color: $white;

        @include respond-to('desktop') {
            width: 850px !important;
        }

        @include respond-to('large') {
            width: 1110px !important;
        }

        .policon {
            color: $main-color;

            &-arrow-right-bis {
                &:hover {
                    transform: translateX(35%);
                }
            }
        }

        .savedSearch {
            h2:first-child {
                color: $main-color;
            }

            .policon {
                color: unset;
            }
        }
    }
}

@include respond-to('tablet') {
    .main-content {
        .header {
            &__title {
                position: relative;

                .content-actions {
                    position: absolute;
                    background: none;
                    bottom: 0;

                    &__list {
                        padding: 0;
                        height: 100%;
                    }

                    .action {
                        height: 100%;
                        display: inline-flex;
                        align-items: center;
                        margin: 0;
                        padding: 0 20px;
                        cursor: pointer;

                        &:last-child {
                            border-left: 1px solid $white;
                        }
                    }
                }
            }

            &__number {
                margin-top: 0;
                min-height: 21px;
            }
        }
    }
}

@include respond-to('desktop') {
    .main-content {
        .content-search {
            width: 400px;
        }
    }
}

@include respond-to('large') {
    .main-content {
        .content-search {
            width: 400px;
        }
    }
}
