.ged-popup {
    position: fixed;
    inset: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000c;
    padding-inline: 20%;

    > * {
        min-width: unset !important;
        flex-basis: unset !important;
    }
}

.ged-popup__window-border {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #9589c0;
    border-bottom: 2px solid #7565c0;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    color: #fff;
    font-weight: 500;

    i {
        cursor: pointer;
        font-size: 1.5rem;
    }
}
