// sass-lint:disable-all
.tail-datetime-calendar {
    width: 350px;
    height: auto;
    padding: 0;
    display: block;
    overflow: hidden;
    margin: 40px auto 30px;
    border-collapse: separate;
    background-color: $white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
    border-radius: 3px;

    &:after {
        clear: both;
        content: "";
        display: block;
        font-size: 0;
        visibility: hidden;
    }

    &.calendar-static {
        margin-left: auto;
        margin-right: auto;
    }

    .calendar-navi {
        width: 100%;
        margin: 0;
        padding: 0;
        display: table;
        border-spacing: 0;
        border-collapse: separate;
        background-color: $secondary-color;
        border-radius: 3px 3px 0 0;

        span {
            cursor: pointer;
            color: #fff;
            margin: 0;
            padding: 0;
            display: table-cell;
            font-size: 1.2em;
            text-align: center;
            line-height: 40px;
            text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);
            background-repeat: no-repeat;
            background-position: center center;
            transition: background-color .5s;

            &:first-child, &:last-child {
                width: 35px;
                padding: 0 0 5px 0;
                font-size: 22px;
            }

            &:hover {
                background-color: darken($secondary-color, 5%);
            }

            &.button-prev {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\ TYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+");
            }

            &.button-next {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\ TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=");
            }

            &.button-check {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\ SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
            }

            &.button-close {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\ SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\ NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
            }
        }
    }

    .calendar-date {
        margin: 0;
        padding: 0;
        display: block;

        table {
            width: 100%;
            margin: 0;
            padding: 0;
            border-spacing: 0;
            border-collapse: collapse;

            thead tr > * {
                color: $white;
                width: 35px;
                height: 35px;
                padding: 0;
                font-size: 12px;
                text-align: center;
                font-weight: normal;
                line-height: 35px;
                border: 0;
                background-color: $main-color;
            }

            tbody {
                tr > * {
                    width: 50px;
                    height: 50px;
                    padding: 0;
                    text-align: center;
                    font-weight: normal;
                    line-height: 35px;
                    border: 0;
                    color: #334455;
                    cursor: pointer;
                    position: relative;
                    font-size: 14px;
                    background-color: $white;

                    > span {
                        color: inherit;
                        z-index: 10;
                        position: relative;
                    }

                    &::after {
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                        width: 29px;
                        height: 29px;
                        content: "";
                        display: block;
                        position: absolute;
                        border-width: 1px;
                        border-style: solid;
                        border-color: transparent;
                        border-radius: 50%;
                    }

                    &.today {
                        color: $active-class;

                        &:after {
                            border-color: $active-class;
                        }
                    }
                }

                .calendar-day:hover {
                    color: $active-class;

                    &:after {
                        border-color: $active-class;
                    }
                }

                tr > * {
                    &.empty, &.disable {
                        color: #8899aa;
                        cursor: not-allowed;
                        background-color: #efefef;
                    }

                    &.empty:hover:after, &.disable:hover:after {
                        content: "";
                        display: none;
                    }

                    &.current {
                        span {
                            color: $white;
                        }

                        &:after {
                            border-color: $active-class;
                            background-color: $active-class;
                        }
                    }
                }
            }

            &.calendar-month tbody tr > * {
                width: 81.66666666666667px;
                padding: 5px;
                line-height: 25px;

                span {
                    display: block;
                    border-width: 1px;
                    border-style: solid;
                    border-color: transparent;
                    border-radius: 3px;
                }

                &:hover span {
                    border-color: #d0d0d0;
                    box-shadow: 0 1px 0 0 #efefef;
                }
            }
        }
    }

    .calendar-time {
        width: 100%;
        margin: 0;
        padding: 0;
        display: table;
        text-align: center;
        background-color: $white;
        border-radius: 0 0 3px 3px;
    }

    .calendar-date + .calendar-time {
        border-top: 1px solid #d0d0d0;
        background-color: #f8f8f8;
    }

    .calendar-time .calendar-field {
        width: 33.3333333333%;
        padding: 10px 0;
        display: table-cell;
        position: relative;
        text-align: center;
        vertical-align: top;

        &:first-child {
            text-align: right;

            &:after {
                top: 12px;
                right: -10px;
                content: ":";
                position: absolute;
            }
        }

        &:last-child {
            text-align: left;

            &:after {
                top: 12px;
                left: -10px;
                content: ":";
                position: absolute;
            }
        }

        input[type="number"] {
            width: 100%;
            max-width: 50px;
            margin: 0;
            padding: 3px 4px;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            border-width: 1px;
            border-style: solid;
            border-color: #d0d0d0;
            background-color: $white;
            border-radius: 3px;

            &:hover {
                border-color: #a0a0a0;
            }

            &:focus {
                border-color: #3296c8;
            }
        }

        label {
            color: #778899;
            margin: 5px 0 0 0;
            padding: 0;
            display: block;
            font-size: 12px;
            line-height: 12px;
        }
    }
}
// sass-lint:enable-all
